<div *ngIf="isValid && !loading">
    <div class="hrdr-title animated fadeIn">{{ username | titlecase}}'s collections</div>
    <div class="row animated fadeIn">
        <div class="col-lg-4 col-sm-6 col-12" *ngFor="let col of categoryList; let i = index;">
            <mdb-card (click)="goToCategory(this.username, col.identity.categoryname);" class="hrdr-col-card">
                <mdb-card-body>
                    <div class="row">
                        <div class="col">
                            <mdb-card-title><h4>{{col.identity.categoryname}}</h4></mdb-card-title>
                            <mdb-card-text>Items: <span style="font-weight: bold;">{{col.objectcount}}</span></mdb-card-text>
                        </div>
                    </div>
                    <div class="row" style="min-height: 2.4em; font-size:1em; padding: 0.8em 0.4em 0 0.4em; color: #CCC;">
                        <div *ngIf="col.objectcount > 0" class="col-12">Last item added on <span style="color: #AAA; font-weight:bold;">{{col.lastchanged.substr(0,10)}}</span></div>
                        <div *ngIf="col.objectcount == 0" class="col-12">No items added yet</div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div *ngIf="categoryList.length == 0" class="col-12">Oops, there are no collections yet!</div>
    </div>
</div>
<div *ngIf="!isValid && !loading">
    <div class="hrdr-title">User not found</div>
    <p>Oops... didn't find a user called {{username}}... are you sure he is real?</p>
</div>
<div *ngIf="loading" class="row" style="text-align: center;">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
</div>