<div class="row">
  <div class="col-12">
    <div class="flex-center">
      <div *ngIf="dataFetched" class="col-lg-6 col-md-8 col-12 card animated fadeIn" style="padding: 2em;">
          <div *ngIf="!pwdReset">
        <form [formGroup]="resetForm">
          <h4>Hi {{usr.identity.username}}, reset your password</h4>
          <div class="md-form">
            <input mdbInput type="password" [name]="'pwd'" [formControlName]="'pwd'" [id]="'pwd'" class="form-control">
            <label for="pwd" class="">Password</label>
          </div>
          <div class="md-form" style="margin-top: 50px;">
            <input [class.is-invalid]="resetForm.get('confirmPwd').touched && resetForm.invalid" mdbInput type="password" name="confirmPwd" [formControlName]="'confirmPwd'" id="confirmPwd" class="form-control">
            <label for="confirmPwd" class="">Confirm password</label>
            <div style="min-height:20px;"><span *ngIf="resetForm.get('confirmPwd').touched && resetForm.invalid" class="hrdr-form-warning animated fadeIn">Passwords are not equal</span></div>
          </div>
          <div class="row">
            <div class="col-4">
              <button [disabled]="isLoading && !resetForm.get('pwd').touched || resetForm.invalid" type="submit" class="btn hrdr-btn" (click)="updatePassword(resetForm.get('pwd').value);">Reset</button>
            </div>
            <div class="col-6" style="padding-top: 0.7em;">
              <mdb-spinner *ngIf="isLoading" spinnerType="small" spinnerColor="blue" style="margin-left: 10px; position: absolute; top: 10px;"></mdb-spinner>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="pwdReset">
          <h4>Password has been reset!</h4>
          <p>Go forth and collect again! Click <a [routerLink]="['/login']">here</a> to go to the login page</p>
      </div>
      </div>
    </div>
  </div>
</div>