import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { identity, user } from 'src/app/_models/main.model';
import { UserService } from 'src/app/_services/user.service';
import { AdminComponent } from '../admin.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { environment } from 'src/environments/environment';
import { debounceTime } from 'rxjs/operators';
import { DataService } from 'src/app/_services/data.service';

const baseImgUrl = environment.imgUrl;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  usersList;
  usernameMsg: string = "";
  saving: boolean = false;
  usernameTaken: boolean = false;
  currentImgLoading;
  profileImage: string = "";
  loading: boolean = true;
  identity: identity;
  usr: user;
  profileForm = new FormGroup({
    username: new FormControl(),
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    ispublic: new FormControl()
  });
  constructor(
    private ac: AdminComponent,
    private us: UserService,
    private imageCompress: NgxImageCompressService,
    private ds: DataService,
  ) { 
    this.identity = JSON.parse(localStorage.getItem('ident'));
    this.ac.validateUser(this.identity);
    setTimeout(()=> {
      this.ac.items = [
        {"route": "dashboard","name":"Home"}
      ];
    }, 0);
  }

  ngOnInit() {
    this.getUserInfo(this.identity);
    this.getAllUsers();
    this.profileForm.valueChanges.pipe(debounceTime(300)).subscribe(proFormVals => {
      this.usernameMsg = "";
      let userExists: number;
      if(proFormVals.username == ""){
        this.usernameMsg = "Username cannot be empty, please fill in the field" 
      } else {
        let foundUser = this.usersList.find(x => x.username === proFormVals.username);
        if(foundUser != undefined){
          userExists = foundUser.id;
        }
        if(userExists != undefined && userExists != this.identity.userid){
          this.usernameMsg = "Username already exists. Please select a different one";
        }
      }
      
    });
  }

  public getAllUsers(){
    this.us.getUsers().subscribe(usrs =>{
      this.usersList = usrs;
    })
  }

  public getUserInfo(ident: identity){
    this.us.getUserInfo(ident).subscribe((usrInfo:user) => {
      this.usr = usrInfo;
      if(this.usr.base64 == "profile.jpg"){
        this.getBase64ImageFromUrl(baseImgUrl + this.identity.userid + '/','profile.jpg').then(data => {
          this.getImageData(data.toString()).then(dim => {
            this.profileImage = data.toString(); 
          });
        });
      }
      this.profileForm.patchValue({
        username: usrInfo.identity.username,
        email: usrInfo.email,
        ispublic: usrInfo.ispublic
      })
      this.loading = false;
    });
  }

  saveUser(){
    this.saving = true;
    let saveUsr: user = new user();
    let ident: identity = new identity();
    let apiKey = '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';

    ident.username = this.profileForm.value["username"];
    ident.securitykey = this.identity.securitykey;
    ident.userid = this.identity.userid;
    saveUsr.identity = ident;
    saveUsr.email = this.profileForm.value["email"];
    saveUsr.ispublic = this.profileForm.value["ispublic"];
    saveUsr.base64 = this.profileImage;
    this.us.updateUser(saveUsr).subscribe(resp =>{
      let toast: any = {"status": resp["status"], "message": resp["message"]};
      this.ds.hasToastChanged(toast);
      this.ds.hasProfilePicChanged("update");
      this.saving = false;
    })
  }


  onFileSelected(event){
    this.currentImgLoading = true;{
    const element = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(element);
    reader.onload = () => {
        let image = new Image();
        image.src = reader.result.toString();
        this.compressFile(image.src).then(data => {
            this.profileImage = data;
            this.currentImgLoading = false;
          }
        );
      };
    }   
  }

  compressFile(image): Promise<string> {
    var orientation = -1;
    return this.imageCompress.compressFile(image, orientation, 80, 80).then(
      result => {
        let imgResultAfterCompress: string = result;
        return imgResultAfterCompress;
      }
    );
  }

  async getBase64ImageFromUrl(imageUrl,imgname) {
    let base64url = imageUrl + imgname;
    var res = await fetch(base64url);
    var blob = await res.blob();
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {resolve(reader.result);}, false);
      reader.onerror = () => {return reject(this);};
      reader.readAsDataURL(blob);
    })
  }

  public getImageData(result: string){
    let image = new Image();
    let imgHeight:number;
    let imgWidth: number;
    image.src = result;
    return new Promise(resolve => {
      image.onload = rs => {
        imgWidth=image.width;
        imgHeight=image.height;
        let dim: number[];
        dim = [imgWidth,imgHeight];
        resolve(dim);
      }
    })
  }
}
