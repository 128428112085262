<mdb-side-nav class="fixed hrdr-sidenav" [hidden]="false" #sidenav [fixed]="false" >
    <div class="hrdrr-sidebar-content" (swipeleft)="sidenav.hide()">
        <div *ngIf="!filters" style="text-align: center;">
            <mdb-spinner spinnerColor="blue"></mdb-spinner>
        </div>
        <div *ngIf="filters && form && !loading" >
            <form [formGroup]="form" style="margin: 3em;">
            <div class="row fltrRow hrdr-fltr-title"><h1 class="hrdr-title hrdr-fltr-title">Filter</h1></div>
            <div *ngFor="let filter of filters[0].filter">
                <app-filter-element [filter]="filter" [form]="form" ></app-filter-element>
            </div>
            <button mdbBtn class="hrdr-btn" (click)="resetFilter()">Clear Filter</button>
            </form>
        </div>
    </div>
</mdb-side-nav>
<div *ngIf="isValid && categoryexists" (swiperight)="sidenav.show()">
    <div class="row">
        <div class="col-7 hrdr-title animated fadeIn">
            {{ categoryname | titlecase}}
            <span class="animated fadeIn hrdrr-fltrbtn" *ngIf="filters && filters != undefined">
                <a *ngIf="filters[0].filter.length != 0 && objectCount != 0" mdbTooltip="Filter" (click)="sidenav.toggle()" class="icons-sm">
                    <mdb-icon  class="hrdr-btn-anim" fas icon="filter"></mdb-icon>
                </a>
            </span>
        </div>
        <div class="col-5 ">
            <div *ngIf="!loading && form && category">
                <form [formGroup]="form">
                    <div class="switch" style="position: absolute; right: 18px; top:20px;">
                        <label>
                        <input type="checkbox" (change)="wishListSwitch()" [formControlName]="'wishlist'">
                        <span class="lever" style="margin-top: -2px;"></span> Wishlist
                        
                        </label>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row animated fadeIn">
        <div class="col-12" *ngIf="!loading && objectCount != 0">
            <div class="animated fadeIn hrdrr-objlist" *ngIf="objectCount != 0 && gridCount != 0 && propertyCount != 0 && form && (objectList | filterPipe: form.value : filters[0].filter : sorter : sortorder ) as result">
                <div class="row">
                    <div class="col-12" style="text-align: left;">
                        <div style="position: absolute; bottom: 0.5em;">
                            <span style="font-weight: bold;" *ngIf="showNumber < result.length; else showResult">{{showNumber}}</span> of {{ result.length }} shown &nbsp;
                            <span *ngIf="objectCount > result.length"><a><mdb-badge pill="true" class="hrdr-fltr-pill" mdbTooltip="Click to remove filter" (click)="resetFilter()">filtered</mdb-badge></a></span>
                        </div>
                        <ng-template #showResult><span style="font-weight: bold;">{{result.length}}</span></ng-template>
                    </div>
                </div>
                <div class="container-fluid clctrr-grid">
                    <div class="row clctrr-grid-header">
                        <div class="col col-{{grid.size}} col-lg-{{grid.lSize}}" *ngFor="let grid of gridList[0].colActive ; let i=index">
                        <span *ngIf="grid.header === true" (click)="sortBy(grid.name)" style="cursor: pointer;">
                            {{grid.name}} &nbsp; 
                            <span *ngIf="sorter == grid.name && sortorder == 'asc'"><mdb-icon fas icon="angle-up"></mdb-icon></span>
                            <span *ngIf="sorter == grid.name && sortorder == 'desc'"><mdb-icon fas icon="angle-down"></mdb-icon></span>
                        </span>
                        </div>
                    </div> 
                    <div class="row clctrr-grid-row" [ngClass]="{ odd: odd, even: even }" *ngFor="let obj of result | slice:0:showNumber; let y=index; let odd=odd; let even=even;" [routerLink]="['/public/',identity.username, identity.categoryname, obj.ID]">
                        <div
                        class="col col-{{grid.size}} col-lg-{{grid.lSize}}"
                        *ngFor="let grid of gridList[0].colActive; let i=index"
                        [ngStyle]="{'font-weight': grid.bold === true ? 'bold' : '','font-size': grid.fontSize + 'em','text-align': grid.textAlign}">
                        <span *ngIf="grid.type === 'Image'" [ngClass]="{checkboxFalse: obj[grid.name] === 'False' || obj[grid.name] === 'false' || obj[grid.name] === ''}"><mdb-icon style="font-size:18px;" fas icon="{{grid.icon}}"></mdb-icon></span>
                        <span *ngIf="grid.type === 'Checkbox'" [ngClass]="{checkboxFalse: obj[grid.name] === 'False' || obj[grid.name] === 'false' || obj[grid.name] === ''}"><mdb-icon *ngIf="grid.icon != ''" style="font-size:18px;" fas icon="{{grid.icon}}"></mdb-icon><span *ngIf="grid.icon == ''">{{grid.name}}</span></span>
                        <span *ngIf="grid.type !== 'Checkbox' && grid.type !== 'Image'" >{{obj[grid.name]}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: left; padding-top: 0.5em;">
                        <div>
                            <span style="font-weight: bold;" *ngIf="showNumber < result.length; else showResult">{{showNumber}}</span> of {{ result.length }} shown &nbsp;
                            <span *ngIf="objectCount > result.length"><a><mdb-badge pill="true" class="hrdr-fltr-pill" mdbTooltip="Click to remove filter" (click)="resetFilter()">filtered</mdb-badge></a></span>
                        </div>
                        <ng-template #showResult><span style="font-weight: bold;">{{result.length}}</span></ng-template>
                    </div>
                </div>
                <div class="row" *ngIf="showNumber < result.length">
                    <div class="col-12" style="text-align: center;">
                        <button class="btn btn-blue-grey waves-light" (click)="showMore()" >Load more</button>	
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!loading && objectCount == 0">
            No items added to the category yet
        </div>
    </div>
</div>
<div *ngIf="isValid && !categoryexists && !loading">
    <div class="hrdr-title">Collection not found</div>
    <p>Oops... didn't find a collection called {{categoryname}} for user {{username}}...</p>
</div>
<div *ngIf="!isValid && !loading">
    <div class="hrdr-title">User not found</div>
    <p>Oops... didn't find a user called {{username}}... are you sure he is real?</p>
</div>
<div *ngIf="loading" class="row" style="text-align: center;">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
</div>