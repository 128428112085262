import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filterPipe'})
export class FilterPipe implements PipeTransform{
	transform(items: any, filterValues: any, filter: any, sortby: string, sortorder: string): any {
		localStorage.setItem('currentFilter',JSON.stringify(filterValues));
		let totals: any = items;
		let searchText: string;
		// if there are no items to begin with, do nothing
		if(!items){
			return [];
		}
		// run through the filterObject
		filter.forEach(function(entry) {
			// if the type is text
			if (entry.type == "Text"){
				if (filterValues[entry.name]){
					searchText = filterValues[entry.name].toLowerCase();
						totals = totals.filter(it => {return it[entry.name].toLowerCase().includes(searchText);});
				}
			} else if( entry.type == "Dropdown"){
				let comboItems: any = [];
				if (filterValues[entry.name]){
					if(filterValues[entry.name] != ""){
						filterValues[entry.name].forEach(function(valueItems){
								comboItems = comboItems.concat(totals.filter(it => {return it[entry.name].toLowerCase() == valueItems.toString().toLowerCase();}));	
						});
						totals = comboItems;
					}
				}
			} else if(entry.type == "Checkbox"){
				if(filterValues[entry.name]){
				searchText = filterValues[entry.name];
								totals = totals.filter(it => {return it[entry.name] == searchText;});
				}
      		} else if(entry.type == "Image"){
				  if(filterValues[entry.name] == 'true'){
					totals = totals.filter(it => {return it[entry.name] == 'true'});
				  } else if (filterValues[entry.name] == 'false'){
					totals = totals.filter(it => {return it[entry.name] == 'false'});
				  }
			} else if (entry.type == "Tagfield"){
				if (filterValues[entry.name]){
					if(filterValues[entry.name] != ""){
						filterValues[entry.name].forEach(function(valueItems){
							totals = totals.filter(it => {return it[entry.name].toString().includes(valueItems);});	
						});
					}
				}
			}
		})
		if(filterValues["wishlist"]){
			totals = totals.filter(it => {return it["wishlist"] == true;});	
		} else {
			totals = totals.filter(it => {return it["wishlist"] == false;});	
		}
		if(sortby == ""){
			return totals;
		} else {
			if(sortorder == "asc"){
				return totals.sort((a,b) => a[sortby].localeCompare(b[sortby]));
			} else {
				return totals.sort((a,b) => b[sortby].localeCompare(a[sortby]));
			}
			
		}
		
	}
}
