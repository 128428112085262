import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { category, identity } from 'src/app/_models/main.model';
import { CategoryService } from 'src/app/_services/category.service';
import { UserService } from 'src/app/_services/user.service';
import { MainComponent } from '../main.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  username: string;
  isValid: boolean = false;
  loading: boolean = true;
  categoryList: category[];
  constructor(
    private route: ActivatedRoute,
    private us: UserService,
    private cs: CategoryService,
    private router: Router,
    private mc: MainComponent
  ) {
    this.username = this.route.snapshot.paramMap.get('userName');
    this.mc.items = [
      {"route": "home", "name":"Home"}
    ];
  }

  ngOnInit() {
    this.us.getUserExists(this.username, true).subscribe((resp: number) => {
      if(resp != 0){
        this.isValid = true;
        let userid: number = resp;
        this.getCategoryList(userid);
      } else {
        this.loading = false;
      }
      
      
    })
  }
  public getCategoryList(userid: number){
    this.cs.getCategories(userid).subscribe((cat: category[]) => {
      this.categoryList = cat;
      this.loading = false;
    })
  }
  public goToCategory(user: string, name: string){
    this.router.navigate(['/public',user,name])
  }

}
