import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
const baseImgUrl = environment.imgUrl;
export class AdminComponent {
    constructor(ss, router, ds, cs, ps, us) {
        this.ss = ss;
        this.router = router;
        this.ds = ds;
        this.cs = cs;
        this.ps = ps;
        this.us = us;
        this.profileImage = "";
        this.isValid = false;
        this.userName = localStorage.getItem('userName');
        this.securityKey = localStorage.getItem('securityKey');
        this.identity = this.ds.getIdentity();
        this.items = [];
        this.toastClosing = false;
        this.toastStyle = {};
        this.toastProperties = {
            bgcolor: "#333",
            opacity: "0",
            visibility: "hidden"
        };
        if (this.identity == undefined) {
            this.identity = JSON.parse(localStorage.getItem('ident'));
            console.log(this.identity);
            this.ds.setIdentity(this.identity);
        }
        this.validateUser(this.identity);
        this.getCategory(this.identity);
        this.getUserInfo(this.identity);
    }
    ngOnInit() {
        this.getPropertyTypeList();
        this.ds.profilePicObservable.subscribe(newData => {
            if (newData != null) {
                this.getUserInfo(this.identity);
            }
        });
        this.ds.toastObservable.subscribe(newData => {
            if (newData != null) {
                this.toastMessage = newData["message"];
                this.toastProperties.opacity = "0.8";
                this.toastProperties.visibility = "visible";
                if (newData["status"] == 'error') {
                    this.toastProperties.bgcolor = "#670000";
                }
                this.setToastStyle(this.toastProperties);
                this.toastTimer();
            }
        });
    }
    toastTimer() {
        var that = this;
        setTimeout(function () { that.closeToast(); }, 5000);
    }
    toastHover(state) {
        if (state) {
            if (!this.toastClosing) {
                this.toastProperties.opacity = "1";
                this.toastProperties.visibility = "visible";
            }
        }
        else {
            if (!this.toastClosing) {
                this.toastProperties.opacity = "0.8";
            }
        }
        this.setToastStyle(this.toastProperties);
        this.toastTimer();
    }
    closeToast() {
        this.toastClosing = true;
        this.toastProperties.opacity = "0";
        this.toastProperties.visibility = "hidden";
        this.setToastStyle(this.toastProperties);
        this.toastClosing = false;
    }
    setToastStyle(properties) {
        this.toastStyle = properties;
    }
    getUserInfo(ident) {
        this.us.getUserInfo(ident).subscribe((usrInfo) => {
            this.ds.setUserInfo(usrInfo);
            if (usrInfo.base64 == "profile.jpg") {
                this.getBase64ImageFromUrl(baseImgUrl + ident.userid + '/', 'profile.jpg?' + (new Date()).getTime()).then(data => {
                    this.getImageData(data.toString()).then(dim => {
                        this.profileImage = data.toString();
                    });
                });
            }
        });
    }
    getPropertyTypeList() {
        this.ps.getPropertyTypeList().subscribe((ptl => {
            this.ds.setPropertyTypeList(ptl);
        }));
    }
    getCategory(ident) {
        console.log(ident);
        this.cs.getAllCategories(ident).subscribe((cat) => {
            this.ds.setCategoryData(cat);
        });
    }
    validateUser(ident) {
        if (ident != null) {
            this.ss.getValidity(ident).subscribe(uv => { if (uv["status"] != "success") {
                this.router.navigateByUrl('/login');
            }
            else {
                this.isValid = true;
            } });
        }
        else {
            this.router.navigateByUrl('/login');
        }
    }
    getBase64ImageFromUrl(imageUrl, imgname) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let base64url = imageUrl + imgname;
            var res = yield fetch(base64url);
            var blob = yield res.blob();
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.addEventListener("load", function () { resolve(reader.result); }, false);
                reader.onerror = () => { return reject(this); };
                reader.readAsDataURL(blob);
            });
        });
    }
    getImageData(result) {
        let image = new Image();
        let imgHeight;
        let imgWidth;
        image.src = result;
        return new Promise(resolve => {
            image.onload = rs => {
                imgWidth = image.width;
                imgHeight = image.height;
                let dim;
                dim = [imgWidth, imgHeight];
                resolve(dim);
            };
        });
    }
}
