import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { identity } from 'src/app/_models/main.model';
export class ObjectComponent {
    constructor(mc, route, us, cs) {
        this.mc = mc;
        this.route = route;
        this.us = us;
        this.cs = cs;
        this.isValid = false;
        this.loading = true;
        this.categoryexists = false;
        this.username = this.route.snapshot.paramMap.get('userName');
        this.categoryname = this.route.snapshot.paramMap.get('categoryName');
        this.objectid = this.route.snapshot.paramMap.get('objectId');
        this.mc.items = [
            { "route": "home", "name": "Home" },
            { "route": this.username, "name": this.username + "'s Collections" },
            { "route": this.username + "/" + this.categoryname, "name": this.categoryname }
        ];
    }
    ngOnInit() {
        this.us.getUserExists(this.username, true).subscribe((resp) => {
            if (resp != 0) {
                this.isValid = true;
                let userid = resp;
                this.loadAllData(userid);
            }
            else {
                this.loading = false;
            }
        });
    }
    loadAllData(userid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("start");
            this.getCategoryList(userid);
            console.log("end");
        });
    }
    getCategoryList(userid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("start getCategoryList");
            this.cs.getCategories(userid).subscribe((cat) => {
                console.log("return value from getCategories");
                let currentCategoryId = 0;
                this.categoryList = cat;
                this.categoryList.forEach(catListItem => {
                    if (catListItem.identity.categoryname == this.categoryname) {
                        this.category = catListItem;
                        console.log(catListItem);
                        currentCategoryId = catListItem.identity.categoryid;
                        let ident = new identity();
                        ident.userid = userid;
                        ident.username = this.username;
                        ident.categoryid = currentCategoryId;
                        ident.categoryname = this.categoryname;
                        this.identity = ident;
                        this.categoryexists = true;
                        if (catListItem.propertycount > 0 && (catListItem.objectcount > 0 || catListItem.wishlistcount > 0) && catListItem.gridcount > 0) {
                        }
                        else {
                            this.loading = false;
                        }
                    }
                });
            });
        });
    }
}
