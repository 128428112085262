/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./object.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./object.component";
import * as i3 from "../main.component";
import * as i4 from "@angular/router";
import * as i5 from "../../_services/user.service";
import * as i6 from "../../_services/category.service";
var styles_ObjectComponent = [i0.styles];
var RenderType_ObjectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ObjectComponent, data: {} });
export { RenderType_ObjectComponent as RenderType_ObjectComponent };
export function View_ObjectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "hrdr-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Details"]))], null, null); }
export function View_ObjectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-object", [], null, null, null, View_ObjectComponent_0, RenderType_ObjectComponent)), i1.ɵdid(1, 114688, null, 0, i2.ObjectComponent, [i3.MainComponent, i4.ActivatedRoute, i5.UserService, i6.CategoryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ObjectComponentNgFactory = i1.ɵccf("app-object", i2.ObjectComponent, View_ObjectComponent_Host_0, {}, {}, []);
export { ObjectComponentNgFactory as ObjectComponentNgFactory };
