<div class="container-fluid hrdr-login" ></div>
<div class="container-fluid hrdr-login-bg" ></div>
<div class="container-fluid">
  <div class="row hrdr-login-row">
    <div class="col-12" style="text-align: center; height:20em;">
      <div class="hrdr-login-logo">Hoarder</div>
      <div class="hrdr-login-logo-sub">Collecting made <span style="color: #0081bd;">easy</span></div>
    </div>
  </div>
  <div class="row hrdr-login-row">
    <div class="col-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>