import { Component, OnInit }                from '@angular/core';
import { ActivatedRoute }                   from '@angular/router';
import { FormGroup }                		    from '@angular/forms';
import { UserService }                      from '../../_services/user.service';
import { CategoryService }                  from '../../_services/category.service';
import { FilterService }                    from '../../_services/filter.service';
import { FilterControlService }             from '../../_services/filter-control.service';
import { GridService }                      from '../../_services/grid.service';
import { ObjectService }                    from '../../_services/object.service';
import { DataService }                      from '../../_services/data.service';
import { AdminComponent }                   from '../admin.component';
import { category, identity, propertyGrid, user } from '../../_models/main.model';
import { PropertyGridService }              from 'src/app/_services/property-grid.service';
import { TagService } from 'src/app/_services/tag.service';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [FilterService,FilterControlService]
})
export class CategoryComponent implements OnInit {
  identity: identity;
  showWishlist: boolean;
  objectCount: number;
  gridCount: number;
  propertyCount: number;
  userExists: string;
  category: category;
  catName: string = "";
  categoryExists: boolean;
  filters: any;
  form: FormGroup;
  gridList: any;
  objectList: any;
  lastObject: number = 0;
  showNumber: number = 25;
  allShown: boolean = false;
  loading: boolean = true;
  cCategoryGrid: any;
  cCategoryConfig: any;
  cFilterList: any;
  cDetailGrid: any;
  cObjectList: any;
  cShowNumber: number;
  cLastObject: number;
  sortby = {values: []};
  sorter:     string = "";
  sortorder:  string = "asc";
  urlTag:     string = "";
  urlTagList: string[] = [];
  userInfo: user;
  constructor(
    private route: ActivatedRoute,
    private ac: AdminComponent,
    private cs: CategoryService,
    private ds: DataService,
    private fcs: FilterControlService,
    private fs: FilterService,
    private gs: GridService,
    private os: ObjectService,
    private pgs: PropertyGridService,
    private us: UserService,
    private ts: TagService
  ) { 
    this.identity = JSON.parse(localStorage.getItem('ident'));
    this.ac.validateUser(this.identity);
    setTimeout(()=> {
      this.ac.items = [
        {"route": "dashboard","name":"Home"},
        {"route": "collections","name":"Your collections"}
      ];
    }, 0);
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.urlTag = params['tag'];
        this.ts.getTag(this.urlTag, this.identity.userid, this.identity.categoryid).subscribe(tagid => {
          if(tagid != 0){
            this.urlTagList.push(tagid.toString());
          }
        });
    });
    this.userInfo = this.ds.getUserInfo();
    if(this.userInfo == undefined){
      this.us.getUserInfo(this.identity).subscribe((usrInfo: user) =>{
        this.userInfo = usrInfo;
      })
    }
    // check the last opened object
    this.cLastObject = this.ds.getLastObject();
    if(this.cLastObject != undefined){this.lastObject = this.cLastObject;}
    // check the number of items to show
    this.cShowNumber = this.ds.getShowNumber();
    if(this.cShowNumber != undefined){this.showNumber = this.cShowNumber;} else {this.showNumber = 25;}
    // check if category config was already cached in data.service
    this.cCategoryConfig = this.ds.getCategoryConfig();
    if(this.cCategoryConfig != undefined && this.cCategoryConfig.name == this.identity.categoryname){
      this.category = this.cCategoryConfig;
      this.gridCount = this.cCategoryConfig.gridcount;
      this.propertyCount = this.cCategoryConfig.propertycount;
      this.catName = this.cCategoryConfig.name;
      this.categoryExists = true;
      this.checkObjectList();
      this.checkFilterList();
    } else {
      let currentCollection = localStorage.getItem('currentCollection');
      if(currentCollection != this.identity.categoryname){
        localStorage.removeItem('currentFilter');
      }
      this.ds.clearCategoryGrid();
      this.ds.clearCategoryConfig();
      this.ds.clearCurrentFilter();
      this.ds.clearFilter();
      this.ds.clearCurrentFilter();
      this.ds.clearDetailGrid();
      this.ds.clearObjectList();
      this.ds.clearShowNumber();
      this.getCategoryConfig(this.identity);
    }

    // check if the gridlist is already cached
    this.cCategoryGrid = this.ds.getCategoryGrid();
    if(this.cCategoryGrid != undefined){
      this.gridList = this.cCategoryGrid;
      this.sorter = this.gridList[0].colActive[0].name;
    } else {
      this.getGrid(this.identity);
    }
    //put the detailgrid in cache for later
    this.getPropertyGrid();
    this.verifyUser(this.identity);
  }

  // fetch the objectList
  private checkObjectList(){
    this.cObjectList = this.ds.getObjectList();
    if(this.cObjectList != undefined){
      this.objectList = this.cObjectList;
      this.loading = false;
    } else {
      this.getObjects();  
    }
  }

  private checkFilterList(){
    // check if the filter was already cached in data.service
    this.cFilterList = this.ds.getFilter();
    if(this.cFilterList != undefined){
      this.filters = this.cFilterList;
      this.form = this.fcs.toFormGroup(this.filters,this.urlTagList);
      this.wishListSwitch();
    } else {
      this.getFilter(this.identity);
    }
  }

  // check if the user is valid
  private verifyUser(ident: identity){this.us.verifyUser(ident).subscribe(ue =>{this.userExists = ue.toString();})}

  // get config data of the category - number of items, number of properties, ...
  private getCategoryConfig(ident: identity){
    this.cs.getCategory(ident).subscribe((singleCat: category) => {

      if(singleCat.identity.categoryid == 0 && singleCat.identity.categoryname == null){
        this.categoryExists = false;
      } else {
        this.category = singleCat;
        localStorage.setItem('currentCollection',this.category.identity.categoryname);
        this.categoryExists = true;
      }
      this.objectCount = singleCat.objectcount;
      this.gridCount = singleCat.gridcount;
      this.propertyCount = singleCat.propertycount;
      this.catName = singleCat.identity.categoryname;
      this.ds.setCategoryConfig(singleCat);
      this.checkObjectList();
      this.checkFilterList();
    });
  }

  // get category grid, used to build the gridview in category.component
  private getGrid(ident: identity){
		this.gs.getGridList(ident).subscribe(grd => {
      this.gridList = grd;
      if(this.gridList[0].colActive[0]){
        this.sorter = this.gridList[0].colActive[0].name;
      }
      this.ds.setCategoryGrid(grd);
		});
  }
  
  // sortby based on what's in the header
  public sortBy(property){
    if(this.sorter != property){
      this.sortorder = "asc";
    } else {
      if(this.sortorder == "asc"){this.sortorder = "desc";} else {this.sortorder = "asc";}
    }
    this.sorter = property;
  }

  // get the filter from filter.service
	private getFilter(ident: identity){
		this.fs.getFilterList(ident).subscribe(fltrs => {
      this.filters = fltrs;
      this.ds.setFilter(fltrs);
      this.form = this.fcs.toFormGroup(this.filters,this.urlTagList);
      this.wishListSwitch();
		});
  }

  // get the objectlist from object.service
	private getObjects(){
    if(this.objectCount > 0){
      this.os.getObjects(this.identity).subscribe(obj => {
        if(this.objectCount < this.showNumber){
          this.showNumber = this.objectCount;
          this.allShown = true;
          }
        this.objectList = obj;
        this.ds.setObjectList(obj);
        this.loading = false;
      });
    } else if(this.objectCount == 0 && this.objectCount != undefined) {
      this.loading = false;
    } else {
      this.getCategoryConfig(this.identity);
    }
  }
  
  // get the property grid to build up the detail-page later
  public getPropertyGrid(){
    this.pgs.getPropertyGridList(this.identity, false).subscribe((propertygrd:propertyGrid) => {
      this.ds.setDetailGrid(propertygrd);
    }) 
  }

    // show more items on the grid
    showMore() {
      if(this.objectCount < this.showNumber + 25){
        this.showNumber = this.objectCount;
        this.allShown = true;
      } else {
        this.showNumber = this.showNumber + 25;
      }
      this.ds.setShowNumber(this.showNumber);
    }
    wishListSwitch(){if(this.form.value["wishlist"]){this.objectCount =  this.category.wishlistcount;} else {this.objectCount = this.category.objectcount;}}
    resetFilter(){this.form.reset({wishlist: this.form.value['wishlist']});}
    copyText(){
      let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = baseUrl + 'public/' + this.identity.username + '/' + this.identity.categoryname;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        let toast: any = {"status": "success", "message": "Public link copied to clipboard"};
        this.ds.hasToastChanged(toast);
      }
    

}
