import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_URL = environment.apiUrl;
export class TagService {
    constructor(http) {
        this.http = http;
    }
    getObjectTags(userName, collectionName, objectID) {
        let url = `${API_URL}/Tag?username=` + userName + `&collectionname=` + collectionName + `&id=` + objectID;
        return this.http.get(url);
    }
    /*getTags(userName: string, collectionName: string){
      let url = `${API_URL}/Tag?username=` + userName + `&collectionname=` + collectionName;
      return this.http.get(url);
    }*/
    getTag(tag, userid, categoryid) {
        let url = `${API_URL}/Tag?userid=` + userid + `&categoryid=` + categoryid + `&tag=` + tag;
        return this.http.get(url);
    }
    saveTags(to) {
        let url = `${API_URL}/Tag`;
        return this.http.post(url, to);
    }
}
TagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagService_Factory() { return new TagService(i0.ɵɵinject(i1.HttpClient)); }, token: TagService, providedIn: "root" });
