<div *ngIf="userExists">
    <div *ngIf="userExists != '0'; else unknownUser">
        <div *ngIf="!deleting; else deleteCat">
            <div *ngIf="category && category.identity.categoryid != 0 && categoryForm">
                <div class="hrdr-title animated fadeIn">Configuration</div>
                <form [formGroup]="categoryForm" style="margin-bottom: 2em;">
                    <div class="row">
                        <div class="col-lg-3 col-12">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group md-form hrdr-form">
                                        <input mdbInput type="text" formControlName="categoryName" class="form-control">
                                        <span *ngIf="categoryMsg != ''" class="hrdr-form-warning animated fadeIn">{{ categoryMsg }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-6">
                            <div class="form-group md-form hrdr-form">
                                <mdb-checkbox formControlName="categoryActive">Active</mdb-checkbox>
                            </div>
                        </div>
                        <div class="col-lg-2 col-6">
                            <div class="form-group md-form hrdr-form">
                                <mdb-checkbox formControlName="categoryPublic">Public</mdb-checkbox>
                            </div>
                        </div>
                    </div>
                </form>
                <div *ngIf="propLoading" style="text-align: center;"><mdb-spinner spinnerColor="blue"></mdb-spinner></div>
                <div *ngIf="!propLoading"  class="animated fadeIn">
                    <mdb-tabset #staticTabs [buttonClass]="'nav md-pills mb-2'" [justified]="false">
                        <!--Panel 1-->
                        <div class="row ">
                            <div class="col-12" style="text-align: right; margin-bottom: 1em;">
                                <button mdbBtn class="hrdr-btn hrdrr-btn-right" (click)="openModal(propertyGrid, -1, 0, identity.categoryname)">Add Property</button>
                            </div>
                        </div>
                        <mdb-tab heading="Properties">
                            <app-property-grid [identity]="identity" [propertyGrid]="propertyGrid"></app-property-grid>
                        </mdb-tab>
                        <!--Panel 2-->
                        <mdb-tab heading="Grid" *ngIf="propertyList">
                            <app-grid [identity]="identity" [grid]="grid"></app-grid>
                        </mdb-tab>
                    </mdb-tabset>
                    <button mdbBtn flat="true" *ngIf="identity.categoryid != 0" (click)="deleteCatModal.show()" mdbWavesEffect>Delete collection</button>
                </div>
                <div mdbModal style="margin-top: 20%;" #deleteCatModal="mdbModal" class="modal fade top" tabindex="10000" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-notify modal-danger modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style="color: white; font-weight: bold;">Are you sure?</div>
                            <div class="modal-body">
                                This action will <strong>permanently</strong> remove this category and all it's objects from the system.
                                <div class="form-group md-form"><mdb-checkbox [(ngModel)]="understood">I understand</mdb-checkbox></div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" mdbBtn color="danger" class="relative waves-light" [disabled]="!understood" mdbWavesEffect (click)="deleteCategory(); deleteCatModal.hide(); ">Delete</button>
                                <button type="button" mdbBtn color="light" class="waves-light" aria-label="Close" (click)="deleteCatModal.hide();" mdbWavesEffect>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #deleteCat class="animated fadeIn">
            <div class="hrdr-title">Deleting...</div>
            <mdb-spinner spinnerColor="blue"></mdb-spinner> Collection <span style="font-weight: bold;">{{identity.categoryname}}</span> is being deleted, {{identity.username | titlecase}}
          </ng-template>

        <ng-template #unknownCat>
            <div class="hrdr-title">Unknown collection</div>
            <p>Collection <strong>{{identity.categoryname}}</strong> does not exist for user {{identity.username | titlecase}}.</p>
        </ng-template>
    </div>
    <ng-template #unknownUser>
        <div class="row hrdrr-breadcrumb">
                <a [routerLink]="['']">Home</a>
        </div>
        <div class="hrdr-title">Unknown user</div>
        <p>Hmm... user doesn't seem to exist!</p>
    </ng-template>
</div>