import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class DataService {
    constructor() {
        this.propertySubject = new BehaviorSubject(null);
        this.propertyObservable = this.propertySubject.asObservable();
        this.profilePicSubject = new BehaviorSubject(null);
        this.profilePicObservable = this.profilePicSubject.asObservable();
        this.toastSubject = new BehaviorSubject(null);
        this.toastObservable = this.toastSubject.asObservable();
    }
    // Get the data
    getCategoryConfig() { return this.categoryConfig; }
    getCategoryData() { return this.categoryList; }
    getCategoryGrid() { return this.categoryGrid; }
    getCurrentFilter() { return this.currentFilter; }
    getDetailGrid() { return this.detailGrid; }
    getFilter() { return this.filter; }
    getIdentity() { return this.identity; }
    getObjectList() { return this.objectList; }
    getPropertyTypeList() { console.log(this.propertyTypeList); return this.propertyTypeList; }
    getShowNumber() { return this.showNumber; }
    getLastObject() { return this.lastObject; }
    getUserInfo() { return this.userInfo; }
    // Set the data
    setDetailGrid(data) { this.detailGrid = data; }
    setCategoryConfig(data) { this.categoryConfig = data; }
    setCategoryData(data) { this.categoryList = data; }
    setCategoryGrid(data) { this.categoryGrid = data; }
    setCurrentFilter(data) { this.currentFilter = data; }
    setFilter(data) { this.filter = data; }
    setIdentity(data) { this.identity = data; }
    setObjectList(data) { this.objectList = data; }
    setPropertyTypeList(data) { this.propertyTypeList = data; }
    setShowNumber(data) { this.showNumber = data; }
    setLastObject(data) { this.lastObject = data; }
    setUserInfo(data) { this.userInfo = data; }
    // Clear the data
    clearAll() {
        this.clearDetailGrid();
        this.clearCategoryConfig();
        this.clearCategoryData();
        this.clearCategoryGrid();
        this.clearCurrentFilter();
        this.clearFilter();
        this.clearIdentity();
        this.clearObjectList();
        this.clearPropertyTypeList();
        this.clearShowNumber();
        this.clearLastObject();
        this.clearUserInfo();
    }
    clearDetailGrid() { this.detailGrid = undefined; }
    clearCategoryConfig() { this.categoryConfig = undefined; }
    clearCategoryData() { this.categoryList = undefined; }
    clearCategoryGrid() { this.categoryGrid = undefined; }
    clearCurrentFilter() { this.currentFilter = undefined; }
    clearFilter() { this.filter = undefined; }
    clearIdentity() { this.identity = undefined; }
    clearObjectList() { this.objectList = undefined; }
    clearPropertyTypeList() { this.propertyTypeList = undefined; }
    clearShowNumber() { this.showNumber = undefined; }
    clearLastObject() { this.lastObject = undefined; }
    clearUserInfo() { this.userInfo = undefined; }
    // listeners to see if data has changed  
    hasPropertyChanged(data) { this.propertySubject.next(data); }
    ;
    hasProfilePicChanged(data) { this.profilePicSubject.next(data); }
    ;
    hasToastChanged(data) { this.toastSubject.next(data); }
    ;
}
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
