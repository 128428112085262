import { Component, Input } from '@angular/core';
import { identity, propertyGrid, propertyGridConfig }     from '../../_models/main.model';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { CategoryConfigComponent } from '../../admin/category-config/category-config.component';
import { PropertyGridService } from '../../_services/property-grid.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { PropertyGridConfigPropComponent } from '../../_modals/property-grid-config-prop/property-grid-config-prop.component';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { PropertyService } from 'src/app/_services/property.service';
import { DataService } from 'src/app/_services/data.service';

const tstoptions = { closeButton: true, positionClass: 'hrdrr-toast', toastClass: 'opacity' };

@Component({
  selector: 'app-property-grid',
  templateUrl: './property-grid.component.html',
  styleUrls: ['./property-grid.component.scss']
})
export class PropertyGridComponent {
  modalRef: MDBModalRef;
  propTypeList: any;
  @Input() identity: identity;
  @Input() propertyGrid: propertyGrid;
  

  constructor(
    private ccc: CategoryConfigComponent,
    private pgs: PropertyGridService,
    private ts: ToastService,
    private ps: PropertyService,
    private modalService: MDBModalService,
    private ds: DataService
  ){
    this.getPropertyTypeList();
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data,
        event.previousIndex,
        event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex, event.currentIndex);
    }
    this.saveGrid(this.propertyGrid);
  }

  public getPropertyTypeList(){
    this.ps.getPropertyTypeList().subscribe(pt => {
      this.propTypeList = pt;
    })
  }

  openModal(propertyGrid: any, i: number, col: number, categoryname: string) {
    this.modalRef = this.modalService.show(PropertyGridConfigPropComponent, { class: 'modal-lg', data: {propertyGrid, i, col, categoryname}});
  }

  public saveGrid(propertyGrid: any){
    console.log("save propertygrid - ",propertyGrid);
    let pgc = new propertyGridConfig;
    pgc.identity = this.identity;
    console.log(propertyGrid);
    pgc.propertyGrid = propertyGrid;
    console.log(pgc);
    this.pgs.savePropertyGridList(pgc).subscribe(resp => {
      let toast: any = {"status": resp["status"], "message": resp["message"]};
      this.ds.hasToastChanged(toast);
    });
  }
}
