import { Component, Input, OnInit } from '@angular/core';
import { category } from 'src/app/_models/main.model';

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent implements OnInit {

  constructor() { }


  @Input() category: category;

  ngOnInit() {
  }

}
