import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { FormControlService } from '../../_services/form-control.service';
import { objectDetail, propertyList, img, imgData, tagObject } from '../../_models/main.model';
import { FormControl, FormGroup } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import { WarningComponent } from '../../_modals/warning/warning.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
const baseImgUrl = environment.imgUrl;
export class ObjectComponent {
    constructor(ac, cs, ds, fcs, os, ps, ts, us, is, route, pgs, imageCompress, router, modalService) {
        this.ac = ac;
        this.cs = cs;
        this.ds = ds;
        this.fcs = fcs;
        this.os = os;
        this.ps = ps;
        this.ts = ts;
        this.us = us;
        this.is = is;
        this.route = route;
        this.pgs = pgs;
        this.imageCompress = imageCompress;
        this.router = router;
        this.modalService = modalService;
        this.understood = false;
        this.saving = false;
        this.object = new objectDetail;
        this.propertyList = new propertyList;
        this.propLoading = true;
        this.objLoading = true;
        this.thumbsLoading = true;
        this.currentImgIndex = 0;
        this.image = new img();
        this.action = "default";
        this.deleting = false;
        this.editor = false;
        this.hideElement = true;
        this.isLoading = true;
        this.tagEmpty = false;
        this.tagExists = false;
        this.wishForm = new FormGroup({ wishlist: new FormControl() });
        this.currentImg = null;
        this.currentImgLoading = false;
        this.imgActive = [];
        this.imgDelete = [];
        this.identity = JSON.parse(localStorage.getItem('ident'));
        this.ac.validateUser(this.identity);
        if (this.route.snapshot.paramMap.get('objectId') != 'new') {
            this.identity.id = parseInt(this.route.snapshot.paramMap.get('objectId'));
        }
        else {
            this.identity.id = 0;
        }
        localStorage.setItem('ident', JSON.stringify(this.identity));
        this.imgUrl = baseImgUrl + this.identity.userid + "\\" + this.identity.categoryid + "\\" + this.identity.id + "\\";
        this.thumbUrl = this.imgUrl + "t_";
        setTimeout(() => {
            this.ac.items = [
                { "route": "dashboard", "name": "Home" },
                { "route": "collections", "name": "Your collections" },
                { "route": this.identity.categoryname, "name": this.identity.categoryname }
            ];
        }, 0);
    }
    onResize(event) {
        this.imgSizer();
    }
    ngOnInit() {
        this.imgSizer();
        this.cDetailGrid = this.ds.getDetailGrid();
        if (this.cDetailGrid != undefined) {
            this.propertyGrid = this.cDetailGrid;
            this.propLoading = false;
        }
        else {
            this.getPropertyGrid(this.identity);
        }
        this.verifyUser(this.identity);
    }
    verifyUser(identity) {
        this.us.verifyUser(identity).subscribe(ue => {
            this.userExists = ue.toString();
            if (this.userExists != '0') {
                this.getCategory(this.identity);
            }
        });
    }
    drop(event) {
        moveItemInArray(this.imgActive, event.previousIndex, event.currentIndex);
        this.currentImgIndex = event.currentIndex;
        this.imgActive.forEach(this.imgUpdateSort);
    }
    imgUpdateSort(item, index) {
        if (item.id != index) {
            if (item.action != 'update' && item.action != 'insert') {
                item.action = "sort";
            }
            item.id = index;
        }
    }
    createObject() {
        this.object.identity = this.identity;
        this.object.tags = [];
        this.object.properties = [];
        this.loadFirstImage();
        this.getTagList(this.identity);
        this.getObject(this.identity);
        this.isLoading = false;
    }
    getCategoryList(ident) { this.cs.getAllCategories(ident).subscribe((cat) => { this.ds.setCategoryData(cat); }); }
    getCategory(ident) {
        this.cs.getCategory(ident).subscribe((cat) => {
            this.category = cat;
            if (!this.deleting) {
                this.createObject();
            }
            this.ds.setCategoryConfig(cat);
        });
    }
    loadFirstImage() {
        let firstImg = new imgData();
        this.is.getImageList(this.identity).subscribe((images) => {
            if (images.length > 0) {
                firstImg.action = "";
                firstImg.id = 0;
                firstImg.name = images[0].toString();
                this.getBase64ImageFromUrl(this.imgUrl, firstImg.name).then(data => {
                    this.getImageData(data.toString()).then(dim => {
                        firstImg.base64 = data.toString();
                        firstImg.width = dim[0];
                        firstImg.height = dim[1];
                        this.imgSizer();
                        this.currentImg = firstImg;
                    });
                });
            }
            this.getImageList(images);
        });
    }
    getImageList(images) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let imgObject = [];
            let i = 0;
            let ix;
            for (ix = 0; ix < images.length; ++ix) {
                let img = new imgData();
                img.name = images[ix].toString();
                img.action = "";
                img.id = ix;
                imgObject.push(img);
                this.getBase64ImageFromUrl(this.imgUrl, img.name).then(data => {
                    this.getImageData(data.toString()).then(dim => {
                        img.base64 = data.toString();
                        img.width = dim[0];
                        img.height = dim[1];
                        imgObject[img.id] = img;
                        this.getBase64ImageFromUrl(this.thumbUrl, img.name).then(data => {
                            img.thumbnail = data.toString();
                        });
                    });
                });
                i++;
                if (i == images.length) {
                    this.imgActive = imgObject;
                    this.isLoading = false;
                }
            }
        });
    }
    getBase64ImageFromUrl(imageUrl, imgname) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let base64url = imageUrl + imgname;
            var res = yield fetch(base64url);
            var blob = yield res.blob();
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.addEventListener("load", function () { resolve(reader.result); }, false);
                reader.onerror = () => { return reject(this); };
                reader.readAsDataURL(blob);
            });
        });
    }
    getImageData(result) {
        let image = new Image();
        let imgHeight;
        let imgWidth;
        image.src = result;
        return new Promise(resolve => {
            image.onload = rs => {
                imgWidth = image.width;
                imgHeight = image.height;
                let dim;
                dim = [imgWidth, imgHeight];
                resolve(dim);
            };
        });
    }
    getTagList(ident) {
        this.ts.getObjectTags(ident.username, ident.categoryname, ident.id).subscribe((tags) => {
            this.object.tags = tags;
        });
    }
    getPropertyGrid(ident) {
        this.pgs.getPropertyGridList(ident, false).subscribe((propertygrd) => {
            this.propertyGrid = propertygrd;
            this.ds.setDetailGrid(propertygrd);
            this.createObject();
            this.propLoading = false;
        });
    }
    getObject(ident) {
        this.os.getObject(ident).subscribe((obj) => {
            if (obj.wishlist == true) {
                this.lblWishList = "Move to collection?";
                this.object.wishlist = true;
            }
            else {
                this.lblWishList = "Put on wishlist?";
                this.object.wishlist = false;
            }
            this.wishForm.reset();
            this.object.properties = obj.properties;
            this.form = this.fcs.toFormGroup(this.propertyGrid, this.object);
            this.objLoading = false;
        });
    }
    deleteObject() {
        this.deleting = true;
        let deleteImg = new img;
        deleteImg.identity = this.identity;
        let deleteImages = [];
        this.imgActive.forEach(imgs => {
            imgs.action = "delete";
            imgs.base64 = "";
            imgs.thumbnail = "";
            deleteImages.push(imgs);
        });
        deleteImg.images = deleteImages;
        if (deleteImg.images.length != 0) {
            this.is.uploadImage(deleteImg).subscribe(resp => {
            });
        }
        this.object.tags = [];
        this.object.properties = [];
        this.os.deleteObject(this.object).subscribe(resp => {
            this.getCategoryList(this.identity);
            this.getCategory(this.identity);
            this.os.getObjects(this.identity).subscribe(obj => { this.ds.setObjectList(obj); this.router.navigate(['/admin', this.identity.categoryname]); });
            let toast = { "status": resp["status"], "message": resp["message"] };
            this.ds.hasToastChanged(toast);
        });
    }
    saveObject() {
        this.saving = true; // set saving so spinners appear
        let to = new tagObject; // generate new tagobject
        let finalImg = new img; // generate new imageobject
        let currentIdentity;
        currentIdentity = Object.assign({}, this.identity);
        to.tags = this.object.tags; // set tags
        let uploadImages = [];
        this.imgDelete.forEach(imgs => {
            let img = new imgData;
            img.name = imgs;
            img.action = "delete";
            uploadImages.push(img);
        });
        this.imgActive.forEach(imgs => { if (imgs.action != "") {
            uploadImages.push(imgs);
        } });
        finalImg.images = uploadImages;
        let keepers = [];
        this.propertyGrid.forEach(propCols => {
            propCols.properties.forEach(props => {
                if (props.keep) {
                    keepers.push(props.id);
                }
            });
        });
        if (this.object.wishlist == true && this.wishForm.value["wishlist"] == true) {
            this.object.wishlist = false;
        }
        else if (this.object.wishlist == false && this.wishForm.value["wishlist"] == null) {
            this.object.wishlist = false;
        }
        else {
            this.object.wishlist = true;
        }
        // set all object properties
        this.object.properties.forEach(val => { val.value = this.form.value[val.label]; });
        if (this.object.identity.id != 0) {
            to.identity = currentIdentity;
            finalImg.identity = currentIdentity;
            this.os.updateObject(this.object).subscribe(resp => {
                if (resp["status"] == "success") {
                    this.doAfterSave(to, finalImg);
                }
                else {
                    this.saving = false;
                }
                let toast = { "status": resp["status"], "message": resp["message"] };
                this.ds.hasToastChanged(toast);
            });
        }
        else {
            this.os.saveObject(this.object).subscribe(resp => {
                if (resp["status"] == "success") {
                    currentIdentity.id = parseInt(resp["id"]);
                    to.identity = currentIdentity;
                    finalImg.identity = currentIdentity;
                    this.doAfterSave(to, finalImg);
                    this.imgActive = [];
                    this.action = "saved";
                    this.form.reset();
                    this.createObject();
                }
                else {
                    this.saving = false;
                }
                let toast = { "status": resp["status"], "message": resp["message"] };
                this.ds.hasToastChanged(toast);
            });
        }
        this.action = "default";
    }
    doAfterSave(to, finalImg) {
        this.ts.saveTags(to).subscribe(resp => { if (resp["status"] == "success") {
            this.getTagList(this.identity);
        } });
        this.is.uploadImage(finalImg).subscribe(resp => { console.log(resp); if (resp["status"] == "success") {
            this.imgActive.forEach(imgs => { imgs.action = ""; });
        } });
        this.wishForm.reset();
        this.updateCache();
        this.saving = false;
    }
    updateCache() {
        this.getObject(this.identity);
        this.os.getObjects(this.identity).subscribe(obj => { this.ds.setObjectList(obj); });
        this.getCategoryList(this.identity);
        this.getCategory(this.identity);
        this.getTagList(this.identity);
    }
    deleteFileSelected() {
        let id = this.currentImgIndex;
        let length = this.imgActive.length;
        if (id + 1 == length && length > 0) {
            this.currentImgIndex = id - 1;
        }
        else if (id + 1 < length && id > 0) {
            this.currentImgIndex = id;
        }
        else if (id == 0 && length > 1) {
            this.currentImgIndex = id;
        }
        else if (id == 0 && length == 1) {
            this.currentImgIndex = -1;
        }
        if (this.imgActive[id].action == "insert") {
            this.imgActive.splice(id, 1);
        }
        else {
            this.imgDelete.push(this.imgActive[id].name);
            this.imgActive.splice(id, 1);
        }
    }
    onFileSelected(event) {
        let imgBoxWidth = this.imgBoxWidth;
        this.currentImgLoading = true;
        this.imgOpacity = "0.4";
        let nrOfImages = 6 - this.imgActive.length;
        let imgWarning = "";
        if (event.length > nrOfImages) {
            if (nrOfImages == 1) {
                imgWarning = "You selected too many images, you can only have a total of 6. Only the first one is taken";
            }
            else {
                imgWarning = "You selected too many images, you can only have a total of 6. Only the first " + nrOfImages + " are taken";
            }
            this.modalRef = this.modalService.show(WarningComponent, { class: 'modal-sm', data: { imgWarning } });
        }
        for (let index = 0; index < nrOfImages && index < event.length; index++) {
            let imgName = uuidv4() + ".jpg";
            let currentImgThumb = {};
            const element = event[index];
            const reader = new FileReader();
            reader.readAsDataURL(element);
            reader.onload = () => {
                let imageData = new imgData;
                imageData.action = "insert";
                let image = new Image();
                image.src = reader.result.toString();
                image.addEventListener('load', function () {
                    imageData.width = image.width;
                    imageData.height = image.height;
                    imageData.name = imgName;
                    if (imageData.width > imageData.height) {
                        let height = (imgBoxWidth / 6 - (imageData.height * ((imgBoxWidth / 6) / imageData.width))) / 2;
                        currentImgThumb = { 'width': '100%', 'margin-top': height + 'px' };
                    }
                    else {
                        currentImgThumb = { 'height': imgBoxWidth / 6 + 'px' };
                    }
                });
                this.compressFile(image.src).then(data => {
                    imageData.base64 = data;
                    this.compressImage(imageData, 300).then(compressed => { imageData.thumbnail = compressed.toString(); });
                    this.compressImage(imageData, 1280).then(compressed => { imageData.base64 = compressed.toString(); this.currentImgLoading = false; this.isLoading = false; });
                    imageData.id = this.imgActive.length;
                    this.currentImgIndex = this.imgActive.length;
                    this.imgActive.push(imageData);
                    this.currentImgLoading = false;
                    this.imgOpacity = "1";
                });
            };
        }
    }
    imgSizer() {
        this.innerWidth = window.innerWidth;
        if (this.innerWidth < 768) {
            this.imgBoxHeight = this.innerWidth * 0.67;
            this.imgBoxWidth = this.innerWidth;
        }
        else if (this.innerWidth > 767 && this.innerWidth < 992) {
            this.imgBoxWidth = this.innerWidth * 0.78;
            this.imgBoxHeight = this.innerWidth * 0.54;
        }
        else {
            this.imgBoxWidth = this.innerWidth * 0.40;
            this.imgBoxHeight = this.innerWidth * 0.28;
        }
    }
    compressFile(image) {
        var orientation = -1;
        return this.imageCompress.compressFile(image, orientation, 80, 80).then(result => {
            let imgResultAfterCompress = result;
            return imgResultAfterCompress;
        });
    }
    compressImage(imageData, size) {
        return new Promise((res, rej) => {
            const img = new Image();
            img.src = imageData.base64;
            img.onload = () => {
                const elem = document.createElement('canvas');
                if (imageData.width > imageData.height) {
                    let scaler = imageData.width / size;
                    elem.width = size;
                    elem.height = imageData.height / scaler;
                }
                else {
                    let scaler = imageData.height / size;
                    elem.height = size;
                    elem.width = imageData.width / scaler;
                }
                const ctx = elem.getContext('2d');
                ctx.drawImage(img, 0, 0, elem.width, elem.height);
                const data = ctx.canvas.toDataURL();
                res(data);
            };
            img.onerror = error => rej(error);
        });
    }
    // TAGS
    goToTag(property, tagid) {
        console.log("property", property);
        console.log("tagid", tagid);
        let tagList = [];
        let currentFilter = JSON.parse(localStorage.getItem('currentFilter'));
        if (currentFilter != undefined) {
            tagList.push(tagid.toString());
            currentFilter[property] = tagList;
            localStorage.setItem('currentFilter', JSON.stringify(currentFilter));
        }
        this.router.navigate(['/admin', this.identity.categoryname]);
    }
    deleteTag(tagindex) {
        var currentTagID = this.object.tags[tagindex].value;
        if (currentTagID == 0) {
            this.object.tags.splice(tagindex, 1);
        }
        else {
            this.object.tags[tagindex].value = -1;
        }
    }
    addTag(tagName, propertyName) {
        if (tagName == undefined || tagName.length == 0) {
            this.tagEmpty = true;
            var tagExists = false;
        }
        else {
            this.tagEmpty = false;
            var tagExists = false;
            var tagList = this.object.tags;
            tagList.forEach(function (tag) {
                if (tag.value != -1) {
                    if (tag.label.toUpperCase() == tagName.toUpperCase()) {
                        tagExists = true;
                    }
                }
            });
            if (!tagExists) {
                var newTag = { "value": 0, "label": tagName.toUpperCase() };
                this.object.tags.push(newTag);
                this.object.tags.sort((a, b) => a['label'].localeCompare(b['label']));
                this.form.controls[propertyName].reset();
            }
        }
        this.tagExists = tagExists;
    }
}
