import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_URL = environment.apiUrl;
export class ObjectService {
    constructor(http) {
        this.http = http;
    }
    deleteObject(object) { const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: object }; let url = `${API_URL}/Object`; return this.http.delete(url, options); }
    getObject(ident) { let url = `${API_URL}/Object?userid=` + ident.userid + `&categoryid=` + ident.categoryid + `&objectid=` + ident.id; return this.http.get(url); }
    getObjects(ident) { let url = `${API_URL}/Object?userid=` + ident.userid + `&categoryid=` + ident.categoryid; return this.http.get(url); }
    saveObject(object) { let url = `${API_URL}/Object`; return this.http.post(url, object); }
    updateObject(object) { let url = `${API_URL}/Object`; return this.http.put(url, object); }
}
ObjectService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObjectService_Factory() { return new ObjectService(i0.ɵɵinject(i1.HttpClient)); }, token: ObjectService, providedIn: "root" });
