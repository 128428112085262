<div class="row">
    <div class="col-12">
      <div class="flex-center">
        <div class="col-lg-6 col-md-8 col-12 card animated fadeIn" style="padding: 2em;">
          <form [formGroup]="loginForm">
            <div class="hrdr-title">Sign in</div>
            <div class="md-form">
              <input [class.is-invalid]="loginMsg == 'email'" mdbInput type="text" autocomplete="email" name="email" [formControlName]="'email'" id="email" class="form-control">
              <label for="email" class="">E-mail</label>
              <div style="min-height:20px;"><span *ngIf="loginMsg == 'email'" class="hrdr-form-warning">Unknown e-mail used</span></div>
            </div>
            <div class="md-form">
              <input [class.is-invalid]="loginMsg == 'pwd'" mdbInput autocomplete="current-password" type="password" name="passWord" [formControlName]="'passWord'" id="passWord" class="form-control">
              <label for="pwd" class="">Password</label>
              <div style="min-height:20px;"><span *ngIf="loginMsg == 'pwd'" class="hrdr-form-warning">Password is incorrect</span></div>
            </div>
            <div class="row">
              <div class="col-8">
                <button type="submit" class="btn hrdr-btn" [disabled]="isLoading" (click)="login(loginForm.get('email').value,loginForm.get('passWord').value)">Submit</button>
                <mdb-spinner *ngIf="isLoading" spinnerType="small" spinnerColor="blue" style="margin-left: 10px; position: absolute; top: 10px;"></mdb-spinner>
              </div>
              <div class="col-4" style="padding-top: 2em; text-align: right;">
                <span class="hrdr-forgot-pwd" [routerLink]="['/login/forgot']">Forgot password?</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 hrdr-login-register">
      Not yet registered? Click <a [routerLink]="['/login/register']">here</a> to create an account!
    </div>
  </div>