import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html'
})

export class WarningComponent implements OnInit{
  imgWarning: string;
  constructor(
    public modalRef: MDBModalRef
  ){}
  ngOnInit(){}
}
