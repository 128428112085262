import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { LoginSubComponent } from './login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { ResetComponent } from './reset/reset.component';
import { ActivateComponent } from './activate/activate.component';
import { RegisterComponent } from './register/register.component';
import { ForgotComponent } from './forgot/forgot.component';


const routes: Routes = [
	{
		path: 'login', 
		component: LoginComponent,
		children: [
			{path: '', component: LoginSubComponent},
            {path: 'reset', component: ResetComponent},
            {path: 'activate', component: ActivateComponent},
            {path: 'register', component: RegisterComponent},
            {path: 'forgot', component: ForgotComponent},
		]
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
