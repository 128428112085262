import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_URL = environment.apiUrl;
export class PropertyGridService {
    constructor(http) {
        this.http = http;
    }
    getPropertyGridList(ident, isactive) { let url = `${API_URL}/PropertyGrid?userid=` + ident.userid + `&categoryid=` + ident.categoryid + `&isactive=` + isactive; return this.http.get(url); }
    savePropertyGridList(pgc) {
        let url = `${API_URL}/PropertyGrid`;
        return this.http.post(url, pgc);
    }
}
PropertyGridService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PropertyGridService_Factory() { return new PropertyGridService(i0.ɵɵinject(i1.HttpClient)); }, token: PropertyGridService, providedIn: "root" });
