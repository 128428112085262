import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { identity, user, User } from '../../_models/main.model';
import { LoginService } from './../login.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataService } from '../../_services/data.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./../login.component.scss'],
  providers:  [LoginService]
})
export class ForgotComponent implements OnInit {
  forgotLoading: boolean = false;
  emailWasSent: boolean = false;
  respMsg: string = "";
  forgotPwdForm = new FormGroup({
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
  });
  deviceInfo = null;
  isLoading = false;
  email: string;
  passWord: string;
  errorMsg = "";
  emailRegisteredMsg = "";
  constructor(
    private loginService: LoginService,
    private router: Router,
    private toastrService: ToastService,
    private deviceService: DeviceDetectorService,
    private ds: DataService,
    private us: UserService
  ) {}

  ngOnInit() {
    this.forgotPwdForm.valueChanges.pipe().subscribe(regForm => {
      this.forgotLoading = true;
      if(!this.forgotPwdForm.get('email').invalid){
        this.us.getEmailRegistered(this.forgotPwdForm.get('email').value).subscribe(resp => {
          console.log(resp);
          if(resp == false){
            this.emailRegisteredMsg = "Email not found"
            this.forgotLoading = false;
          } else {
            this.emailRegisteredMsg = "";
            this.forgotLoading = false;
          }
        })
      } else {
        this.emailRegisteredMsg = "";
        this.forgotLoading = false;
      }
    })
  }

  sendPwdMail(){
    this.us.forgotPassword(this.forgotPwdForm.value["email"]).subscribe(resp =>{
      if(resp["status"] == "success"){
        let guid = resp["message"];
      let usr: user = new user;
      usr.email = this.forgotPwdForm.value["email"];
      let subject = "Reset your Hoarder password"
      let htmlBody = "<!DOCTYPE html><html><body><h1>Reset your password</h1><p>Please reset your password by clicking the link below</p><a href=\"http://test.planetsamzor.be/#/login/reset?i=" + guid + "\">http://test.planetsamzor.be/#/login/reset?i="+ guid +"</a><br /><br /><p>Thank you,</p><p>The Hoarder-team</p></body></html>";
      this.us.sendEmail(usr,htmlBody,subject).subscribe(resp => {
        this.emailWasSent = true;
      });
      } else {
        this.respMsg = "something went wrong, please try again!";
      }
    })
  }
}
