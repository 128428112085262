import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { category, identity } from 'src/app/_models/main.model';
import { CategoryService } from 'src/app/_services/category.service';
import { FilterService } from 'src/app/_services/filter.service';
import { FilterControlService } from 'src/app/_services/filter-control.service';
import { ObjectService } from 'src/app/_services/object.service';
import { TagService } from 'src/app/_services/tag.service';
import { UserService } from 'src/app/_services/user.service';
import { MainComponent } from '../main.component';
import { GridService } from 'src/app/_services/grid.service';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [FilterService,FilterControlService]
})
export class CategoryComponent implements OnInit {
  filterForm: FormGroup;
  allShown: boolean = false;
  category: category;
  filters: any = undefined;
  form: any = [];
  objectCount: number;
  showNumber: number = 25;
  isValid: boolean = false;
  identity: identity;
  loading: boolean = true;
  username: string;
  categoryexists: boolean = false;
  categoryname: string;
  categoryList: category[];
  gridList: any;
  gridCount: number;
  objectList: any = [];
  propertyCount: number;
  sortby = {values: []};
  sorter:     string = "";
  sortorder:  string = "asc";
  urlTag:     string = "";
  urlTagList: string[] = [];
  constructor(
    private route: ActivatedRoute,
    private us: UserService,
    private cs: CategoryService,
    private router: Router,
    private mc: MainComponent,
    private ts: TagService,
    private os: ObjectService,
    private fs: FilterService,
    private fcs: FilterControlService,
    private gs: GridService
  ) { 
    this.username = this.route.snapshot.paramMap.get('userName');
    this.categoryname = this.route.snapshot.paramMap.get('categoryName');
    this.mc.items = [
      {"route": "home", "name":"Home"},
      {"route": this.username,"name": this.username + "'s Collections"}
    ];
  }

  ngOnInit() {
    this.us.getUserExists(this.username, true).subscribe((resp: number) => {
      if(resp != 0){
        this.isValid = true;
        let userid: number = resp;
        this.loadAllData(userid);
      } else {
        this.loading = false;
      }
      
      
    })
  }

  async loadAllData(userid: number){
    console.log("start");
    this.getCategoryList(userid);
    console.log("end");
  }

  async getCategoryList(userid: number){
    console.log("start getCategoryList");
    this.cs.getCategories(userid).subscribe((cat: category[]) => {
      console.log("return value from getCategories");
      let currentCategoryId: number = 0;
      this.categoryList = cat;
      this.categoryList.forEach(catListItem => {
          if(catListItem.identity.categoryname == this.categoryname){
            
            this.category = catListItem;
            console.log(catListItem);
            currentCategoryId = catListItem.identity.categoryid;
            this.objectCount = catListItem.objectcount;
            let ident: identity = new identity();
            ident.userid = userid;
            ident.username = this.username;
            ident.categoryid = currentCategoryId;
            ident.categoryname = this.categoryname;
            this.identity = ident;
            this.categoryexists = true;
            this.getGrid(ident);
            if(catListItem.propertycount > 0 && (catListItem.objectcount > 0 || catListItem.wishlistcount > 0) && catListItem.gridcount > 0){
              this.os.getObjects(ident).subscribe(obj => {
                if(this.objectCount < this.showNumber){
                  this.showNumber = this.objectCount;
                  this.allShown = true;
                }
                console.log(obj);
                this.objectList = obj;
                this.fs.getFilterList(ident).subscribe(fltrs => {
                  this.filters = fltrs;
                  console.log(this.filters);
                  this.form = this.fcs.toFormGroup(this.filters,this.urlTagList);
                  this.wishListSwitch();
                  this.loading = false;
                });
                
              });
            } else {
              this.loading = false;
            }
          }
        }
      )
      
    })
    console.log(this.categoryexists);
    console.log("stop getCategoryList");
  }
  wishListSwitch(){if(this.form.value["wishlist"]){this.objectCount =  this.category.wishlistcount;} else {this.objectCount = this.category.objectcount;}}

  private getGrid(ident: identity){
		this.gs.getGridList(ident).subscribe(grd => {
      this.gridList = grd;
      if(this.gridList[0].colActive[0]){
        this.sorter = this.gridList[0].colActive[0].name;
      }
		});
  }

  showMore() {
    if(this.objectCount < this.showNumber + 25){
      this.showNumber = this.objectCount;
      this.allShown = true;
    } else {
      this.showNumber = this.showNumber + 25;
    }
  }

  // sortby based on what's in the header
  public sortBy(property){
    if(this.sorter != property){
      this.sortorder = "asc";
    } else {
      if(this.sortorder == "asc"){this.sortorder = "desc";} else {this.sortorder = "asc";}
    }
    this.sorter = property;
  }

  resetFilter(){this.form.reset({wishlist: this.form.value['wishlist']});}
}
