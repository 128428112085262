import { Component, OnInit } from '@angular/core';
import { MainComponent } from '../main.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private mc: MainComponent
  ) { 
    this.mc.items = [
      {"route": "home", "name":"Home"},
    ];
  }

  ngOnInit() {
  }

}
