import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { identity, User } from '../../_models/main.model';
import { LoginService } from './../login.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataService } from '../../_services/data.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./../login.component.scss'],
  providers:  [LoginService]
})
export class LoginSubComponent implements OnInit {
  respMsg: string = "";
  loginMsg: string = "";
  loginForm = new FormGroup({
    passWord: new FormControl(),
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
  })
  deviceInfo = null;
  isLoading = false;
  email: string;
  passWord: string;
  errorMsg = "";
  emailRegisteredMsg = "";
  constructor(
    private loginService: LoginService,
    private router: Router,
    private toastrService: ToastService,
    private deviceService: DeviceDetectorService,
    private ds: DataService,
    private us: UserService
  ) {}

  ngOnInit() {}

  login(email: string, passWord: string) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
      const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
    const options = { closeButton: true, positionClass: 'hrdrr-toast', toastClass: 'opacity' };
    this.isLoading = true;
    localStorage.clear();
    const loginUser: User = { email, passWord } as User;
    this.loginService.userLogin(loginUser).subscribe(res => {
      if(res.body[0]){
        console.log(res.body[0]);
          if(!res.body[0].error){
            let usr: User = res.body[0];
            let ident: identity = new identity;
            ident.username = usr.userName;
            ident.userid = usr.userId;
            ident.securitykey = usr.securityKey;
            this.ds.setIdentity(ident);
            localStorage.setItem('ident', JSON.stringify(ident));
            localStorage.setItem('userName', usr.userName);
            localStorage.setItem('securityKey', usr.securityKey);
            this.router.navigateByUrl('/admin');
          } else {
            this.isLoading = false;
            localStorage.removeItem('ident');
            localStorage.removeItem('userName');
            localStorage.removeItem('securityKey');
            this.loginMsg = res.body[0].error;
          }
      } else {
        this.isLoading = false;
        localStorage.clear();
        this.toastrService.error('Something went wrong','', options);
      }
    });
  }

}
