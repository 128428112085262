import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { identity } from '../_models/main.model';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(
	private http: HttpClient
  ) { }
  
  getFilterList(ident: identity){
	  let url = `${API_URL}/Filter?userid=` + ident.userid + `&categoryid=` + ident.categoryid;
	  return this.http.get(url);
  }
}
