import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { gridconfig, identity } from '../_models/main.model';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class GridService {
    constructor(
    private http: HttpClient
    ) { }

    getGridList(ident: identity){
        let url = `${API_URL}/Grid?userid=` + ident.userid + `&categoryid=` + ident.categoryid;
        return this.http.get(url);
    }

    saveGridList(gc: gridconfig){
      let url = `${API_URL}/Grid`;
      return this.http.post(url,gc);
    }
  
}