import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { GridService } from '../../_services/grid.service';
import { gridconfig, identity } from '../../_models/main.model';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import * as iconlist from '../../../assets/iconList.json';
import { DataService } from 'src/app/_services/data.service';
const tstoptions = { closeButton: true, positionClass: 'hrdrr-toast', toastClass: 'opacity' };

@Component({
  selector: 'app-grid-config-prop',
  templateUrl: './grid-config-prop.component.html'
})
export class GridConfigPropComponent implements OnInit{
  identity: identity;
  results: string[];
  i: number;
  userName: string = localStorage.getItem('userName');
  securityKey: string = localStorage.getItem('securityKey');
  iconList: any = iconlist;
  grid: any;
  previewGrid: any;
  showIcon: boolean = false;
  gridForm = new FormGroup ({
    lSize: new FormControl(),
    size: new FormControl(),
    header: new FormControl(),
    bold: new FormControl(),
    showIcon: new FormControl(),
    icon: new FormControl(),
  });
  constructor(
    public modalRef: MDBModalRef,
    private gs: GridService,
    private ds: DataService
    ) {
      this.identity = JSON.parse(localStorage.getItem('ident'));
    }
    closeModal() {
      this.saveGrid(this.gridForm.value);
      this.modalRef.hide();
    }

    ngOnInit(){
      this.previewGrid = this.grid;
      if(this.grid[0].colActive[this.i].icon != ""){this.showIcon = true;}
      this.gridForm.patchValue({
        lSize: this.grid[0].colActive[this.i].lSize,
        size: this.grid[0].colActive[this.i].size,
        header: this.grid[0].colActive[this.i].header,
        bold: this.grid[0].colActive[this.i].bold,
        showIcon: this.showIcon,
        icon: this.grid[0].colActive[this.i].icon
      })
      this.gridForm.valueChanges.pipe(debounceTime(300)).subscribe(grid => {
        this.previewGrid[0].colActive[this.i].lSize = grid.lSize;
        this.previewGrid[0].colActive[this.i].size = grid.size;
        this.previewGrid[0].colActive[this.i].header = grid.header;
        this.previewGrid[0].colActive[this.i].bold = grid.bold;
        this.previewGrid[0].colActive[this.i].icon = grid.icon;
        this.results = this.filter(grid.icon);
      });
      this.results = this.filter("");
    }
  
    public saveGrid(gridForm: any){
      this.grid[0].colActive[this.i].lSize = gridForm.lSize;
      this.grid[0].colActive[this.i].size = gridForm.size;
      this.grid[0].colActive[this.i].header = gridForm.header;
      this.grid[0].colActive[this.i].bold = gridForm.bold;
      let gc = new gridconfig;
      gc.identity = this.identity;
      gc.grid = this.grid[0];
      this.gs.saveGridList(gc).subscribe(resp => {
        let toast: any = {"status": resp["status"], "message": resp["message"]};
        this.ds.hasToastChanged(toast);
      });
    }
    onIconChange(value){this.results = this.filter(value);}
    filter(value: string): string[] {if(!value){return this.iconList.default;} else {return this.iconList.default.filter((item: string) => item.toLowerCase().includes(value.toLowerCase()));}}
}
