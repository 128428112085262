import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { identity, propertyGridConfig } from '../_models/main.model';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PropertyGridService {
    constructor(
    private http: HttpClient
    ) { }

    getPropertyGridList(ident: identity, isactive: boolean){let url = `${API_URL}/PropertyGrid?userid=` + ident.userid + `&categoryid=` + ident.categoryid + `&isactive=` + isactive;return this.http.get(url);}

    savePropertyGridList(pgc: propertyGridConfig){
      let url = `${API_URL}/PropertyGrid`;
      return this.http.post(url,pgc);
    }
  
}