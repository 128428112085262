import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { identity, objectDetail} from '../_models/main.model';

const API_URL = environment.apiUrl;
@Injectable({providedIn:'root'})
export class ObjectService {
  constructor(private http: HttpClient){}
  deleteObject(object: objectDetail){const options = {headers: new HttpHeaders({'Content-Type': 'application/json'}),body: object};let url = `${API_URL}/Object`;return this.http.delete(url, options);}
  getObject(ident: identity){let url=`${API_URL}/Object?userid=`+ident.userid+`&categoryid=`+ident.categoryid+`&objectid=`+ident.id;return this.http.get(url);}
  getObjects(ident: identity){let url=`${API_URL}/Object?userid=`+ident.userid+`&categoryid=`+ident.categoryid;return this.http.get(url);}
  saveObject(object: objectDetail){let url = `${API_URL}/Object`;return this.http.post(url, object);}
  updateObject(object: objectDetail){let url = `${API_URL}/Object`;return this.http.put(url, object);}
  //getObjectCount(ident: identity){let url = `${API_URL}/Category?userid=` + ident.userid + `&categoryid=` + ident.categoryid; return this.http.get(url);}
  //getObjectProperties(userName: string, collectionName: string, isactive: Boolean){let url = `${API_URL}/Property?username=` + userName + `&collectionname=` + collectionName + `&isactive=` + isactive;return this.http.get(url);}
}