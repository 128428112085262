import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FilterElementComponent } from '../_components/filter-element/filter-element.component';
import { MDBSpinningPreloader, MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { FilterPipe } from "../_pipes/filter.pipe";

@NgModule({
    imports: [
        CommonModule,
        MDBBootstrapModulesPro.forRoot(),
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [FilterElementComponent,FilterPipe],
    providers: [MDBSpinningPreloader],
    exports: [
        FilterElementComponent,
        ReactiveFormsModule,
        MDBBootstrapModulesPro,
        FormsModule,
        FilterPipe
    ],
 })
 export class SharedModule { }