/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../login/login.service";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-cookie-service";
import * as i4 from "./logout.component";
import * as i5 from "../_services/data.service";
import * as i6 from "@angular/router";
var styles_LogoutComponent = [];
var RenderType_LogoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
export function View_LogoutComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_LogoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-logout", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i0.ɵprd(512, null, i1.LoginService, i1.LoginService, [i2.HttpClient, i3.CookieService]), i0.ɵdid(2, 114688, null, 0, i4.LogoutComponent, [i1.LoginService, i5.DataService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LogoutComponentNgFactory = i0.ɵccf("app-logout", i4.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };
