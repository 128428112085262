import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tagObject } from '../_models/main.model';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(
    private http: HttpClient
  ) { }

  getObjectTags(userName: string, collectionName: string, objectID: number){
    let url = `${API_URL}/Tag?username=` + userName + `&collectionname=` + collectionName + `&id=` + objectID;
    return this.http.get(url);
  }

  /*getTags(userName: string, collectionName: string){
    let url = `${API_URL}/Tag?username=` + userName + `&collectionname=` + collectionName;
    return this.http.get(url);
  }*/

  getTag(tag: string, userid: number, categoryid: number){
    let url = `${API_URL}/Tag?userid=` + userid + `&categoryid=` + categoryid + `&tag=` + tag;
    return this.http.get(url);
  }

  saveTags(to: tagObject){
    let url = `${API_URL}/Tag`;
    return this.http.post(url,to);
  }

}
