import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filterMain, filterObject } from '../../_models/main.model';

@Component({
  selector: 'app-filter-element',
  templateUrl: './filter-element.component.html',
  styleUrls: ['./filter-element.component.scss']
})
export class FilterElementComponent{
  constructor(
  ) {}

  checkboxList: any = [
    {"value":"", "label":"Show all"},
    {"value":"true", "label":"Yes"},
    {"value":"false", "label":"No"}
  ];

  @Input() filters: filterMain[] = [];
	@Input() filter: filterObject;
  @Input() form: FormGroup;
}
