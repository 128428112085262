import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login.service';
import { user, identity } from '../../_models/main.model';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['../login.component.scss']
})
export class ResetComponent implements OnInit {
  guid: string;
  usr: user = new user();
  dataFetched: boolean = false;
  pwdReset: boolean = false;
  isLoading: boolean = false;
  resetForm = new FormGroup({
    pwd: new FormControl('', [Validators.required]),
    confirmPwd: new FormControl('', [Validators.required])
  }, { validators: this.checkPasswords })
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private us: UserService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.guid = params['i'];
      if(this.guid == undefined){
        this.router.navigateByUrl('/login');
      } else {
        this.us.getUserByGUID(this.guid,'reset').subscribe((resp:user) => {
          let fetchedUser: user = resp;
          if(fetchedUser.email != null){
            let ident: identity = new identity();
            ident.username = fetchedUser.identity.username;
            ident.userid = fetchedUser.identity.userid;
            this.usr.identity = ident;
            this.usr.email = fetchedUser.email;
            this.dataFetched = true;
          } else {
            this.router.navigateByUrl('/login');
          }
        });
      }
    });
  }

  updatePassword(password: string){
    this.usr.passWord = password;
    this.us.resetPassword(this.usr).subscribe(resp => {
      if(resp["status"] == 'success'){
        this.pwdReset = true;
      }
    })
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  const password = group.get('pwd').value;
  const confirmPassword = group.get('confirmPwd').value;
  console.log(password === confirmPassword ? null : { notSame: true });
  return password === confirmPassword ? null : { notSame: true }     
}


}
