import { Component, OnInit } from '@angular/core';
import { identity } from 'src/app/_models/main.model';
import { AdminComponent } from '../admin.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  identity: identity;
  constructor(
    private ac: AdminComponent,
  ) { 
    this.identity = JSON.parse(localStorage.getItem('ident'));
    this.ac.validateUser(this.identity);
    setTimeout(()=> {
      this.ac.items = [
        {"route": "dashboard","name":"Home"}
      ];
    }, 0);
  }

  ngOnInit() {
  }

}
