import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { identity, img } from '../_models/main.model';

const API_URL = environment.apiUrl;
@Injectable({providedIn:'root'})
export class ImageService {
  constructor(private http: HttpClient){}
  //deleteImage(image: img){const options = {headers: new HttpHeaders({'Content-Type': 'application/json'}),body: image};let url=`${API_URL}/Image`;return this.http.delete(url, options);}
  getImageList(ident: identity){let url = `${API_URL}/Image?objectid=` + ident.id; return this.http.get(url);}
  uploadImage(image: img){let url=`${API_URL}/Image`;return this.http.post(url,image);}
}