<div>
    <div class="row">
        <div class="col-6"><h1 class="hrdr-title animated fadeIn " style="margin-bottom: 1em;">Profile</h1></div>
        <div class="col-6">
            <mdb-spinner *ngIf="saving" spinnerColor="blue"></mdb-spinner>
            <button mdbBtn class="hrdr-btn hrdrr-btn-right" (click)="saveUser()" 
            [disabled]="
            saving || 
            usernameMsg != '' ||
            (profileForm.get('email').invalid && profileForm.get('email').touched)">Save</button>
        </div>
    </div>
    <div *ngIf="loading" style="text-align: center;"><mdb-spinner spinnerColor="blue"></mdb-spinner></div>
    <div class="row animated fadeIn" *ngIf="!loading">
        <div class="col-md-4 col-12" style="text-align:center;">
            <div class="flex-center">
                <div style="min-width:225px; min-height: 225px;">
                    <div class="avatar mx-auto" >
                        <input style="opacity: 0; margin-bottom: -24px; float: left;" type="file" id="imageInput" (change)="onFileSelected($event.target.files)" #imageInput />
                        <mdb-icon style="padding-top:30px" (click)="imageInput.click()" class="hrdr-profile-img hrdr-profile-img-none" mdbTooltip="Click to change your picture" *ngIf="profileImage == ''" fas icon="user-alt"></mdb-icon>
                        <img *ngIf="profileImage != ''" [src]="profileImage" style="object-fit: cover; width: 225px; height: 225px; overflow: hidden;"
                        alt="Profile picture" (click)="imageInput.click()" mdbTooltip="Click to change your picture" class="animated fadeIn rounded-circle img-fluid hrdr-profile-img">
                    </div>
                </div>
            </div>
            
        </div>
        <div class="col-md-8 col-12 order-md-first">
            <form [formGroup]="profileForm">
                <div class="row">
                    <div class="md-form col-12">
                        <input [class.is-invalid]="usernameMsg != ''" style="margin-top: -20px;" mdbInput [id]="'username'" [type]="'Text'" [formControlName]="'username'" class="form-control">
                        <label for="username" class="hrdr-form-label">Username</label>
                        <div style="min-height:20px;"><span *ngIf="usernameMsg != ''" class="hrdr-form-warning animated fadeIn">{{usernameMsg}}</span></div>
                    </div>
                </div>
                <div class="row">
                    <div class="md-form col-12">
                        <input [class.is-invalid] = "profileForm.get('email').invalid && profileForm.get('email').touched" style="margin-top: -20px" mdbInput [id]="'email'" [type]="'email'" [formControlName]="'email'" class="form-control" [email]="true">
                        <label for="email" class="hrdr-form-label">Email</label>
                        <div style="min-height:20px;"><span *ngIf="profileForm.get('email').invalid && profileForm.get('email').touched" class="hrdr-form-warning animated fadeIn">Please provide a valid email</span></div>
                    </div>
                </div>
                <div class="row">
                    <mdb-checkbox style="margin:5px 15px 5px 15px;" [formControlName]="'ispublic'">User can be found by others</mdb-checkbox>
                </div>
            </form>
        </div>
    </div>
</div>