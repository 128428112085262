import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_URL = environment.apiUrl;
export class PropertyService {
    constructor(http) {
        this.http = http;
    }
    getPropertyList(ident, isactive) { let url = `${API_URL}/Property?userid=` + ident.userid + `&categoryid=` + ident.categoryid + `&isactive=` + isactive; return this.http.get(url); }
    getPropertyTypeList() { let url = `${API_URL}/PropertyType`; return this.http.get(url); }
    getPropertyValueList(propertyid) {
        let url = `${API_URL}/PropertyValues?propertyid=` + propertyid;
        return this.http.get(url);
    }
    saveProperty(prop) { let url = `${API_URL}/Property`; return this.http.post(url, prop); }
    updateProperty(prop) { let url = `${API_URL}/Property`; return this.http.put(url, prop); }
    deleteProperty(prop) { const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: prop }; let url = `${API_URL}/Property`; return this.http.delete(url, options); }
    // single property value
    // save a single property value
    savePropertyValue(propVal) { let url = `${API_URL}/PropertyValues`; return this.http.post(url, propVal); }
    // update a single property value
    updatePropertyValue(propVal) { let url = `${API_URL}/PropertyValues`; return this.http.put(url, propVal); }
    // delete a single property value
    deletePropertyValue(propVal) {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
            body: propVal };
        let url = `${API_URL}/PropertyValues`;
        return this.http.delete(url, options);
    }
}
PropertyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PropertyService_Factory() { return new PropertyService(i0.ɵɵinject(i1.HttpClient)); }, token: PropertyService, providedIn: "root" });
