import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MustMatch } from '../../_directives/mustMatch.directive';
import { UserService } from '../../_services/user.service';
import { user, identity } from '../../_models/main.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../login.component.scss']
})
export class RegisterComponent implements OnInit {
  emailWasSent: boolean = false;
  emailRegisteredMsg: string = "";
  userExistsMsg: string = "";
  isLoading: boolean = false;
  register: boolean = false;
  registerForm = new FormGroup({
    username: new FormControl('',Validators.required),
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]
      ),
    pwd: new FormControl('', [Validators.required]),
    confirmPwd: new FormControl('', [Validators.required])
  }, MustMatch('pwd', 'confirmPwd'))
  constructor(
    private us: UserService
  ) { }

  ngOnInit() {
    this.registerForm.valueChanges.pipe(debounceTime(300)).subscribe(regForm => {
      if(!this.registerForm.get('username').invalid){
        this.us.getUserExists(this.registerForm.get('username').value, false).subscribe(resp => {
          if(resp == true){
            this.userExistsMsg = "This username is already taken, please select a new one"
          } else {
            this.userExistsMsg = "";
          }
        })
      } else {
        this.userExistsMsg = "";
      }
      if(!this.registerForm.get('email').invalid){
        this.us.getEmailRegistered(this.registerForm.get('email').value).subscribe(resp => {
          if(resp == true){
            this.emailRegisteredMsg = "This email address has already been registered. Log in instead"
          } else {
            this.emailRegisteredMsg = "";
          }
        })
      } else {
        this.emailRegisteredMsg = "";
      }
    })
  }

  registerUser(){
    let usr: user = new user();
    let ident: identity = new identity();
    ident.username = this.registerForm.get('username').value;
    usr.identity = ident;
    usr.email = this.registerForm.get('email').value;
    usr.passWord = this.registerForm.get('pwd').value;
    this.us.registerUser(usr).subscribe(resp => {
      if(resp["status"] == "success"){
        let guid = resp["message"];
        let subject = "Confirm your Hoarder account validation"
        let htmlBody = "<!DOCTYPE html><html><body><h1>Confirm your account</h1><p>Please confirm your account by clicking the link below</p><a href=\"http://test.planetsamzor.be/#/login/activate?i=" + guid + "\">http://test.planetsamzor.be/#/login/activate?i="+ guid +"</a><br /><br /><p>Thank you,</p><p>The Hoarder-team</p></body></html>";
        this.us.sendEmail(usr,htmlBody,subject).subscribe(resp => {
          this.register = true;
        });
      } else {
        // something went wrong
      }
    })

  }

}
