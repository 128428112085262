import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, FormControl } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { identity, user, User } from '../_models/main.model';
import { LoginService } from './login.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataService } from '../_services/data.service';
import { UserService } from '../_services/user.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers:  [LoginService]
})
export class LoginComponent implements OnInit {
  
  forgotLoading: boolean = false;
  emailWasSent: boolean = false;
  respMsg: string = "";
  loginMsg: string = "";
  loginForm = new FormGroup({
    passWord: new FormControl(),
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
  })
  forgotPwdForm = new FormGroup({
    femail: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
  });
  deviceInfo = null;
  isLoading = false;
  email: string;
  passWord: string;
  errorMsg = "";
  emailRegisteredMsg = "";
  constructor(
    private loginService: LoginService,
    private router: Router,
    private toastrService: ToastService,
    private deviceService: DeviceDetectorService,
    private ds: DataService,
    private us: UserService
  ) {}

  ngOnInit() {
    this.forgotPwdForm.valueChanges.pipe().subscribe(regForm => {
      this.forgotLoading = true;
      if(!this.forgotPwdForm.get('femail').invalid){
        this.us.getEmailRegistered(this.forgotPwdForm.get('femail').value).subscribe(resp => {
          console.log(resp);
          if(resp == false){
            this.emailRegisteredMsg = "Email not found"
            this.forgotLoading = false;
          } else {
            this.emailRegisteredMsg = "";
            this.forgotLoading = false;
          }
        })
      } else {
        this.emailRegisteredMsg = "";
        this.forgotLoading = false;
      }
    })
  }




  sendPwdMail(){
    this.us.forgotPassword(this.forgotPwdForm.value["femail"]).subscribe(resp =>{
      if(resp["status"] == "success"){
        let guid = resp["message"];
      let usr: user = new user;
      usr.email = this.forgotPwdForm.value["femail"];
      let subject = "Reset your Hoarder password"
      let htmlBody = "<!DOCTYPE html><html><body><h1>Reset your password</h1><p>Please reset your password by clicking the link below</p><a href=\"http://test.planetsamzor.be/#/login/reset?i=" + guid + "\">http://test.planetsamzor.be/#/login/reset?i="+ guid +"</a><br /><br /><p>Thank you,</p><p>The Hoarder-team</p></body></html>";
      this.us.sendEmail(usr,htmlBody,subject).subscribe(resp => {
        this.emailWasSent = true;
      });
      } else {
        this.respMsg = "something went wrong, please try again!";
      }
    })

  }

  login(email: string, passWord: string) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
      const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
    const options = { closeButton: true, positionClass: 'hrdrr-toast', toastClass: 'opacity' };
    this.isLoading = true;
    localStorage.clear();
    const loginUser: User = { email, passWord } as User;
    this.loginService.userLogin(loginUser).subscribe(res => {
      if(res.body[0]){
        console.log(res.body[0]);
          if(!res.body[0].error){
            let usr: User = res.body[0];
            let ident: identity = new identity;
            ident.username = usr.userName;
            ident.userid = usr.userId;
            ident.securitykey = usr.securityKey;
            this.ds.setIdentity(ident);
            localStorage.setItem('ident', JSON.stringify(ident));
            localStorage.setItem('userName', usr.userName);
            localStorage.setItem('securityKey', usr.securityKey);
            this.router.navigateByUrl('/admin');
          } else {
            this.isLoading = false;
            localStorage.removeItem('ident');
            localStorage.removeItem('userName');
            localStorage.removeItem('securityKey');
            this.loginMsg = res.body[0].error;
          }
      } else {
        this.isLoading = false;
        localStorage.clear();
        this.toastrService.error('Something went wrong','', options);
      }


    });
  }

}
