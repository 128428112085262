<div class="row animated fadeIn" *ngIf="category.objectcount === 0 && (category.propertycount === 0 || category.gridcount === 0)">
    <div class="col-12">
    <p>There are still a few things to do before you can start adding items to your collection</p>
    <br />
    <ul class="animated fadeIn list-group">
            <li class="list-group-item hrdrr-listgrp" [routerLink]="['/admin/' + category.identity.categoryname, 'config']">
                <span style="margin-right: 30px;">Add properties</span>
                <mdb-icon *ngIf="category.propertycount != 0" fas icon="check" style="color: green;"></mdb-icon><mdb-icon *ngIf="category.propertycount == 0" fas icon="times" style="color: red;"></mdb-icon>
            </li>
            <li class="list-group-item hrdrr-listgrp" [routerLink]="['/admin/' + category.identity.categoryname, 'config']">
                    <span style="margin-right: 30px;">Configure a grid</span>
                    <mdb-icon *ngIf="category.gridcount != 0" fas icon="check" style="color: green;"></mdb-icon><mdb-icon *ngIf="category.gridcount == 0" fas icon="times" style="color: red;"></mdb-icon>
                </li>
        </ul>
    </div>
</div>
<div class="row" *ngIf="category.objectcount == 0 && category.propertycount != 0 && category.gridcount != 0" class="animated bounce">
    <div class="col-12" class="hrdrr-nostart">
        Oops, seems you haven't started yet! Click <a [routerLink]="['/admin/' + category.identity.categoryname, 'new']">here</a> to add your first item.
    </div>
</div>