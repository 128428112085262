import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_KEY = environment.apiKey;
const API_URL = environment.apiUrl;
export class UserService {
    constructor(http) {
        this.http = http;
    }
    verifyUser(ident) {
        let url = `${API_URL}/User?username=` + ident.username + `&ispublic=` + false;
        return this.http.get(url);
    }
    getUsers() {
        let url = `${API_URL}/User/getAll`;
        return this.http.get(url);
    }
    updateUser(usr) {
        let url = `${API_URL}/User`;
        return this.http.put(url, usr);
    }
    registerUser(usr) {
        let url = `${API_URL}/User/register`;
        return this.http.post(url, usr);
    }
    forgotPassword(email) {
        let url = `${API_URL}/User/forgot?email=` + email;
        return this.http.get(url);
    }
    getUserExists(username, ispublic) {
        let url = `${API_URL}/User?username=` + username + `&ispublic=` + ispublic;
        return this.http.get(url);
    }
    getEmailRegistered(email) {
        let url = `${API_URL}/User/getEmail?email=` + email;
        return this.http.get(url);
    }
    getUserByGUID(guid, action) {
        let url = `${API_URL}/User/getByGUID?guid=` + guid + `&action=` + action;
        return this.http.get(url);
    }
    resetPassword(usr) {
        let url = `${API_URL}/User/reset`;
        return this.http.put(url, usr);
    }
    activateUser(id) {
        let url = `${API_URL}/User/activate?id=` + id;
        return this.http.get(url);
    }
    sendEmail(usr, htmlBody, subject) {
        let body = {
            "sender": { "name": "Hoarder", "email": "hoarder@planetsamzor.be" },
            "subject": subject,
            "to": [{ "email": usr.email }],
            "htmlContent": htmlBody
        };
        console.log(body);
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'api-key': API_KEY
            })
        };
        console.log(options);
        let url = 'https://api.sendinblue.com/v3/smtp/email';
        return this.http.post(url, body, options);
    }
    getUserInfo(ident) {
        let url = `${API_URL}/User?userid=` + ident.userid + `&securitykey=` + ident.securitykey;
        return this.http.get(url);
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
