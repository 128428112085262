import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_URL = environment.apiUrl;
export class ImageService {
    constructor(http) {
        this.http = http;
    }
    //deleteImage(image: img){const options = {headers: new HttpHeaders({'Content-Type': 'application/json'}),body: image};let url=`${API_URL}/Image`;return this.http.delete(url, options);}
    getImageList(ident) { let url = `${API_URL}/Image?objectid=` + ident.id; return this.http.get(url); }
    uploadImage(image) { let url = `${API_URL}/Image`; return this.http.post(url, image); }
}
ImageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImageService_Factory() { return new ImageService(i0.ɵɵinject(i1.HttpClient)); }, token: ImageService, providedIn: "root" });
