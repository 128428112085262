import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_URL = environment.apiUrl;
export class SecurityService {
    constructor(http) {
        this.http = http;
    }
    getValidity(ident) {
        let url = `${API_URL}/Security?securityKey=` + ident.securitykey + `&userid=` + ident.userid;
        return this.http.get(url);
    }
}
SecurityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SecurityService_Factory() { return new SecurityService(i0.ɵɵinject(i1.HttpClient)); }, token: SecurityService, providedIn: "root" });
