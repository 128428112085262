import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { User, identity } from '../_models/main.model';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable()
export class LoginService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ){}

  userLogout(ident: identity): Observable<HttpResponse<User>> {
    let url = `${API_URL}/Logout`;
    let httpHeaders = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post<User>(url, ident,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    );
  }

  userLogin(user: User): Observable<HttpResponse<User>>{
    let url = `${API_URL}/Login`;
    let httpHeaders = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post<User>(url, user,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    );
  }

  
}
