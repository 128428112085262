/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./getting-started.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ng-uikit-pro-standard/ng-uikit-pro-standard.ngfactory";
import * as i3 from "ng-uikit-pro-standard";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./getting-started.component";
var styles_GettingStartedComponent = [i0.styles];
var RenderType_GettingStartedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GettingStartedComponent, data: {} });
export { RenderType_GettingStartedComponent as RenderType_GettingStartedComponent };
function View_GettingStartedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mdb-icon", [["fas", ""], ["icon", "check"], ["style", "color: green;"]], null, null, null, i2.View_MdbIconComponent_0, i2.RenderType_MdbIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.MdbIconComponent, [i1.ElementRef, i1.Renderer2], { icon: [0, "icon"] }, null), i1.ɵdid(2, 16384, null, 0, i3.FasDirective, [i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { var currVal_0 = "check"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GettingStartedComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mdb-icon", [["fas", ""], ["icon", "times"], ["style", "color: red;"]], null, null, null, i2.View_MdbIconComponent_0, i2.RenderType_MdbIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.MdbIconComponent, [i1.ElementRef, i1.Renderer2], { icon: [0, "icon"] }, null), i1.ɵdid(2, 16384, null, 0, i3.FasDirective, [i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { var currVal_0 = "times"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GettingStartedComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mdb-icon", [["fas", ""], ["icon", "check"], ["style", "color: green;"]], null, null, null, i2.View_MdbIconComponent_0, i2.RenderType_MdbIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.MdbIconComponent, [i1.ElementRef, i1.Renderer2], { icon: [0, "icon"] }, null), i1.ɵdid(2, 16384, null, 0, i3.FasDirective, [i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { var currVal_0 = "check"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GettingStartedComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mdb-icon", [["fas", ""], ["icon", "times"], ["style", "color: red;"]], null, null, null, i2.View_MdbIconComponent_0, i2.RenderType_MdbIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.MdbIconComponent, [i1.ElementRef, i1.Renderer2], { icon: [0, "icon"] }, null), i1.ɵdid(2, 16384, null, 0, i3.FasDirective, [i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { var currVal_0 = "times"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GettingStartedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "row animated fadeIn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There are still a few things to do before you can start adding items to your collection"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 18, "ul", [["class", "animated fadeIn list-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "li", [["class", "list-group-item hrdrr-listgrp"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["style", "margin-right: 30px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add properties"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GettingStartedComponent_2)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GettingStartedComponent_3)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 8, "li", [["class", "list-group-item hrdrr-listgrp"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(17, 2), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["style", "margin-right: 30px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Configure a grid"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GettingStartedComponent_4)), i1.ɵdid(21, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GettingStartedComponent_5)), i1.ɵdid(23, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 8, 0, ("/admin/" + _co.category.identity.categoryname), "config"); _ck(_v, 7, 0, currVal_0); var currVal_1 = (_co.category.propertycount != 0); _ck(_v, 12, 0, currVal_1); var currVal_2 = (_co.category.propertycount == 0); _ck(_v, 14, 0, currVal_2); var currVal_3 = _ck(_v, 17, 0, ("/admin/" + _co.category.identity.categoryname), "config"); _ck(_v, 16, 0, currVal_3); var currVal_4 = (_co.category.gridcount != 0); _ck(_v, 21, 0, currVal_4); var currVal_5 = (_co.category.gridcount == 0); _ck(_v, 23, 0, currVal_5); }, null); }
function View_GettingStartedComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "animated bounce"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "hrdrr-nostart"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Oops, seems you haven't started yet! Click "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 2), (_l()(), i1.ɵted(-1, null, ["here"])), (_l()(), i1.ɵted(-1, null, [" to add your first item. "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 5, 0, ("/admin/" + _co.category.identity.categoryname), "new"); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_GettingStartedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GettingStartedComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GettingStartedComponent_6)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.category.objectcount === 0) && ((_co.category.propertycount === 0) || (_co.category.gridcount === 0))); _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.category.objectcount == 0) && (_co.category.propertycount != 0)) && (_co.category.gridcount != 0)); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GettingStartedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-getting-started", [], null, null, null, View_GettingStartedComponent_0, RenderType_GettingStartedComponent)), i1.ɵdid(1, 114688, null, 0, i6.GettingStartedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GettingStartedComponentNgFactory = i1.ɵccf("app-getting-started", i6.GettingStartedComponent, View_GettingStartedComponent_Host_0, { category: "category" }, {}, []);
export { GettingStartedComponentNgFactory as GettingStartedComponentNgFactory };
