import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_URL = environment.apiUrl;
export class FilterService {
    constructor(http) {
        this.http = http;
    }
    getFilterList(ident) {
        let url = `${API_URL}/Filter?userid=` + ident.userid + `&categoryid=` + ident.categoryid;
        return this.http.get(url);
    }
}
FilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FilterService_Factory() { return new FilterService(i0.ɵɵinject(i1.HttpClient)); }, token: FilterService, providedIn: "root" });
