import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { identity } from 'src/app/_models/main.model';
import { FilterService } from 'src/app/_services/filter.service';
import { FilterControlService } from 'src/app/_services/filter-control.service';
export class CategoryComponent {
    constructor(route, us, cs, router, mc, ts, os, fs, fcs, gs) {
        this.route = route;
        this.us = us;
        this.cs = cs;
        this.router = router;
        this.mc = mc;
        this.ts = ts;
        this.os = os;
        this.fs = fs;
        this.fcs = fcs;
        this.gs = gs;
        this.allShown = false;
        this.filters = undefined;
        this.form = [];
        this.showNumber = 25;
        this.isValid = false;
        this.loading = true;
        this.categoryexists = false;
        this.objectList = [];
        this.sortby = { values: [] };
        this.sorter = "";
        this.sortorder = "asc";
        this.urlTag = "";
        this.urlTagList = [];
        this.username = this.route.snapshot.paramMap.get('userName');
        this.categoryname = this.route.snapshot.paramMap.get('categoryName');
        this.mc.items = [
            { "route": "home", "name": "Home" },
            { "route": this.username, "name": this.username + "'s Collections" }
        ];
    }
    ngOnInit() {
        this.us.getUserExists(this.username, true).subscribe((resp) => {
            if (resp != 0) {
                this.isValid = true;
                let userid = resp;
                this.loadAllData(userid);
            }
            else {
                this.loading = false;
            }
        });
    }
    loadAllData(userid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("start");
            this.getCategoryList(userid);
            console.log("end");
        });
    }
    getCategoryList(userid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("start getCategoryList");
            this.cs.getCategories(userid).subscribe((cat) => {
                console.log("return value from getCategories");
                let currentCategoryId = 0;
                this.categoryList = cat;
                this.categoryList.forEach(catListItem => {
                    if (catListItem.identity.categoryname == this.categoryname) {
                        this.category = catListItem;
                        console.log(catListItem);
                        currentCategoryId = catListItem.identity.categoryid;
                        this.objectCount = catListItem.objectcount;
                        let ident = new identity();
                        ident.userid = userid;
                        ident.username = this.username;
                        ident.categoryid = currentCategoryId;
                        ident.categoryname = this.categoryname;
                        this.identity = ident;
                        this.categoryexists = true;
                        this.getGrid(ident);
                        if (catListItem.propertycount > 0 && (catListItem.objectcount > 0 || catListItem.wishlistcount > 0) && catListItem.gridcount > 0) {
                            this.os.getObjects(ident).subscribe(obj => {
                                if (this.objectCount < this.showNumber) {
                                    this.showNumber = this.objectCount;
                                    this.allShown = true;
                                }
                                console.log(obj);
                                this.objectList = obj;
                                this.fs.getFilterList(ident).subscribe(fltrs => {
                                    this.filters = fltrs;
                                    console.log(this.filters);
                                    this.form = this.fcs.toFormGroup(this.filters, this.urlTagList);
                                    this.wishListSwitch();
                                    this.loading = false;
                                });
                            });
                        }
                        else {
                            this.loading = false;
                        }
                    }
                });
            });
            console.log(this.categoryexists);
            console.log("stop getCategoryList");
        });
    }
    wishListSwitch() { if (this.form.value["wishlist"]) {
        this.objectCount = this.category.wishlistcount;
    }
    else {
        this.objectCount = this.category.objectcount;
    } }
    getGrid(ident) {
        this.gs.getGridList(ident).subscribe(grd => {
            this.gridList = grd;
            if (this.gridList[0].colActive[0]) {
                this.sorter = this.gridList[0].colActive[0].name;
            }
        });
    }
    showMore() {
        if (this.objectCount < this.showNumber + 25) {
            this.showNumber = this.objectCount;
            this.allShown = true;
        }
        else {
            this.showNumber = this.showNumber + 25;
        }
    }
    // sortby based on what's in the header
    sortBy(property) {
        if (this.sorter != property) {
            this.sortorder = "asc";
        }
        else {
            if (this.sortorder == "asc") {
                this.sortorder = "desc";
            }
            else {
                this.sortorder = "asc";
            }
        }
        this.sorter = property;
    }
    resetFilter() { this.form.reset({ wishlist: this.form.value['wishlist'] }); }
}
