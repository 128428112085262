import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_URL = environment.apiUrl;
export class GridService {
    constructor(http) {
        this.http = http;
    }
    getGridList(ident) {
        let url = `${API_URL}/Grid?userid=` + ident.userid + `&categoryid=` + ident.categoryid;
        return this.http.get(url);
    }
    saveGridList(gc) {
        let url = `${API_URL}/Grid`;
        return this.http.post(url, gc);
    }
}
GridService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GridService_Factory() { return new GridService(i0.ɵɵinject(i1.HttpClient)); }, token: GridService, providedIn: "root" });
