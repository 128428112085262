import { Component, Input } from '@angular/core';
import { grid, gridconfig, identity }     from '../../_models/main.model';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { GridService } from '../../_services/grid.service';
import { GridConfigPropComponent } from '../../_modals/grid-config-prop/grid-config-prop.component';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { DataService } from 'src/app/_services/data.service';

const tstoptions = { closeButton: true, positionClass: 'hrdrr-toast', toastClass: 'opacity' };

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent {
  modalRef: MDBModalRef;
  @Input() identity: identity;
  @Input() grid: grid;
  userName: string = localStorage.getItem('userName');
  securityKey: string = localStorage.getItem('securityKey');
  

  constructor(
    private ds: DataService,
    private gs: GridService,
    private modalService: MDBModalService
  ){}

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data,
        event.previousIndex,
        event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex, event.currentIndex);
    }
    this.saveGrid(this.grid);
  }

  openModal(grid: any, i: number) {
    this.modalRef = this.modalService.show(GridConfigPropComponent, { class: 'modal-lg', data: {grid, i}});
  }

  public saveGrid(grid: any){
    let gc = new gridconfig;
    gc.identity = this.identity;
    gc.grid = grid[0];
    this.gs.saveGridList(gc).subscribe(resp => {
      if(resp["status"] == "success"){
        this.ds.setCategoryGrid(grid);
      }
      let toast: any = {"status": resp["status"], "message": resp["message"]};
      this.ds.hasToastChanged(toast);
    });
  }
}
