/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./activate.component";
import * as i5 from "../login.service";
import * as i6 from "../../_services/user.service";
var styles_ActivateComponent = [i0.styles];
var RenderType_ActivateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivateComponent, data: {} });
export { RenderType_ActivateComponent as RenderType_ActivateComponent };
function View_ActivateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-lg-6 col-md-8 col-12 card animated fadeIn"], ["style", "padding: 2em;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Hi ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your account has been activated, go to the login page and start your collection!"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.usr.identity.username; _ck(_v, 2, 0, currVal_0); }); }
function View_ActivateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-lg-6 col-md-8 col-12 card animated fadeIn"], ["style", "padding: 2em;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Hi ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Something went wrong activating your user. Try again later or contact our servicedesk"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.usr.identity.username; _ck(_v, 2, 0, currVal_0); }); }
export function View_ActivateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "flex-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivateComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivateComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-12 hrdr-login-register"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 1), (_l()(), i1.ɵted(-1, null, ["Back to login page"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dataFetched && _co.userActivated); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.dataFetched && !_co.userActivated); _ck(_v, 6, 0, currVal_1); var currVal_4 = _ck(_v, 11, 0, "/login"); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 10).target; var currVal_3 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_2, currVal_3); }); }
export function View_ActivateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activate", [], null, null, null, View_ActivateComponent_0, RenderType_ActivateComponent)), i1.ɵdid(1, 114688, null, 0, i4.ActivateComponent, [i3.ActivatedRoute, i3.Router, i5.LoginService, i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivateComponentNgFactory = i1.ɵccf("app-activate", i4.ActivateComponent, View_ActivateComponent_Host_0, {}, {}, []);
export { ActivateComponentNgFactory as ActivateComponentNgFactory };
