import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { category, identity } from '../_models/main.model';
import { environment } from '../../environments/environment';
const API_URL = environment.apiUrl;
@Injectable({providedIn: 'root'})
export class CategoryService {
  constructor(private http: HttpClient) {}
  getCategories(userid: number){
    let url = `${API_URL}/Category/getAllPublic?userid=` + userid;
    return this.http.get(url);
  }
  getAllCategories(ident: identity){
    let url = `${API_URL}/Category?userid=` + ident.userid + `&securitykey=` + ident.securitykey;
    return this.http.get(url);
  }
  getCategory(ident: identity){let url = `${API_URL}/Category?userid=` + ident.userid + `&categoryid=` + ident.categoryid;return this.http.get(url);}
  saveCategory(cat: category){let url = `${API_URL}/Category`;return this.http.post(url,cat);}
  updateCategory(cat: category){let url = `${API_URL}/Category`;return this.http.put(url,cat);}
  deleteCategory(ident: identity){
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),body: ident
      };
      let url = `${API_URL}/Category`;
      return this.http.delete(url,options);
    }
}