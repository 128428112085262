<div class="row">
    <div class="col-12">
        <div class="flex-center">
            <div class="col-lg-6 col-md-8 col-12 card animated fadeIn" style="padding: 2em;">
                <div *ngIf="!emailWasSent">
                    <div class="hrdr-title" >Forgot password?</div>
                    <div class="row" style="padding: 1em;">
                      <div class="col-12">
                        <p>Please provide your email address so we can mail you a link to reset the password.</p>
                        <form [formGroup]="forgotPwdForm">
                          <div class="row">
                            <div class="md-form col-12" style="margin-top:5em;">
                                <input [class.is-invalid]="emailRegisteredMsg != '' || forgotPwdForm.get('email').invalid && forgotPwdForm.get('email').touched" style="margin-top: -20px" mdbInput [id]="'email'" [type]="'email'" [formControlName]="'email'" class="form-control" [email]="true">
                                <label for="femail" class="hrdr-form-label">Email</label>
                                <div style="min-height:20px;">
                                  <span *ngIf="forgotPwdForm.get('email').invalid && forgotPwdForm.get('email').touched" class="hrdr-form-warning">Please provide a valid email</span>
                                  <span *ngIf="emailRegisteredMsg != ''" class="hrdr-form-warning">{{emailRegisteredMsg}}</span>
                                </div>
                            </div>
                        </div>
                        </form>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12" style="text-align: right;">
                        <button type="button" mdbBtn class="relative waves-light hrdr-btn" mdbWavesEffect [disabled]="forgotLoading || emailRegisteredMsg != ''|| forgotPwdForm.value['email'] == null || forgotPwdForm.value['email'] == '' || (forgotPwdForm.get('email').invalid && forgotPwdForm.get('email').touched)" (click)="sendPwdMail();">Send email</button>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="emailWasSent">
                    <div class="hrdr-title" >Email sent!</div>
                    <div class="row" style="padding: 1em;">
                      <div class="col-12">
                        <p style="margin-top: 2em; font-size: 1.2em;">The password recovery email has been sent! Make sure to check your spam-folder.</p>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 hrdr-login-register">
      <a [routerLink]="['/login']">Back to login page</a>
    </div>
  </div>