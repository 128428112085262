import { Injectable, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';

import { NgxImageCompressService } from 'ngx-image-compress';

import { AdminRoutingModule } from './admin-routing.module';

import { DragDropDirective } from '../_directives/dragDrop.directive';

import { SharedModule } from '../_modules/shared.module';
import { PropertyPipe } from '../_pipes/property.pipe';
import { IconPipe } from '../_pipes/icon.pipe';
import { SortPipe } from '../_pipes/sort.pipe';
import { NumberPipe } from '../_pipes/number.pipe';
import { CollectionPipe } from '../_pipes/collection.pipe';

import { CategoryComponent } from './category/category.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormElementComponent } from '../_components/form-element/form-element.component';
import { GridComponent } from '../_components/grid/grid.component';
import { CategoryConfigComponent } from './category-config/category-config.component';
import { GridConfigPropComponent } from '../_modals/grid-config-prop/grid-config-prop.component';
import { PropertyGridConfigPropComponent } from '../_modals/property-grid-config-prop/property-grid-config-prop.component';
import { WarningComponent } from '../_modals/warning/warning.component';
import { ObjectComponent } from './object/object.component';
import { PropertyGridComponent } from '../_components/property-grid/property-grid.component';
import { GettingStartedComponent } from '../_components/getting-started/getting-started.component';
import { SettingsComponent } from './settings/settings.component';
import { ProfileComponent } from './profile/profile.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {overrides = <any> {'pinch': { enable: false },'rotate': { enable: false }}}

@NgModule({
  declarations: [
    CategoryComponent, 
    CategoryListComponent, 
    DashboardComponent,
    FormElementComponent,
    GridComponent,
    PropertyGridComponent,
    PropertyPipe,
    IconPipe,
    SortPipe,
    NumberPipe,
    CollectionPipe,
    CategoryConfigComponent,
    ObjectComponent,
    GettingStartedComponent,
    SettingsComponent,
    ProfileComponent,
    DragDropDirective,
    GridConfigPropComponent,
    PropertyGridConfigPropComponent
  ],
  imports: [
    AdminRoutingModule,
    BrowserAnimationsModule,
    DragDropModule,
    SharedModule,
  ],
  providers: [
    [CollectionPipe],
    [NgxImageCompressService],
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
  }
  ],
  exports: [ObjectComponent],
  entryComponents: [ PropertyGridConfigPropComponent, WarningComponent ]
})
export class AdminModule { }
