<mdb-side-nav class="fixed hrdr-sidenav" [hidden]="false" #sidenav [fixed]="false" >
    <div class="hrdrr-sidebar-content" (swipeleft)="sidenav.hide()">
        <div *ngIf="!filters" style="text-align: center;">
            <mdb-spinner spinnerColor="blue"></mdb-spinner>
        </div>
        <div *ngIf="filters && form && !loading" >
            <form [formGroup]="form" style="margin: 3em;">
            <div class="row fltrRow hrdr-fltr-title"><h1 class="hrdr-title hrdr-fltr-title">Filter</h1></div>
            <div *ngFor="let filter of filters[0].filter">
                <app-filter-element [filter]="filter" [form]="form" ></app-filter-element>
            </div>
            <button mdbBtn class="hrdr-btn" (click)="resetFilter()">Clear Filter</button>
            </form>
        </div>
    </div>
</mdb-side-nav>
<div *ngIf="userExists != '0'" (swiperight)="sidenav.show()">
    <div *ngIf="categoryExists">
        <div class=row>
            <div class="col-7">
                <div class="animated fadeIn hrdr-title" style="margin-bottom: 1em;">
                    {{identity.categoryname}} 
                    <span class="animated fadeIn hrdrr-fltrbtn" *ngIf="filters">
                        <a *ngIf="filters[0].filter.length != 0 && objectCount != 0" mdbTooltip="Filter" (click)="sidenav.toggle()" class="icons-sm">
                            <mdb-icon  class="hrdr-btn-anim" fas icon="filter"></mdb-icon>
                        </a>
                        &nbsp;
                        <a *ngIf="userInfo.ispublic && category.ispublic" mdbTooltip="Copy link" (click)="copyText()" >
                            <mdb-icon class="hrdr-btn-anim" fas icon="link"></mdb-icon>
                        </a>
                        &nbsp;
                        <a [routerLink]="['/admin', identity.categoryname, 'config']" mdbTooltip="Configuration" class="icons-sm">
                            <mdb-icon mdbTooltip="Configuration" class="hrdr-btn-anim" fas icon="cog"></mdb-icon>
                        </a>
                    </span>
                </div>
            </div>
            <div class="col-5">
                <div *ngIf="form && category">
                    <form [formGroup]="form">
                        <div class="switch" style="position: absolute; right: 18px; top:20px;">
                            <label>
                            <input type="checkbox" (change)="wishListSwitch()" [formControlName]="'wishlist'">
                            <span class="lever" style="margin-top: -2px;"></span> Wishlist
                            
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div *ngIf="loading" style="text-align: center;"><mdb-spinner spinnerColor="blue"></mdb-spinner></div>
        <div *ngIf="!loading">
            <app-getting-started [category]="category"></app-getting-started>
            <div class="animated fadeIn hrdrr-objlist" *ngIf="objectCount != 0 && gridCount != 0 && propertyCount != 0 && form && (objectList | filterPipe: form.value : filters[0].filter : sorter : sortorder ) as result">
                <div class="row">
                    <div class="col-6" style="text-align: left;">
                        <div style="position: absolute; bottom: 0.5em;">
                            <span style="font-weight: bold;" *ngIf="showNumber < result.length; else showResult">{{showNumber}}</span> of {{ result.length }} shown &nbsp;
                            <span *ngIf="objectCount > result.length"><a><mdb-badge pill="true" class="hrdr-fltr-pill" mdbTooltip="Click to remove filter" (click)="resetFilter()">filtered</mdb-badge></a></span>
                        </div>
                        <ng-template #showResult><span style="font-weight: bold;">{{result.length}}</span></ng-template>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        
                        <button mdbBtn class="hrdr-btn hrdrr-btn-right" [routerLink]="['/admin/' + identity.categoryname, 'new']">Add Item</button>
                    </div>
                </div>
                <div class="container-fluid clctrr-grid">
                    <div class="row clctrr-grid-header">
                        <div class="col col-{{grid.size}} col-lg-{{grid.lSize}}" *ngFor="let grid of gridList[0].colActive ; let i=index">
                        <span *ngIf="grid.header === true" (click)="sortBy(grid.name)" style="cursor: pointer;">
                            {{grid.name}} &nbsp; 
                            <span *ngIf="sorter == grid.name && sortorder == 'asc'"><mdb-icon fas icon="angle-up"></mdb-icon></span>
                            <span *ngIf="sorter == grid.name && sortorder == 'desc'"><mdb-icon fas icon="angle-down"></mdb-icon></span>
                        </span>
                        </div>
                    </div> 
                    <div class="row clctrr-grid-row" [ngClass]="{ odd: odd, even: even }" *ngFor="let obj of result | slice:0:showNumber; let y=index; let odd=odd; let even=even;" [routerLink]="['/admin/' + identity.categoryname, obj.ID]">
                        <div
                        class="col col-{{grid.size}} col-lg-{{grid.lSize}}"
                        *ngFor="let grid of gridList[0].colActive; let i=index"
                        [ngStyle]="{'font-weight': grid.bold === true ? 'bold' : '','font-size': grid.fontSize + 'em','text-align': grid.textAlign}">
                        <span *ngIf="grid.type === 'Image'" [ngClass]="{checkboxFalse: obj[grid.name] === 'False' || obj[grid.name] === 'false' || obj[grid.name] === ''}"><mdb-icon style="font-size:18px;" fas icon="{{grid.icon}}"></mdb-icon></span>
                        <span *ngIf="grid.type === 'Checkbox'" [ngClass]="{checkboxFalse: obj[grid.name] === 'False' || obj[grid.name] === 'false' || obj[grid.name] === ''}"><mdb-icon *ngIf="grid.icon != ''" style="font-size:18px;" fas icon="{{grid.icon}}"></mdb-icon><span *ngIf="grid.icon == ''">{{grid.name}}</span></span>
                        <span *ngIf="grid.type !== 'Checkbox' && grid.type !== 'Image'" >{{obj[grid.name]}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: left; padding-top: 0.5em;">
                        <div>
                            <span style="font-weight: bold;" *ngIf="showNumber < result.length; else showResult">{{showNumber}}</span> of {{ result.length }} shown &nbsp;
                            <span *ngIf="objectCount > result.length"><a><mdb-badge pill="true" class="hrdr-fltr-pill" mdbTooltip="Click to remove filter" (click)="resetFilter()">filtered</mdb-badge></a></span>
                        </div>
                        <ng-template #showResult><span style="font-weight: bold;">{{result.length}}</span></ng-template>
                    </div>
                </div>
                <div class="row" *ngIf="showNumber < result.length">
                    <div class="col-12" style="text-align: center;">
                        <button class="btn btn-blue-grey waves-light" (click)="showMore()" >Load more</button>	
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!categoryExists && !loading" class="animated fadeIn">
        <h1>Unknown Collection</h1>
        <p>Collection <strong>{{identity.categoryname}}</strong> does not exist for user {{identity.username}}.</p>
    </div>   
</div>
<div *ngIf="userExists == '0'">
    <div class="row hrdrr-breadcrumb animated fadeIn">
            <a [routerLink]="['']">Home</a>
    </div>
    <h1>Unknown user</h1>
    <p>Hmm... user doesn't seem to exist!</p>
</div>

    