<div class="row">
  <div class="col-12">
    <div class="flex-center">
      <div *ngIf="dataFetched && userActivated" class="col-lg-6 col-md-8 col-12 card animated fadeIn" style="padding: 2em;">
          <h4>Hi {{usr.identity.username}}</h4>
          <p>Your account has been activated, go to the login page and start your collection!</p>
      </div>
      <div *ngIf="dataFetched && !userActivated" class="col-lg-6 col-md-8 col-12 card animated fadeIn" style="padding: 2em;">
          <h4>Hi {{usr.identity.username}}</h4>
          <p>Something went wrong activating your user. Try again later or contact our servicedesk</p>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 hrdr-login-register">
    <a [routerLink]="['/login']">Back to login page</a>
  </div>
</div>