import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { identity, user } from '../_models/main.model';

const API_KEY = environment.apiKey;
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  verifyUser(ident: identity){
    let url = `${API_URL}/User?username=` + ident.username + `&ispublic=` + false;
    return this.http.get(url);
  }

  getUsers(){
    let url = `${API_URL}/User/getAll`;
    return this.http.get(url);
  }

  updateUser(usr: user){
    let url = `${API_URL}/User`;
    return this.http.put(url,usr);
  }
  
  registerUser(usr: user){
    let url = `${API_URL}/User/register`;
    return this.http.post(url,usr);
  }

  forgotPassword(email: string){
    let url = `${API_URL}/User/forgot?email=` + email;
    return this.http.get(url);
  }

  getUserExists(username: string, ispublic: boolean){
    let url = `${API_URL}/User?username=` + username + `&ispublic=` + ispublic;
    return this.http.get(url);
  }
  getEmailRegistered(email: string){
    let url = `${API_URL}/User/getEmail?email=` + email;
    return this.http.get(url);
  }

  getUserByGUID(guid: string, action: string){
    let url = `${API_URL}/User/getByGUID?guid=` + guid + `&action=` + action;
    return this.http.get(url);
  }

  resetPassword(usr: user){
    let url = `${API_URL}/User/reset`;
    return this.http.put(url,usr);
  }

  activateUser(id: number){
    let url = `${API_URL}/User/activate?id=` + id;
    return this.http.get(url);
  }



  sendEmail(usr: user, htmlBody: string, subject: string){
    let body = {
      "sender": {"name": "Hoarder", "email": "hoarder@planetsamzor.be"},
      "subject": subject,
      "to": [{"email": usr.email}],
      "htmlContent": htmlBody
    }
    console.log(body);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'api-key': API_KEY
      })
    };
    console.log(options);
    let url = 'https://api.sendinblue.com/v3/smtp/email';
    return this.http.post(url,body,options);
  }

  getUserInfo(ident: identity){
    let url = `${API_URL}/User?userid=` + ident.userid + `&securitykey=` + ident.securitykey;
    return this.http.get(url);
  }

}
