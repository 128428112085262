import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { LoginRoutingModule } from './login-routing.module';
import { LoginService } from './login.service';
import { ResetComponent } from './reset/reset.component';
import { ActivateComponent } from './activate/activate.component';
import { RegisterComponent } from './register/register.component';
import { ForgotComponent } from './forgot/forgot.component';
import { LoginSubComponent } from './login/login.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {overrides = <any> {'pinch': { enable: false },'rotate': { enable: false }}}

@NgModule({
  declarations: [
    ResetComponent,
    ActivateComponent,
    RegisterComponent,
    ForgotComponent,
    LoginSubComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot()
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
  },
  LoginService
  ],
  exports: [],
  entryComponents: []
})
export class LoginModule { }
