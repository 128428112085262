
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { MDBSpinningPreloader, MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';

import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { LoginRoutingModule } from './login/login-routing.module';
import { MainRoutingModule } from './main/main-routing.module';

import { AdminModule } from  './admin/admin.module';
import { LoginModule } from  './login/login.module';
import { MainModule } from './main/main.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';
import { BreadcrumbComponent } from './_components/breadcrumb/breadcrumb.component';
import { LogoutComponent } from './logout/logout.component';
import { WarningComponent } from './_modals/warning/warning.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoginService } from './login/login.service';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    MainComponent,
    LoginComponent,
    BreadcrumbComponent,
    LogoutComponent,
    WarningComponent
  ],
  imports: [
    AdminModule,
    LoginModule,
    MainModule,
    BrowserModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key'
    }),
    AppRoutingModule,
    AdminRoutingModule,
    LoginRoutingModule,
    MainRoutingModule,
    MDBBootstrapModulesPro.forRoot()
  ],
  exports: [MDBBootstrapModulesPro],
  providers: [CookieService,DeviceDetectorService, LoginService],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
        