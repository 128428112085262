<div *ngIf="isValid">
	<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark sticky-top hrdrr-navbar">
		<mdb-navbar-brand><a class="navbar-brand hrdrr-logo" href="/#/admin">Hoarder</a></mdb-navbar-brand>
		<div class="btn-group" mdbDropdown style="position: absolute; right: 2em;" *ngIf="!isValid">
			<a style="margin: 0;" mdbDropdownToggle mdbBtn floating="true" color="light" size="md" class="dropdown-toggle waves-light" type="button"
				mdbWavesEffect>
				<mdb-icon fas icon="user" size="2x"></mdb-icon>
				<div style="max-width: 47px; max-height: 47px; border-radius: 50%; border: 1px solid white;">TEST</div>
			</a>
			<div class="dropdown-menu dropdown-menu-right">
			<a class="dropdown-item" [routerLink]="['/login']">Login</a>
			</div>
		</div>
		<div class="btn-group" mdbDropdown style="position: absolute; right: 2em;" *ngIf="isValid">
			<a style="margin: 0;" mdbDropdownToggle mdbBtn floating="true" color="light" size="md" class="dropdown-toggle waves-light" type="button"
				mdbWavesEffect>
				<img *ngIf="profileImage != ''" [src]="profileImage" style="object-fit: cover; width: 47px; height: 47px; overflow: hidden;"
                        alt="Profile picture" class="rounded-circle img-fluid hrdr-profile-img">
				<mdb-icon *ngIf="profileImage == ''" fas icon="user" size="2x"></mdb-icon>
			</a>
			<div class="dropdown-menu dropdown-menu-right">
				<a class="dropdown-item" [routerLink]="['/admin/profile']">Profile</a>
				<a class="dropdown-item" [routerLink]="['/admin/settings']">Settings</a>
				<div class="divider dropdown-divider"></div>
				<a class="dropdown-item" [routerLink]="['/logout']">Logout</a>
			</div>
		</div>
	</mdb-navbar>

	<div class="container-fluid" style="padding-bottom: 20px;">
		<div class="row">
			<div class="col-md-1 col-sm-0"></div>
			<div class="col-md-10 col-sm-12">
				<app-breadcrumb [base]="'admin'" [items]="items"></app-breadcrumb>
				<router-outlet></router-outlet>
			</div>
			<div class="col-md-1 col-sm-0"></div>
		</div>
	</div>
</div>
<div class="hrdr-toast" [(ngStyle)]="toastStyle" (mouseover)="toastHover(true)" (mouseleave)="toastHover(false)">
	{{toastMessage}} <mdb-icon class="hrdr-toast-close" fas icon="times" (click)="closeToast()"></mdb-icon>
</div>