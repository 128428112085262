import { Component, OnInit, Input } from '@angular/core';
import { AdminComponent } from '../admin.component';
import { Router } from '@angular/router';
import { UserService } from '../../_services/user.service';
import { category, property, grid, propertyList, propertyGrid, identity } from '../../_models/main.model';
import { DataService } from '../../_services/data.service';
import { CategoryService } from '../../_services/category.service';
import { GridService } from '../../_services/grid.service';
import { PropertyGridService } from '../../_services/property-grid.service';
import { PropertyService } from '../../_services/property.service';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { FormControl, FormGroup } from '@angular/forms';
import {CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import {debounceTime} from 'rxjs/operators';
import { PropertyGridConfigPropComponent } from '../../_modals/property-grid-config-prop/property-grid-config-prop.component';
import * as iconlist from '../../../assets/iconList.json';

@Component({
  selector: 'app-category-config',
  templateUrl: './category-config.component.html',
  styleUrls: ['./category-config.component.scss']
})
export class CategoryConfigComponent implements OnInit {
  understood: boolean = false;
  identity: identity;
  results: string[];
  iconList: any = iconlist;
  // creating the form to edit the category
  categoryForm = new FormGroup ({
    categoryName: new FormControl(),
    categoryActive: new FormControl(),
    categoryPublic: new FormControl(),
    categoryIcon: new FormControl({value: ""}),
    useIcon: new FormControl()
  });
  // creating the modal form to add new property
  propertyForm = new FormGroup ({
    name: new FormControl(),
    fullname: new FormControl(),
    isactive: new FormControl(),
    type: new FormControl(),
    filter: new FormControl(),
    keep: new FormControl()
  });

  categoryMsg = "";
  @Input() propertyList: propertyList;
  props: property[];
  categoryName: string;
  categoryExists: number;
  grid: grid;
  propertyGrid: propertyGrid;
  userExists: string;
  category: category;
  currentProperty: property = new property;
  currentIndex: number;
  np: property;
  modalAction = "";
  propSaving = false;
  propLoading: boolean = true;
  propertyLength: number = 0;
  cCategoryGrid: any;
  modalRef: MDBModalRef;
  deleting: boolean = false;
  constructor(
    private ac: AdminComponent,
    private cs: CategoryService,
    private ds: DataService,
    private gs: GridService,
    private pgs: PropertyGridService,
    private ps: PropertyService,
    private us: UserService,
    private modalService: MDBModalService,
    private router: Router
  ) {
    this.identity = JSON.parse(localStorage.getItem('ident'));
    this.ac.validateUser(this.identity);
    //this.categoryName = this.route.snapshot.paramMap.get('categoryName');
    setTimeout(()=> {
      this.ac.items = [
        {"route": "dashboard","name":"Home"},
        {"route": "collections","name":"Your collections"},
        {"route": this.identity.categoryname, "name": this.identity.categoryname}
      ];
    }, 0);
    
  }

  ngOnInit() {
    this.verifyUser(this.identity);
    // check if the gridlist is already cached
    this.cCategoryGrid = this.ds.getCategoryGrid();
    if(this.cCategoryGrid != undefined){
      this.grid = this.cCategoryGrid;
    } else {
      this.getGrid(this.identity);
    }
    
    // check for changes from the modal
    this.ds.propertyObservable.subscribe(newData => {
      if(newData != null){
        this.getPropertyGrid(this.identity);
        this.getGrid(this.identity);
      }
    });

    this.getPropertyGrid(this.identity);
    this.categoryForm.valueChanges.pipe(debounceTime(300)).subscribe(catFormValues => {
      let catExists = false;
      let catList = this.ds.getCategoryData();
      let currentCategory = this.identity.categoryname;
      let currentCatListIndex;
      catList.forEach(
        function(catListItem){
          if(catListItem.identity.categoryname == catFormValues.categoryName){
            catExists = true;
          }
        }
      )
      if(catExists && catFormValues.categoryName != currentCategory){
        this.categoryMsg = "Category already exists. Please choose a different name";
      } else if(catFormValues.categoryName == "") {
        this.categoryMsg = "Category cannot be empty. Please fill in a value";
      } else {
        this.categoryMsg = "";
        this.category.identity = this.identity;
        this.category.identity.categoryname = catFormValues.categoryName;
        this.category.isactive = catFormValues.categoryActive;
        this.category.ispublic = catFormValues.categoryPublic;
        if(catFormValues.useIcon == true){
          this.category.icon = catFormValues.categoryIcon;
          this.results = this.filter(catFormValues.categoryIcon);
        } else {
          this.category.icon = "";
        }
        this.cs.updateCategory(this.category).subscribe(resp => {
            this.identity.categoryname = this.category.identity.categoryname;
            localStorage.setItem('ident',JSON.stringify(this.identity));
            this.ac.items = [
              {"route": "dashboard","name":"Home"},
              {"route": "collections","name":"Your collections"},
              {"route": this.identity.categoryname, "name": this.identity.categoryname}
            ];
            let currentCategoryId = this.identity.categoryid;
            catList.forEach(
              function(catListItem, index){
                if(catListItem.identity.categoryid == currentCategoryId){
                  currentCatListIndex = index;
                }
              }
            )
            catList[currentCatListIndex].identity.categoryname = this.identity.categoryname;
            catList[currentCatListIndex].ispublic = this.category.ispublic;
            catList[currentCatListIndex].isactive = this.category.isactive;
            catList[currentCatListIndex].icon = this.category.icon;
            this.ds.setCategoryData(catList);
            if(resp["status"] == "error"){
              console.log(resp);
              let toast: any = {"status": resp["status"], "message": resp["message"]};
              this.ds.hasToastChanged(toast);
            }
        });
      }
    });
  }

  openModal(propertyGrid: any, i: number, col: number, categoryName: string) {
    this.modalRef = this.modalService.show(PropertyGridConfigPropComponent, { class: 'modal-lg', data: {propertyGrid, i, col, categoryName}});
  }

  onDrop(event: CdkDragDrop<string[]>) {
    transferArrayItem(event.previousContainer.data,
    event.container.data,
    event.previousIndex, event.currentIndex);
    this.savePropertyOrder();
  }

  public savePropertyOrder(){
    this.ps.saveProperty(this.propertyList).subscribe(resp => {
      if(resp["status"] == "success"){
        this.getPropertyList(this.identity);
        this.propertyForm.reset();
      } else {
        console.log(resp);
        let toast: any = {"status": resp["status"], "message": resp["message"]};
        this.ds.hasToastChanged(toast);
      }
    });
  }

  // check if the user is valid
  private verifyUser(ident: identity){
    this.us.verifyUser(ident).subscribe(ue => {
      this.userExists = ue.toString();
      if(this.userExists != '0'){
        this.verifyCategory(ident);
      }
    })
  }

  public deleteCategory(){
    this.deleting = true;
    this.identity;
    this.cs.deleteCategory(this.identity).subscribe(resp => {
      if(resp["status"] == "success"){
        this.cs.getAllCategories(this.identity).subscribe((cat: category[]) => {this.ds.setCategoryData(cat); this.router.navigateByUrl('/admin/collections');})
        
      }
      let toast: any = {"status": resp["status"], "message": resp["message"]};
      this.ds.hasToastChanged(toast);
    });
  }

  public verifyCategory(ident: identity){
		this.cs.getCategory(ident).subscribe((singleCat: category) => {
      this.category = singleCat;
      let catUseIcon = false;
      if(this.category.icon != ""){
        catUseIcon = true;
        this.results = this.filter(this.category.icon);
      } else {
        this.results = this.filter("");
      }
      this.categoryForm.setValue({
        categoryName: this.category.identity.categoryname,
        categoryActive: this.category.isactive,
        categoryPublic: this.category.ispublic,
        categoryIcon: this.category.icon,
        useIcon: catUseIcon
      })
      this.categoryExists = singleCat.identity.categoryid;
      this.getPropertyList(ident);
		})
  }

  public getPropertyList(ident: identity){
    this.ps.getPropertyList(ident, false).subscribe((prop: propertyList) => {
      this.propertyList = prop;
      this.propertyList[0].securitykey = this.identity.securitykey;
      this.propLoading = false;
    })
  }

  public getGrid(ident: identity){
    this.gs.getGridList(ident).subscribe((grd:grid) => {
      this.grid = grd;
      this.ds.setCategoryGrid(grd);
    }) 
  }

  public getPropertyGrid(ident: identity){
    this.pgs.getPropertyGridList(ident, false).subscribe((propertygrd:propertyGrid) => {
      this.propertyGrid = propertygrd;
    }) 
  }

  public loadProperty(index: number){
    this.currentProperty = new property;
    if(index != -1){
      this.modalAction = "Update";
      this.currentIndex = index;
      this.currentProperty = this.propertyList[0].properties[index];
    } else {
      this.modalAction = "Save";
      this.currentProperty.id = 0;
      this.currentProperty.name="";
      this.currentProperty.fullname = "";
      this.currentProperty.type = "";
      this.currentProperty.isactive = false;
      this.currentProperty.filter = false;
      this.currentProperty.keep = false;
    }
    this.propertyForm.setValue({
      name: this.currentProperty.name, 
      fullname: this.currentProperty.fullname, 
      type: this.currentProperty.type, 
      isactive: this.currentProperty.isactive, 
      filter: this.currentProperty.filter,
      keep: this.currentProperty.keep
    });
  }

  public processProperty(property: property, id: number, currentIndex: number){
    this.propSaving = true;
    if(id == 0){
      this.propertyList[0].properties.push(this.propertyForm.value);
    } else {
      this.propertyList[0].properties[currentIndex] = this.propertyForm.value;
      this.propertyList[0].properties[currentIndex].id = id;
    }
    this.ps.saveProperty(this.propertyList).subscribe(resp => {
      if(resp["status"] == "success"){
        this.propSaving = false;
        this.getPropertyList(this.identity);
        if(id == 0){
          this.propertyForm.reset();
        }
        this.getGrid(this.identity);
      } else {
        console.log(resp);
        let toast: any = {"status": resp["status"], "message": resp["message"]};
        this.ds.hasToastChanged(toast);
      }
      
    });
  }
  onIconChange(value){this.results = this.filter(value);}
  filter(value: string): string[] {if(!value){return this.iconList.default;} else {return this.iconList.default.filter((item: string) => item.toLowerCase().includes(value.toLowerCase()));}}

}
