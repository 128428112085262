import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../_services/security.service';
import { DataService } from '../_services/data.service';
import { CategoryService } from '../_services/category.service';
import { Router } from '@angular/router';
import { category, identity, toast, user }     from '../_models/main.model';
import { PropertyService } from '../_services/property.service';
import { ProfileComponent } from './profile/profile.component';
import { UserService } from '../_services/user.service';
import { environment } from 'src/environments/environment';

const baseImgUrl = environment.imgUrl;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  usr: user;
  profileImage: string = "";
  isValid: boolean = false;
  userName: string = localStorage.getItem('userName');
  securityKey: string = localStorage.getItem('securityKey');
  identity: identity = this.ds.getIdentity();
  items: any = [];
  toastClosing = false;
  toastStyle = {}; 
  toastProperties: toast = {
    bgcolor : "#333",
    opacity: "0",
    visibility: "hidden"
  };
  toastMessage: string;
  constructor(
    private ss: SecurityService,
    private router: Router,
    private ds: DataService,
    private cs: CategoryService,
    private ps: PropertyService,
    private us: UserService
  ) { 
    if(this.identity == undefined){
      this.identity = JSON.parse(localStorage.getItem('ident'));
      console.log(this.identity);
      this.ds.setIdentity(this.identity);
    }
    this.validateUser(this.identity);
    this.getCategory(this.identity);
    this.getUserInfo(this.identity);
  }

  ngOnInit() {
    this.getPropertyTypeList();

    this.ds.profilePicObservable.subscribe(newData => {
      if(newData != null){
        this.getUserInfo(this.identity);
      }
    });
    this.ds.toastObservable.subscribe(newData => {
      if(newData != null){
        this.toastMessage = newData["message"];
        this.toastProperties.opacity = "0.8";
        this.toastProperties.visibility = "visible"
        if(newData["status"] == 'error'){
          this.toastProperties.bgcolor = "#670000";
        }
        this.setToastStyle(this.toastProperties);
        this.toastTimer();
      }
      
    })
  }

  public toastTimer(){
    var that = this;
    setTimeout(function(){that.closeToast();}, 5000);
  }

  public toastHover(state: boolean){
    if(state){
      if(!this.toastClosing){
        this.toastProperties.opacity = "1";
        this.toastProperties.visibility = "visible";
      }
    } else {
      if(!this.toastClosing){this.toastProperties.opacity = "0.8";}
    }
    this.setToastStyle(this.toastProperties);
    this.toastTimer();
  }

  closeToast(){
    this.toastClosing = true;
    this.toastProperties.opacity = "0";
    this.toastProperties.visibility = "hidden";
    this.setToastStyle(this.toastProperties);
    this.toastClosing = false;
  }

  setToastStyle(properties: toast){
    this.toastStyle = properties;
  }

  public getUserInfo(ident: identity){
    this.us.getUserInfo(ident).subscribe((usrInfo:user) => {
      this.ds.setUserInfo(usrInfo);
      if(usrInfo.base64 == "profile.jpg"){
        this.getBase64ImageFromUrl(baseImgUrl + ident.userid + '/','profile.jpg?' + (new Date()).getTime()).then(data => {
          this.getImageData(data.toString()).then(dim => {
            this.profileImage = data.toString(); 
          });
        });
      }
    });
  }




  getPropertyTypeList(){
    this.ps.getPropertyTypeList().subscribe(
      (ptl => {
        this.ds.setPropertyTypeList(ptl);
      })
    )
  }

  getCategory(ident: identity){
    console.log(ident);
      this.cs.getAllCategories(ident).subscribe(
        (cat: category[]) => {
          this.ds.setCategoryData(cat);
        }
      )
  }

  validateUser (ident: identity){
    if(ident != null){
      this.ss.getValidity(ident).subscribe(uv => {if(uv["status"] != "success"){this.router.navigateByUrl('/login');} else {this.isValid = true;}})
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  async getBase64ImageFromUrl(imageUrl, imgname) {
    let base64url = imageUrl + imgname;
    var res = await fetch(base64url);
    var blob = await res.blob();
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {resolve(reader.result);}, false);
      reader.onerror = () => {return reject(this);};
      reader.readAsDataURL(blob);
    })
  }

  public getImageData(result: string){
    let image = new Image();
    let imgHeight:number;
    let imgWidth: number;
    image.src = result;
    return new Promise(resolve => {
      image.onload = rs => {
        imgWidth=image.width;
        imgHeight=image.height;
        let dim: number[];
        dim = [imgWidth,imgHeight];
        resolve(dim);
      }
    })
  }
}
