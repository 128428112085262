export class propertyGridConfig{
  identity: identity;
  propertyGrid: propertyGrid[];
}

export class response{
  status: string;
  message: string;
  id: number;
}

export class imgData{
  id: number;
  action: string;
  name: string;
  width: number;
  height: number;
  base64: string;
  thumbnail: string;
}

export class img{
  identity: identity;
  images: imgData[];
}

export class propertyGrid {
  properties: propertyGridColumn[];
}

export class propertyGridColumn {
  id: number;
  name: string;
  type: string;
}

export class propertyColumn {
  properties: property[];
}

export class gridconfig{
  identity: identity;
  grid: grid;
}

export class grid {
  colActive: gridColumn[];
  colInactive: gridColumn[];
}

export class gridColumn {
  id: number;
  name: string;
  fullname: string;
  type: string;
  lSize: number;
  size: number;
  header: boolean;
  bold: boolean;
  fontSize: number;
  textAlign: string;
  icon: string;
}

export class category {
  identity: identity;
	isactive: boolean;
	ispublic: boolean;
  objectcount: number;
  wishlistcount: number;
  propertycount: number;
  gridcount: number;
  lastchanged: string;
  icon: string;
}

export class identity{
  userid: number;
  username: string;
  categoryid: number;
  categoryname: string;
  securitykey: string;
  id: number;
}

export class tagObject{
  identity: identity;
  tags: tag[];
}
export class tag{
  value:number;
  label: string;
}

export class objectDetail{
  identity: identity;
  wishlist: boolean;
  insertdate: string;
  tags: tag[];
  properties: objectDetailProperty[];
}

export class objectDetailProperty{
  label: string;
  type: string;
  value: string;
}

/*export class propertyDetail{
  id: number;
  name: string;
  type: string;
  valueString: string;
  valueBool: boolean;
  valueInt: number;
  detailPropertyValues: detailPropertyValue[];
}*/

/*export class detailPropertyValue{
  value: number;
  name: string;
  label: string;
  isdefault: boolean;
}*/

export class propertyList{
  identity: identity;
  properties: property[];
}

export class property{
  id: number;
  name: string;
  fullname: string;
  type: string;
  filter: boolean;
  isactive: boolean;
  keep: boolean;
  listorder: number;
  listcolumn: number;
  values: propertyValue[];
}

export class propertyValue{
  value: string;
  name: string;
  label: string;
  isactive: boolean;
  isdefault: boolean;
  propertyid: number;
}

export interface filterMain{
  filter: filterObject[];
  sortby: string;
}

export interface filterObject{
  name: string;
  fullname: string;
  type: string;
  value: string;
  sortby: string;
  values: filterOptions[];
}

export interface filterOptions{
  value: string;
  label: string;
}

export class user{
  identity: identity;
  email: string;
  passWord: string;
  active: boolean;
  validDate: string;
  ispublic: boolean;
  base64: string;
}

export interface User {
  userId: number;
  userName: string;
  email: string;
  passWord: string;
  active: boolean;
  securityKey: string;
  validDate: string;
  ispublic: boolean;
}

export interface response {
  status: string;
  message: string;
  id: number;
}

export interface toast{
  opacity: string;
  visibility: string;
  bgcolor: string;
}