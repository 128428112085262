import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserComponent } from './user/user.component';
import { CategoryComponent } from './category/category.component';
import { MainComponent } from './main.component';
import { HomeComponent } from './home/home.component';
import { ObjectComponent } from './object/object.component';

const routes: Routes = [
  {
		path: 'public', 
		component: MainComponent,
		children: [
			{ path: '', component: HomeComponent},
			{ path: 'home', component: HomeComponent},
			{ path: ':userName', component: UserComponent },
      		{ path: ':userName/:categoryName', component: CategoryComponent },
			{ path: ':userName/:categoryName/:objectId', component: ObjectComponent },
		]
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class MainRoutingModule { }
