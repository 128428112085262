import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { DataService} from '../../_services/data.service';
import { CategoryService} from '../../_services/category.service';
import { UserService} from '../../_services/user.service';
import { AdminComponent } from '../admin.component';
import { category, identity, response } from '../../_models/main.model';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { CollectionPipe } from '../../_pipes/collection.pipe';

const tstoptions = { closeButton: true, positionClass: 'hrdrr-toast', toastClass: 'opacity' };

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  showInactive: false;
  identity: identity = JSON.parse(localStorage.getItem('ident'));
  loading: boolean = true;
  userExists: string;
  categoryList: category[];
  colName: string;
  colActive: boolean;
  colPublic: boolean;
  cCategoryData: any;
  categoryMsg: string;
  categoryForm = new FormGroup ({
    categoryName: new FormControl(),
    categoryActive: new FormControl() ,
    categoryPublic: new FormControl(),
  });
  constructor(
    private ac: AdminComponent,
    private cs: CategoryService,
    private ds: DataService,
    private us: UserService,
    private router: Router,
    private pipe: CollectionPipe
  ) { 
    this.ac.validateUser(this.identity);
    setTimeout(()=> {
      this.ac.items = [{"route": "dashboard","name":"Home"}];
    }, 0);
  }
  ngOnInit() {
    this.categoryForm.setValue({
      categoryName: "",
      categoryActive: false,
      categoryPublic: false
    })
    this.verifyUser(this.identity);
    this.categoryForm.valueChanges.pipe(debounceTime(300)).subscribe(catFormValues => {
      let catExists = false;
      let catList = this.categoryList;
      catList.forEach(
        function(catListItem){
          if(catListItem.identity.categoryname == catFormValues.categoryName){
            catExists = true;
          }
        }
      )
      if(catExists){
        this.categoryMsg = "Category already exists. Please choose a different name";
      } else if(catFormValues.categoryName == "") {
        this.categoryMsg = "Category cannot be empty. Please fill in a value";
      } else {
        this.categoryMsg = "";
      }
    });
  }

  public goToCategory(id: number, name: string){
    this.identity.categoryname = name;
    this.identity.categoryid = id;
    localStorage.setItem('ident',JSON.stringify(this.identity));
    this.router.navigate(['/admin',this.identity.categoryname])

  }

  // clear the form when exiting the modal
  public clearCollection(){
    this.categoryForm.reset();
  }

  // save the newly created
  public saveCategory(categoryForm){
    let saveCat = new category();
    let ident: identity = new identity();
    ident = Object.assign({},this.identity);;
    ident.categoryname = categoryForm.categoryName;
    ident.categoryid = 0;
    saveCat.identity = ident;
    saveCat.isactive = categoryForm.categoryActive;
    saveCat.ispublic = categoryForm.categoryPublic;
    saveCat.objectcount = 0;

    this.cs.saveCategory(saveCat).subscribe((resp: response) => {
      if(resp.status == "success"){
        saveCat.identity.id = 0;
        saveCat.identity.categoryid = resp.id
        this.categoryList.push(saveCat);
        this.getCategoryData(this.identity);
        this.categoryForm.reset();
        this.ds.setCategoryData(this.categoryList);
        this.pipe.transform(this.categoryList,this.showInactive);
      }
      let toast: any = {"status": resp["status"], "message": resp["message"]};
      this.ds.hasToastChanged(toast);
    });
  }

  // verify if the user is still logged in
  private verifyUser(ident: identity){
    this.us.verifyUser(ident).subscribe(ue => {
      this.userExists = ue.toString();
      if(this.userExists != '0'){
        this.getCategoryList(ident);
      }
    })
  }

  // check if we have the data in the cache, if not, we need to fetch it from the service
  private getCategoryList(ident: identity){
    this.cCategoryData = this.ds.getCategoryData();
    console.log(this.cCategoryData);
    if(this.cCategoryData != undefined){
      this.categoryList = this.cCategoryData;
      this.loading = false;
    } else {
      this.getCategoryData(ident);
    }
  }

  // in case the data is not yet stored in the cache, we need to fetch it anyway
  getCategoryData(ident: identity){
    this.cs.getAllCategories(ident).subscribe(
      (cat: category[]) => {
        this.ds.setCategoryData(cat);
        this.categoryList = cat;
        console.log(this.categoryList);
        this.loading = false;
      }
    )
  }
}
