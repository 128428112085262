import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { identity, propertyList, propertyValue } from '../_models/main.model';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(private http: HttpClient
  ) { }

  getPropertyList(ident: identity, isactive: Boolean){let url = `${API_URL}/Property?userid=` + ident.userid + `&categoryid=` + ident.categoryid + `&isactive=` + isactive;	return this.http.get<propertyList>(url);}
  getPropertyTypeList(){let url = `${API_URL}/PropertyType`;return this.http.get(url);}
  getPropertyValueList(propertyid: number){
    let url = `${API_URL}/PropertyValues?propertyid=` + propertyid;
    return this.http.get(url);
  }
  saveProperty(prop: propertyList){let url = `${API_URL}/Property`;return this.http.post(url,prop);}
  updateProperty(prop: propertyList){let url = `${API_URL}/Property`;return this.http.put(url,prop);}
  deleteProperty(prop: propertyList){const options={headers:new HttpHeaders({'Content-Type':'application/json'}),body:prop};let url=`${API_URL}/Property`;return this.http.delete(url,options);}
  
  // single property value
  // save a single property value
  savePropertyValue(propVal: propertyValue){let url = `${API_URL}/PropertyValues`;return this.http.post(url,propVal);}
  // update a single property value
  updatePropertyValue(propVal: propertyValue){let url = `${API_URL}/PropertyValues`;return this.http.put(url,propVal);}
  // delete a single property value
  deletePropertyValue(propVal: propertyValue){const options = {headers: new HttpHeaders({'Content-Type': 'application/json',}),
  body: propVal};let url = `${API_URL}/PropertyValues`;return this.http.delete(url, options);}
}
  
