<p>Drag and drop the elements to create your own grid view.</p>
<div class="row list-container">
    <div class="col-lg-6 col-12 todo-container column-container">
        <div class="list"
             cdkDropList
             #todoList="cdkDropList"
             [cdkDropListData]="grid[0].colActive"
             [cdkDropListConnectedTo]="[doneList]"
             (cdkDropListDropped)="onDrop($event)">
          <p style="margin: 10px 0 5px 0; font-weight: bold;">Visible elements</p>
          <ul class="list-group">
            <li *ngFor="let cActive of grid[0].colActive; let i=index" cdkDrag class="list-group-item" style="padding: 0px;">
                <div class="row">
                    <div cdkDragHandle class="col-2" style="border-right: 1px solid #EEE">
                        <span style="float: left; padding: 10px 0 10px 15px;">
                            <mdb-icon class="hrdrr-grid-dragger" fas icon="grip-lines"></mdb-icon>
                        </span>
                    </div>
                    <div class="col-8" style="padding-top: 10px; font-weight: bold;"><strong>{{cActive.name}}</strong></div>
                    <div class="col-2" style="padding-top: 10px; text-align: center;"><mdb-icon style="cursor: pointer; padding: 8px; margin-top: -6px;" fas icon="cog" (click)="openModal(grid, i)"></mdb-icon></div>
                </div>
            </li>
          </ul>

        </div>
      </div>
      <div class="col-lg-6 col-12 done-container column-container">
        <div class="list"
             cdkDropList
             #doneList="cdkDropList"
             [cdkDropListData]="grid[0].colInactive"
             [cdkDropListConnectedTo]="todoList"
             (cdkDropListDropped)="onDrop($event)">
          <p style="margin: 10px 0 5px 0; font-weight: bold;">Hidden elements</p>
          <ul class="list-group">
                <li *ngFor="let cInActive of grid[0].colInactive" cdkDrag class="list-group-item" style="padding: 0px;">
                        <div class="row">
                            <div cdkDragHandle class="col-2" style="border-right: 1px solid #EEE">
                                <span style="float: left; padding: 10px 0 10px 15px;">
                                    <mdb-icon class="hrdrr-grid-dragger" fas icon="grip-lines"></mdb-icon>
                                </span>
                            </div>
                            <div class="col-8" style="padding-top: 10px; font-weight: bold; color: #AAA;"><strong>{{cInActive.name}}</strong></div>
                            <div class="col-2" style="padding-top: 10px; text-align: center;"></div>
                        </div>
                    </li>
          </ul>
        </div>
      </div>
  </div>
