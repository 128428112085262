import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { PropertyGridService } from '../../_services/property-grid.service';
import { gridColumn, identity, property, propertyGrid, propertyGridConfig, propertyList, propertyValue } from '../../_models/main.model';
import { ToastService} from 'ng-uikit-pro-standard';
import { FormControl, FormGroup } from '@angular/forms';
import { PropertyService } from 'src/app/_services/property.service';
import { debounceTime } from 'rxjs/operators';
import { DataService } from 'src/app/_services/data.service';

const tstoptions = { closeButton: true, positionClass: 'hrdrr-toast', toastClass: 'opacity' };

@Component({
  selector: 'app-property-grid-config-prop',
  templateUrl: './property-grid-config-prop.component.html',
  styleUrls: ['./property-grid-config-prop.component.scss']
})
export class PropertyGridConfigPropComponent implements OnInit{
  disableType: boolean = false;
  identity: identity;
  i: number;
  col: number;
  categoryName: string;
  showCol: string;
  userName: string = localStorage.getItem('userName');
  securityKey: string = localStorage.getItem('securityKey');
  propertyGrid: any;
  action: string = "Add";
  addPropVal: Boolean = false;
  currentProperty: any;
  propertyTypeList: any;
  propertyValueList: any;
  saving: boolean = true;
  pvAction: string = "Add";
  pvNameMsg = "";
  pvLabelMsg = "";
  pNameMsg = "";
  pLabelMsg = "";
  pTypeMsg = "";
  understood: boolean;
  understoodp:boolean;
  propertyValForm = new FormGroup ({
    value: new FormControl(),
    name: new FormControl(),
    label: new FormControl(),
    isactive: new FormControl(),
    isdefault: new FormControl()
  })
  propertyForm = new FormGroup ({
    id: new FormControl(),
    name: new FormControl(),
    fullname: new FormControl(),
    isactive: new FormControl(),
    type: new FormControl(),
    filter: new FormControl(),
    keep: new FormControl()
  });
  constructor(
    public modalRef: MDBModalRef,
    private pgs: PropertyGridService,
    private ts: ToastService,
    private ps: PropertyService,
    private ds: DataService
  ) {
    this.identity = JSON.parse(localStorage.getItem('ident'));
  }
  
  ngOnInit(){
    this.propertyTypeList = Object.assign([], this.ds.getPropertyTypeList());
    if(this.i != -1 && this.propertyGrid != undefined){
      this.action = "Edit";
      this.currentProperty = this.propertyGrid[this.col].properties[this.i];
      if((this.propertyGrid[0].properties.find(x => x.type === "Image") || this.propertyGrid[1].properties.find(x => x.type === "Image")) && this.currentProperty.type != "Image"){
        let imgIndex = this.propertyTypeList.findIndex(i => i.value === "Image");
        this.propertyTypeList.splice(imgIndex,1);
      }
      if((this.propertyGrid[0].properties.find(x => x.type === "Tagfield") || this.propertyGrid[1].properties.find(x => x.type === "Tagfield")) && this.currentProperty.type != "Tagfield"){
        let tagIndex = this.propertyTypeList.findIndex(i => i.value === "Tagfield");
        this.propertyTypeList.splice(tagIndex,1);
      }
      this.propertyForm.patchValue({
        id: this.currentProperty.id,
        name: this.currentProperty.name,
        fullname: this.currentProperty.fullname,
        isactive: this.currentProperty.isactive,
        type: this.currentProperty.type,
        filter: this.currentProperty.filter,
        keep: this.currentProperty.keep
      });
      if(this.currentProperty.count > 0){
        this.disableType = true;
        this.propertyForm.controls['type'].disable();
      }
      this.getPropertyValuesList(this.currentProperty.id);
    } else {
      if(this.propertyGrid[0].properties.find(x => x.type === "Image") || this.propertyGrid[1].properties.find(x => x.type === "Image")){
        let imgIndex = this.propertyTypeList.findIndex(i => i.value === "Image");
        this.propertyTypeList.splice(imgIndex,1);
      }
      if(this.propertyGrid[0].properties.find(x => x.type === "Tagfield") || this.propertyGrid[1].properties.find(x => x.type === "Tagfield")){
        let tagIndex = this.propertyTypeList.findIndex(i => i.value === "Tagfield");
        this.propertyTypeList.splice(tagIndex,1);
      }
      this.saving = false;
    }
    
    // check if the property already exists
    this.propertyForm.valueChanges.pipe(debounceTime(300)).subscribe(pValues => {
      let pNameExists = false;
      let pLabelExists = false;
      let pNameEmpty = false;
      let pLabelEmpty = false;
      let pTypeEmpty = false;
      let pList = this.propertyGrid;
      pList.forEach(
        // loop through the columns
        function(pCol){
          // loop through the properties
          pCol.properties.forEach(
            function(p){
                if(pValues.name == null){pNameEmpty = false;} else if(pValues.name == ""){pNameEmpty = true;} else if (p.name.toUpperCase() == pValues.name.toUpperCase() && p.id != pValues.id){pNameExists = true;}
                if(pValues.fullname == null){pLabelEmpty = false;} else if(pValues.fullname == ""){pLabelEmpty = true;} else if (p.fullname.toUpperCase() == pValues.fullname.toUpperCase() && p.id != pValues.id){pLabelExists = true;}
                if(pValues.type == "" ){pTypeEmpty = true;}
            }
          )
        }
      )
      if(pNameExists){this.pNameMsg = "Name already exists";} else if(pNameEmpty){this.pNameMsg = "Name cannot be empty"} else {this.pNameMsg = "";}
      if(pLabelExists){this.pLabelMsg = "Full name already exists";} else if(pLabelEmpty){this.pLabelMsg = "Full name cannot be empty"} else {this.pLabelMsg = "";}
      if(pTypeEmpty){this.pTypeMsg = "Please select a type";} else {this.pTypeMsg = "";}
    })

    // Check if the propertyvalue already exists
    this.propertyValForm.valueChanges.pipe(debounceTime(300)).subscribe(pvValues => {
      let pvNameExists = false;
      let pvLabelExists = false;
      let pvNameEmpty = false;
      let pvLabelEmpty = false;
      let pvList = this.propertyValueList;
      pvList.forEach(
        function(pv){
          if(pvValues.name == null){pvNameEmpty = false;} else if(pvValues.name == "" ){pvNameEmpty = true;} else if(pv.name.toUpperCase() == pvValues.name.toUpperCase() && pv.value != pvValues.value){pvNameExists = true;}
          if(pvValues.label == null){pvLabelEmpty = false;} else if(pvValues.label == "" ){pvLabelEmpty = true;} else if(pv.label.toUpperCase() == pvValues.label.toUpperCase() && pv.value != pvValues.value){pvLabelExists = true;}
        }
      )
      if(pvNameExists){this.pvNameMsg = "Name already exists";} else if(pvNameEmpty){this.pvNameMsg = "Name cannot be empty"} else {this.pvNameMsg = "";}
      if(pvLabelExists){this.pvLabelMsg = "Full name already exists";} else if(pvLabelEmpty){this.pvLabelMsg = "Full name cannot be empty"} else {this.pvLabelMsg = "";}
    });
  }

  closeModal(action: string) {
    if(action == "Add"){
      this.saveProp(this.propertyForm.value);
    } else if(action == "Edit"){
      this.updateProp(this.propertyForm.value);
    }
    this.modalRef.hide();
  }

  public getPropertyValuesList(propertyid: number){
    this.ps.getPropertyValueList(propertyid).subscribe(propertyValueList => {
      this.propertyValueList = propertyValueList;
      this.propertyValForm.reset();
      this.pvAction = "Add";
      this.saving = false;
    })
  }

  public setPropertyValue(pv: propertyValue){
    this.pvAction = "Update";
    this.propertyValForm.patchValue({
      value: pv.value,
      name: pv.name,
      label: pv.label,
      isactive: pv.isactive,
      isdefault: pv.isdefault
    });
  }

  addPropVals(){
    this.addPropVal = true;
    this.action = "Edit options of " + this.currentProperty.fullname;
  }

  savePropval(propval: propertyValue){
    console.log("inside savepropval");
    this.saving = true;
    propval.propertyid = this.currentProperty.id;
    if(propval.isactive == null){propval.isactive = false;}
    if(propval.isdefault == null){propval.isdefault = false;}
    if(this.pvAction == "Add"){
      this.ps.savePropertyValue(propval).subscribe(resp => {
        if(resp["status"] == "success"){
          propval.value = resp["id"];
          this.getPropertyValuesList(this.currentProperty.id);
          
        } else {
          this.ts.error( resp["message"],'', tstoptions);
        }
      });
    } else if (this.pvAction == "Update"){
      this.ps.updatePropertyValue(propval).subscribe(resp => {
        if(resp["status"] == "success"){
          this.getPropertyValuesList(this.currentProperty.id);
          
        } else {
          this.ts.error( resp["message"],'', tstoptions);
        }
      });
    }
    
  }

  deletePropval(propval: propertyValue){
    console.log("inside deltePropval");
    this.ps.deletePropertyValue(propval).subscribe(resp => {
      if(resp["status"] == "success"){
        this.getPropertyValuesList(this.currentProperty.id);
      } else {
        this.ts.error( resp["message"],'', tstoptions);
      }
    });
  }

  updateProp(propertyForm: property){
    this.ps.updateProperty(this.createPropList(propertyForm)).subscribe(resp => {
      if(resp["status"] == "success"){
        this.ds.hasPropertyChanged("update");
      } else {
        this.ts.error( resp["message"],'', tstoptions);
      }
    })
  }

  saveProp(propertyForm: property){
    this.ps.saveProperty(this.createPropList(propertyForm)).subscribe(resp => {
      if(resp["status"] == "success"){
        this.ds.hasPropertyChanged("insert");
      } else {
        this.ts.error( resp["message"],'', tstoptions);
      }
    })
  }

  deleteProp(propertyForm: property){
    this.ps.deleteProperty(this.createPropList(propertyForm)).subscribe(resp => {
      if(resp["status"] == "success"){
        this.ds.hasPropertyChanged("delete");
      } else {
        this.ts.error( resp["message"],'', tstoptions);
      }
    })
  }

  // create the propertyList object
  createPropList(propertyForm: property){
    propertyForm.listcolumn = this.col;
    propertyForm.listorder = this.i;
    let propList: propertyList = new propertyList;
    propList.identity = this.identity;
    propList.properties = [];
    propList.properties.push(propertyForm);
    return propList;
  }

  resetPropVal(){
    this.pvAction = "Add";
    this.propertyValForm.reset();
  }
}
