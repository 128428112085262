import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_URL = environment.apiUrl;
export class CategoryService {
    constructor(http) {
        this.http = http;
    }
    getCategories(userid) {
        let url = `${API_URL}/Category/getAllPublic?userid=` + userid;
        return this.http.get(url);
    }
    getAllCategories(ident) {
        let url = `${API_URL}/Category?userid=` + ident.userid + `&securitykey=` + ident.securitykey;
        return this.http.get(url);
    }
    getCategory(ident) { let url = `${API_URL}/Category?userid=` + ident.userid + `&categoryid=` + ident.categoryid; return this.http.get(url); }
    saveCategory(cat) { let url = `${API_URL}/Category`; return this.http.post(url, cat); }
    updateCategory(cat) { let url = `${API_URL}/Category`; return this.http.put(url, cat); }
    deleteCategory(ident) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }), body: ident
        };
        let url = `${API_URL}/Category`;
        return this.http.delete(url, options);
    }
}
CategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoryService_Factory() { return new CategoryService(i0.ɵɵinject(i1.HttpClient)); }, token: CategoryService, providedIn: "root" });
