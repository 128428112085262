import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseImgUrl = environment.imgUrl;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  isValid: boolean = false;
  profileImage: string = "";
  items: any = [];
  constructor(
  ) {}
  ngOnInit() {}

}
