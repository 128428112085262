import { NgModule } from '@angular/core';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CategoryComponent } from './category/category.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryConfigComponent } from './category-config/category-config.component';
import { SettingsComponent} from './settings/settings.component';
import { ProfileComponent } from './profile/profile.component';
import { ObjectComponent } from './object/object.component';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
	{
		path: 'admin', 
		component: AdminComponent,
		children: [
			{path: '', component: DashboardComponent},
			{path: 'dashboard', component: DashboardComponent},
			{path: 'collections', component: CategoryListComponent},
			{path: 'settings', component: SettingsComponent},
			{path: 'profile', component: ProfileComponent},
			{path: ':categoryName', component: CategoryComponent},
			{path: ':categoryName/config', component: CategoryConfigComponent},
			{path: ':categoryName/:objectId', component: ObjectComponent}
		]
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
