<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">Warning</h4>
</div>
<div class="modal-body">
    {{imgWarning}}
</div>
<div class="modal-footer" >
    <button type="button" class="hrdr-btn relative waves-light" mdbBtn (click)="modalRef.hide()">Ok</button>
</div>