import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { propertyColumn } from '../_models/main.model';
@Injectable()
export class FormControlService {
  constructor(){}
  toFormGroup(props: propertyColumn[] , object: any) {
    // for each property, create a FormControl
    let group: any = {};
    props.forEach(col => {
      col.properties.forEach(property => {
        if(property.isactive){
          group[property.id] = new FormControl();
          group[property.id].value = object.properties.find(x => x.label === property.id).value;
        }
      })
    });
    // combine it into a FormGroup
    return new FormGroup(group);
  }
}