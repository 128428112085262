<p>Drag and drop the elements to create your own detailpage.</p>
<div class="row list-container">
    <div class="col-lg-6 col-12 todo-container column-container">
        <div class="list"
             cdkDropList
             #colLeft="cdkDropList"
             [cdkDropListData]="propertyGrid[0].properties"
             [cdkDropListConnectedTo]="[colRight]"
             (cdkDropListDropped)="onDrop($event)">
          <p style="margin: 10px 0 5px 0; font-weight: bold;">Left Column</p>
          <ul class="list-group">
            <li *ngFor="let cl of propertyGrid[0].properties; let i=index" cdkDrag class="list-group-item" style="padding: 0px;">
                <div class="row">
                    <div cdkDragHandle class="col-2" style="border-right: 1px solid #EEE">
                        <span style="float: left; padding: 10px 0 10px 15px;">
                            <mdb-icon class="hrdrr-grid-dragger" fas icon="grip-lines"></mdb-icon>
                        </span>
                    </div>
                    <div class="col-8" style="padding-top: 10px; font-weight: bold;"><strong>{{cl.name}}</strong></div>
                    <div class="col-2" style="padding-top: 10px; text-align: center;"><mdb-icon style="cursor: pointer; padding: 8px; margin-top: -6px;" fas icon="cog" (click)="openModal(propertyGrid, i, 0, identity.categoryname)"></mdb-icon></div>
                </div>
            </li>
          </ul>

        </div>
      </div>
      <div class="col-lg-6 col-12 todo-container column-container">
        <div class="list"
             cdkDropList
             #colRight="cdkDropList"
             [cdkDropListData]="propertyGrid[1].properties"
             [cdkDropListConnectedTo]="[colLeft]"
             (cdkDropListDropped)="onDrop($event)">
          <p style="margin: 10px 0 5px 0; font-weight: bold;">Right Column</p>
          <ul class="list-group">
            <li *ngFor="let cr of propertyGrid[1].properties; let y=index" cdkDrag class="list-group-item" style="padding: 0px;" >
              <div class="row">
                <div cdkDragHandle class="col-2" style="border-right: 1px solid #EEE">
                  <span style="float: left; padding: 10px 0 10px 15px;">
                    <mdb-icon class="hrdrr-grid-dragger" fas icon="grip-lines"></mdb-icon>
                  </span>
                </div>
                <div class="col-8" style="padding-top: 10px; font-weight: bold;">
                  <strong>{{cr.name}}</strong>&nbsp;
                  <span *ngIf="!cr.isactive">
                    <mdb-badge pill="true" color="grey">
                        inactive
                    </mdb-badge>
                </span>
                </div>
                <div class="col-2" style="padding-top: 10px; text-align: center;"><mdb-icon style="cursor: pointer; padding: 8px; margin-top: -6px;" fas icon="cog" (click)="openModal(propertyGrid, y, 1, identity.categoryname)"></mdb-icon></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
  </div>