import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[dragDrop]'
})
export class DragDropDirective {
    @Output() onFileDropped = new EventEmitter<any>();
    @HostBinding('style.background-color') background = '#F6F6F6';
    @HostBinding('style.opacity') opacity = '0';
    @HostBinding('style.z-index') zindex = '0';
    //Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.zindex = '100';
        this.background = '#dddddd';
        this.opacity = '0.7'
    }
        
    //Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.zindex = '0';
        this.opacity = '0';
        this.background = '#F6F6F6';
    }
        
    //Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#F6F6F6'
        this.opacity = '0'
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.opacity = '0'
        this.onFileDropped.emit(files)
        }
    }
}