import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'collectionPipe'})
export class CollectionPipe implements PipeTransform{
	transform(items: any, inactive: boolean): any {
		let results: any;
		if(inactive == true){
			results = items;
		} else {
			results = items.filter(it => {return it["isactive"] == true;});
		}
		return results.sort((a,b) => a["identity"]["categoryname"].toString().localeCompare(b["identity"]["categoryname"].toString()));
	}
}
