<div *ngIf="userExists">
    <div *ngIf="userExists != '0'">
        <div *ngIf="category != undefined && category.identity.categoryid  != 0">
            <div *ngIf="!deleting">
                <div class="row">
                    <div class="col-6">           
                        <h1 class="animated fadeIn hrdr-title" style="margin-bottom: 1em;">
                            Edit item
                        </h1>
                    </div>
                    <div class="col-6">
                        <button mdbBtn class="hrdr-btn hrdrr-btn-right" (click)="saveObject()" [disabled]="saving">Save</button>
                        <form [formGroup]="wishForm">
                            <mdb-checkbox *ngIf="!objLoading" class="animated fadeIn hrdr-wish-slider" [formControlName]="'wishlist'">{{lblWishList}}</mdb-checkbox>
                        </form>
                        <span *ngIf="saving"><mdb-spinner spinnerColor="blue" style="float: right;"></mdb-spinner></span>
                    </div>
                </div>
                <div *ngIf="propLoading" style="text-align: center;"><mdb-spinner spinnerColor="blue"></mdb-spinner></div>
                <div *ngIf="!propLoading && !objLoading"  class="animated fadeIn">
                    <form [formGroup]="form">
                        <div class="row">
                            <div *ngFor="let cols of propertyGrid" class="col-lg-6 col-12">
                                <div *ngFor="let prop of cols.properties; let i=index">
                                    <div class="row" *ngIf="prop.isactive" [ngSwitch]="prop.type">
                                        <div class="col-12">
                                            <div class="row" *ngSwitchCase="'Text'">
                                                <div class="md-form col-12 hrdr-obj-txt" >
                                                    <input style="margin: -20px 0px -20px 0px;" mdbInput [id]="prop.id" [type]="prop.type" [formControlName]="prop.id" class="form-control">
                                                    <label for="prop.id" class="hrdr-form-label">{{prop.name | titlecase}}</label>
                                                </div>
                                            </div>
                                            <div class="row" *ngSwitchCase="'Timestamp'" >
                                                <div class="md-form col-12" style="margin-top: 5px; margin-bottom: 5px;">
                                                    <mdb-date-picker style="margin-top: -50px;" [formControlName]="prop.id" [id]="prop.id" [placeholder]="prop.name | titlecase"></mdb-date-picker>
                                                </div>
                                            </div>
                                            <div class="row" *ngSwitchCase="'Number'">
                                                <div class="md-form col-12" >
                                                    <input mdbInput [id]="prop.id" [type]="prop.type" [formControlName]="prop.id" class="form-control">
                                                    <label for="prop.id" style="left: 15px; color: #a4a4a4;">{{prop.name | titlecase}}</label>
                                                </div>
                                            </div>
                                            <div class="row" *ngSwitchCase="'Rating'">
                                                <div class="md-form col-12" >
                                                    <span style="left: 15px; color: #a4a4a4; font-size:0.8rem;">{{prop.name | titlecase}}</span>
                                                    <mdb-range-input [formControlName]="prop.id" min="0" value="5" max="10" class="form-control"></mdb-range-input>
                                                </div>
                                            </div>
                                            <div class="row" *ngSwitchCase="'Dropdown'">
                                                <mdb-select style="margin:10px 15px 10px 15px;" [formControlName]="prop.id" [options]="prop.propertyValues" [multiple]="false" label="{{prop.name}}"></mdb-select>
                                            </div>
                                            <div class="row" *ngSwitchCase="'Checkbox'">
                                                <mdb-checkbox style="margin:5px 15px 5px 15px;" [formControlName]="prop.id">{{ prop.fullname | titlecase}}</mdb-checkbox>
                                            </div>
                                            <div class="row" *ngSwitchCase="'Image'">
                                                <div class="col-12">
                                                    <div class="row" *ngIf="!isLoading">
                                                        <div class="col-12" style="background-color: #F6F6F6; border: 1px solid #E6E6E6;">
                                                            <div class="row" style="min-height: 200px; ">
                                                                <div class="col-12 hrdr-img-box" [ngStyle]="{'background-image': imgActive.length == 0 ? 'url(\'assets/upload.png\')':'','height':imgBoxHeight + 'px'}">
                                                                    <input multiple style="opacity: 0; margin-bottom: -24px; float: left;" type="file" id="imageInput" (change)="onFileSelected($event.target.files)" #imageInput />
                                                                    <div *ngIf="currentImgLoading"><mdb-spinner spinnerColor="blue" class="hrdr-img-spinner"></mdb-spinner></div>
                                                                    <div *ngIf="imgActive.length >= 6;" class="row hrdr-img-dropper-i"></div>
                                                                    <div *ngIf="imgActive.length < 6;" class="row hrdr-img-dropper" [ngStyle]="{'height':imgBoxHeight + 'px','text-align': 'center'}" (click)="imageInput.click()" mdbTooltip="Click to add an image" (onFileDropped)="onFileSelected($event)" dragDrop></div>
                                                                    <div class="animated fadeIn hrdr-img-delete" *ngIf="imgActive.length > 0">
                                                                        <button class="btn" type="button" mdbBtn color="danger" mdbWavesEffect (click)="deleteFileSelected(); form.setValue[prop.id] = null;"><mdb-icon fas icon="trash-alt"></mdb-icon></button>
                                                                        <button type="button" mdbBtn color="light" mdbWavesEffect><mdb-icon fas icon="pen"></mdb-icon></button>
                                                                    </div>
                                                                    <div *ngIf="imgActive.length > 0 && imgActive[currentImgIndex].base64 != undefined" [ngStyle]="{'height': imgBoxHeight + 'px', 'opacity': imgOpacity, 'background-image':'url(' + imgActive[currentImgIndex].base64 + ')'}" style="background-size: contain;background-repeat: no-repeat;background-position: center;"></div>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="!isLoading && imgActive.length > 0">
                                                                <div class="col-12" style="margin-top:15px;">
                                                                    <div cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" class="row animated fadeIn hrdr-thumb-list" style="margin-left: 0px; width:100%;" [ngStyle]="{'min-height': imgBoxWidth/6 + 'px'}">
                                                                        <div cdkDrag class="col-2 hrdr-thumb-col" style="padding: 5px !important;" *ngFor="let img of imgActive; let i = index;">
                                                                            <div *ngIf="img.thumbnail != undefined" [ngStyle]="{'background-image':'url(' + img.thumbnail + ')','opacity': currentImgIndex == i ? '1' : '0.5'}" (click)="currentImgIndex = i;"></div>
                                                                        </div>
                                                                        <div class="col-2 hrdr-img-add" [ngStyle]="{'margin-top': ((imgBoxWidth/6)/2) - 20 + 'px'}" *ngIf="imgActive.length > 0 && imgActive.length < 6" (click)="imageInput.click()"><mdb-icon far icon="plus-square" class="hrdr-img-add-btn"></mdb-icon></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngSwitchCase="'Textarea'" >
                                                <div class="md-form col-12" style="margin-top: 5px; margin-bottom: 5px;">
                                                    <textarea type="text" [formControlName]="prop.id" class="md-textarea form-control" mdbInput></textarea>
                                                    <label for="prop.id" style="left: 15px; color: #a4a4a4;">{{prop.name | titlecase}}</label>
                                                </div>
                                            </div>
                                            <div class="row" *ngSwitchCase="'Tagfield'" >
                                                <div class="md-form col-12" >
                                                    <input [formControlName]="prop.id" mdbInput [type]="'Text'" [id]="prop.id" class="form-control" (keydown.enter)="addTag(form.value[prop.id], prop.id)">
                                                    <label for="prop.id" style="left: 15px; color: #a4a4a4;">{{prop.name | titlecase}}</label>
                                                    <span *ngIf="tagExists" class="hrdr-form-warning animated fadeIn">Tag already exists!</span>
                                                    <span *ngIf="tagEmpty" class="hrdr-form-warning animated fadeIn">Please fill in a value</span>
                                                </div>
                                                <div class="col-12" style="margin-top: 0px; margin-bottom: 25px;">
                                                    <span *ngFor="let tag of object.tags | sortPipe: 'label'; let tagi = index">
                                                    <div *ngIf="tag.value != -1" class="chip hrdr-tag">
                                                        
                                                        <a (click)="goToTag(prop.name,tag.value)">{{tag.label | titlecase}}</a><mdb-icon fas icon="times" (click)="deleteTag(tagi)" style="margin-left: 10px; padding-top:4px"></mdb-icon>
                                                        <mdb-icon ></mdb-icon>
                                                    </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button mdbBtn class="hrdr-btn-delete" flat="true" *ngIf="identity.id != 0" (click)="deleteObjModal.show()" mdbWavesEffect>Delete item</button>
                </div>
            </div>
            <div class="row" *ngIf="deleting" style="width:100%; height: 60vh;">
                <mdb-spinner spinnerColor="blue" class="hrdr-img-spinner"></mdb-spinner>
            </div>
            <div *ngIf="categoryExists == 0" class="animated fadeIn">
                <h1>Unknown Collection</h1>
                <p>Collection <strong>{{identity.categoryname}}</strong> does not exist for user {{identity.username}}.</p>
            </div>
        </div>
        <div *ngIf="userExists == '0'">
            <div class="row hrdrr-breadcrumb">
                <a [routerLink]="['']">Home</a>
            </div>
            <h1>Unknown user</h1>
            <p>Hmm... user doesn't seem to exist!</p>
        </div>
    </div>
    <div mdbModal style="margin-top: 20%;" #deleteObjModal="mdbModal" class="modal fade top" tabindex="10000" role="dialog" aria-labelledby="deletePVModalLabel" aria-hidden="true">
	    <div class="modal-dialog modal-notify modal-danger modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header" style="color: white; font-weight: bold;">Are you sure?</div>
		        <div class="modal-body">
                    This action will <strong>permanently</strong> remove this object and all it's properties from the collection.
                    <div class="form-group md-form"><mdb-checkbox [(ngModel)]="understood">I understand</mdb-checkbox></div>
                </div>
                <div class="modal-footer">
                    <button type="button" mdbBtn color="danger" class="relative waves-light" [disabled]="!understood" mdbWavesEffect (click)="deleteObject(); deleteObjModal.hide(); ">Delete</button>
                    <button type="button" mdbBtn color="light" class="waves-light" aria-label="Close" (click)="deleteObjModal.hide();" mdbWavesEffect>Close</button>
                </div>
	        </div>
        </div>
    </div>