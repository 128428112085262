import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainRoutingModule } from './main-routing.module';
import { UserComponent } from './user/user.component';
import { CategoryComponent } from './category/category.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from '../_modules/shared.module';
import { ObjectComponent } from './object/object.component';



@NgModule({
  declarations: [UserComponent, CategoryComponent, HomeComponent, ObjectComponent],
  imports: [
    CommonModule,
    MainRoutingModule,
    SharedModule
  ]
})
export class MainModule { }
