/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/ng-uikit-pro-standard/ng-uikit-pro-standard.ngfactory";
import * as i2 from "ng-uikit-pro-standard";
import * as i3 from "./warning.component";
var styles_WarningComponent = [];
var RenderType_WarningComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WarningComponent, data: {} });
export { RenderType_WarningComponent as RenderType_WarningComponent };
export function View_WarningComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title w-100"], ["id", "myModalLabel"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Warning"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", "\n"])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["class", "hrdr-btn relative waves-light"], ["mdbBtn", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MdbBtnDirective_0, i1.RenderType_MdbBtnDirective)), i0.ɵdid(10, 114688, null, 0, i2.MdbBtnDirective, [i0.ElementRef, i0.Renderer2], null, null), (_l()(), i0.ɵted(-1, 0, ["Ok"]))], function (_ck, _v) { _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgWarning; _ck(_v, 7, 0, currVal_0); }); }
export function View_WarningComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-warning", [], null, null, null, View_WarningComponent_0, RenderType_WarningComponent)), i0.ɵdid(1, 114688, null, 0, i3.WarningComponent, [i2.MDBModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WarningComponentNgFactory = i0.ɵccf("app-warning", i3.WarningComponent, View_WarningComponent_Host_0, {}, {}, []);
export { WarningComponentNgFactory as WarningComponentNgFactory };
