import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { category, identity } from 'src/app/_models/main.model';
import { CategoryService } from 'src/app/_services/category.service';
import { UserService } from 'src/app/_services/user.service';
import { MainComponent } from '../main.component';

@Component({
  selector: 'app-object',
  templateUrl: './object.component.html',
  styleUrls: ['./object.component.scss']
})
export class ObjectComponent implements OnInit {
  username;
  categoryname;
  objectid;
  isValid: boolean = false;
  category: category;
  categoryList: category[];
  loading: boolean = true;
  identity: identity;
  categoryexists: boolean = false;
  constructor(
    private mc: MainComponent,
    private route: ActivatedRoute,
    private us: UserService,
    private cs: CategoryService
  ) { 
    this.username = this.route.snapshot.paramMap.get('userName');
    this.categoryname = this.route.snapshot.paramMap.get('categoryName');
    this.objectid = this.route.snapshot.paramMap.get('objectId');
    this.mc.items = [
      {"route": "home", "name":"Home"},
      {"route": this.username,"name": this.username + "'s Collections"},
      {"route": this.username + "/" + this.categoryname,"name": this.categoryname}
    ];
  }

  ngOnInit() {
    this.us.getUserExists(this.username, true).subscribe((resp: number) => {
      if(resp != 0){
        this.isValid = true;
        let userid: number = resp;
        this.loadAllData(userid);
      } else {
        this.loading = false;
      }
      
      
    })
  }

  async loadAllData(userid: number){
    console.log("start");
    this.getCategoryList(userid);
    console.log("end");
  }

  async getCategoryList(userid: number){
    console.log("start getCategoryList");
    this.cs.getCategories(userid).subscribe((cat: category[]) => {
      console.log("return value from getCategories");
      let currentCategoryId: number = 0;
      this.categoryList = cat;
      this.categoryList.forEach(catListItem => {
          if(catListItem.identity.categoryname == this.categoryname){
            
            this.category = catListItem;
            console.log(catListItem);
            currentCategoryId = catListItem.identity.categoryid;
            let ident: identity = new identity();
            ident.userid = userid;
            ident.username = this.username;
            ident.categoryid = currentCategoryId;
            ident.categoryname = this.categoryname;
            this.identity = ident;
            this.categoryexists = true;
            if(catListItem.propertycount > 0 && (catListItem.objectcount > 0 || catListItem.wishlistcount > 0) && catListItem.gridcount > 0){
              
            } else {
              this.loading = false;
            }
          }
        }
      )
      
    })
  }
}
