<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">{{action}}</h4>
</div>
<div class="modal-body hrdr-scroll-modal">
    <div *ngIf="!addPropVal">
        <form [formGroup]="propertyForm">
            <div class="form-group md-form">
                <input mdbInput type="text" formControlName="name" class="form-control">
                <label for="name" class="">Name *</label>
                <span *ngIf="pNameMsg != ''" class="hrdr-form-warning animated fadeIn">{{pNameMsg}}</span>
            </div>
            <div class="form-group md-form">
                <input mdbInput type="text" formControlName="fullname" class="form-control">
                <label for="fullname" class="">Full name *</label>
                <span *ngIf="pLabelMsg != ''" class="hrdr-form-warning animated fadeIn">{{pLabelMsg}}</span>
            </div>
            <div class="form-group md-form">
                    <mdb-select formControlName="type" [options]="propertyTypeList" [multiple]="false" label="Type *"></mdb-select>  
                    <span *ngIf="disableType" style="color: #999;">! Property type cannot be changed since it has already been used. Inactivate and create a new property</span>
                    <span *ngIf="pTypeMsg != ''" class="hrdr-form-warning animated fadeIn">{{pTypeMsg}}</span>
            </div>
            <div class="form-group md-form">
                <mdb-checkbox formControlName="isactive" >Active</mdb-checkbox>
            </div>
            <div class="form-group md-form">
                    <mdb-checkbox formControlName="filter" >Filter</mdb-checkbox>
            </div>
            <div class="form-group md-form">
                <mdb-checkbox formControlName="keep" >
                    Keep after saving a new item? &nbsp;
                    <mdb-icon style="float: right; margin-top: 5px;" mdbTooltip="Select this option if you want to keep the value after saving a new item. If not selected, the value will be cleared. Keeping the value is handy when adding a lot of items for the same property" far icon="question-circle"></mdb-icon>
                </mdb-checkbox>
            </div>
        </form>
    </div>
    <div *ngIf="addPropVal">
        <div class="row">
            <div class="col-lg-6 col-12">
                <form [formGroup]="propertyValForm">
                    <div class="form-group md-form">
                        <input mdbInput type="text" formControlName="name" class="form-control">
                        <label for="name" class="">Name *</label>
                        <span *ngIf="pvNameMsg != ''" class="hrdr-form-warning animated fadeIn">{{pvNameMsg}}</span>
                    </div>
                    <div class="form-group md-form">
                        <input mdbInput type="text" formControlName="label" class="form-control">
                        <label for="label" class="">Full name *</label>
                        <span *ngIf="pvLabelMsg != ''" class="hrdr-form-warning animated fadeIn">{{pvLabelMsg}}</span>
                    </div>
                    <div class="form-group md-form">
                        <div class="row">
                            <div class="col-6"><mdb-checkbox formControlName="isactive" >Active</mdb-checkbox></div>
                            <div class="col-6"><mdb-checkbox formControlName="isdefault" >Default</mdb-checkbox></div>
                        </div>
                    </div>
                    <button style="float: left;" type="button" mdbBtn color="danger" class="relative waves-light" mdbWavesEffect (click)="deletePVModal.show(); understood = false;" *ngIf="pvAction == 'Update'">
                        <mdb-icon far icon="trash-alt"></mdb-icon>
                    </button>
                    <button 
                        type="button" 
                        style="float:right;"
                        class="hrdr-btn relative waves-light" 
                        mdbBtn 
                        (click)="savePropval(propertyValForm.value)" 
                        [disabled]="
                            saving || 
                            (this.propertyValForm.value['name'] == '' || this.propertyValForm.value['name'] == null) ||
                            (this.propertyValForm.value['label'] == '' || this.propertyValForm.value['label'] == null) ||
                            pvNameMsg != '' || pvLabelMsg != ''"
                    >
                        Save
                    </button>
                    <button style="float:right;" type="button" mdbBtn color="light" class="relative waves-light" mdbWavesEffect (click)="resetPropVal()" *ngIf="pvAction == 'Update'">
                        Cancel
                    </button>
                    
                </form>
            </div>
            <div class="col-lg-6 col-12">
                <div *ngIf="propertyValueList" class="form-group md-form animated fadeIn">
                    <mdb-select [options]="propertyValueList" (selected)="setPropertyValue($event)" [multiple]="false" label="Options"></mdb-select>
                </div>
            </div>
        </div>
        
    </div>
</div>
<div class="modal-footer" >
    <button style="left: 15px; position: absolute;" type="button" mdbBtn color="danger" class="relative waves-light" mdbWavesEffect (click)="deletePModal.show(); understoodp = false;" *ngIf="action == 'Edit' && !addPropVal">
        <mdb-icon far icon="trash-alt"></mdb-icon>
    </button>
    <button type="button" color="light" class="relative waves-light" *ngIf="addPropVal" mdbBtn (click)="addPropVal = false; action = 'Edit'">Back</button>
    <button type="button" class="hrdr-btn relative waves-light" *ngIf="propertyForm.value['type'] == 'Dropdown' && action == 'Edit' && !addPropVal" mdbBtn (click)="addPropVals()">Options</button>
    <button 
        type="button" 
        mdbBtn 
        *ngIf="!addPropVal"
        class="hrdr-btn relative waves-light" 
        (click)="closeModal(action)" 
        mdbWavesEffect
        [disabled]="
            saving || 
            (this.propertyForm.value['type'] == '' || this.propertyForm.value['type'] == null) ||
            (this.propertyForm.value['name'] == '' || this.propertyForm.value['name'] == null) ||
            (this.propertyForm.value['fullname'] == '' || this.propertyForm.value['fullname'] == null) ||
            pNameMsg != '' || pLabelMsg != ''"
    >
        Save
    </button>
</div>

<div mdbModal style="margin-top: 20%;" #deletePVModal="mdbModal" class="modal fade top" tabindex="10000" role="dialog" aria-labelledby="deletePVModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-notify modal-danger modal-sm" role="document">
        
	  <div class="modal-content">
        <div class="modal-header" style="color: white; font-weight: bold;">Are you sure?</div>
		<div class="modal-body">
            This action will <strong>permanently</strong> remove this value from all items in the collection.
            <div class="form-group md-form"><mdb-checkbox [(ngModel)]="understood">I understand</mdb-checkbox></div>
        </div>

        <div class="modal-footer">
            
            <button type="button" mdbBtn color="danger" class="relative waves-light" [disabled]="!understood" mdbWavesEffect (click)="deletePropval(propertyValForm.value); deletePModal.hide(); ">Delete</button>
            <button type="button" mdbBtn color="light" class="waves-light" aria-label="Close" (click)="deletePVModal.hide();" mdbWavesEffect>Close</button>
        </div>
	  </div>
	</div>
  </div>
  <div mdbModal style="margin-top: 20%;" #deletePModal="mdbModal" class="modal fade top" tabindex="10000" role="dialog" aria-labelledby="deletePModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-notify modal-danger modal-sm" role="document">
        
	  <div class="modal-content">
        <div class="modal-header" style="color: white; font-weight: bold;">Are you sure?</div>
		<div class="modal-body">
            This action will <strong>permanently</strong> remove this property from all items in the collection. Deactivating is advised
            <div class="form-group md-form"><mdb-checkbox [(ngModel)]="understoodp">I understand</mdb-checkbox></div>
        </div>

        <div class="modal-footer">
            <button type="button" mdbBtn color="danger" class="relative waves-light" [disabled]="!understoodp" mdbWavesEffect (click)="deleteProp(propertyForm.value); deletePModal.hide(); modalRef.hide();">Delete</button>
            <button type="button" mdbBtn color="light" class="waves-light" aria-label="Close" (click)="deletePModal.hide();" mdbWavesEffect>Close</button>
        </div>
	  </div>
	</div>
  </div>