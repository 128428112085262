import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { identity } from '../_models/main.model';
import { Router } from '@angular/router';
import { DataService } from '../_services/data.service';


@Component({
  selector: 'app-logout',
  template: '',
  providers:  [LoginService]
})
export class LogoutComponent implements OnInit {
  ident: identity;
  constructor(
    private ls: LoginService,
    private ds: DataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.ident = JSON.parse(localStorage.getItem('ident'));
    this.ls.userLogout(this.ident).subscribe(res => res);
    this.ds.clearIdentity();
    localStorage.clear();
    this.ds.clearAll();
    this.router.navigateByUrl('/login');
  }

}
