import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login.service';
import { user, identity } from '../../_models/main.model';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['../login.component.scss']
})
export class ActivateComponent implements OnInit {
  userActivated: boolean = false;
  guid: string = "";
  usr: user = new user();
  dataFetched = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ls: LoginService,
    private us: UserService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.guid = params['i'];
      if(this.guid == undefined){
        this.router.navigateByUrl('/login');
      } else {
        this.us.getUserByGUID(this.guid,'activate').subscribe((resp:user) => {
          let fetchedUser: user = resp;
          if(fetchedUser.email != null){
            let ident: identity = new identity();
            ident.username = fetchedUser.identity.username;
            ident.userid = fetchedUser.identity.userid;
            this.usr.identity = ident;
            this.usr.email = fetchedUser.email;
            this.us.activateUser(fetchedUser.identity.userid).subscribe(resp => {
              if(resp["status"] == "success"){
                this.userActivated = true;
              } else {
                this.userActivated = false;
              }
            })
            this.dataFetched = true;
          } else {
            this.router.navigateByUrl('/login');
          }
        });
      }
    });
  }

}
