/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./settings.component";
import * as i3 from "../admin.component";
var styles_SettingsComponent = [i0.styles];
var RenderType_SettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsComponent, data: {} });
export { RenderType_SettingsComponent as RenderType_SettingsComponent };
export function View_SettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "hrdr-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Settings"]))], null, null); }
export function View_SettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-settings", [], null, null, null, View_SettingsComponent_0, RenderType_SettingsComponent)), i1.ɵdid(1, 114688, null, 0, i2.SettingsComponent, [i3.AdminComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsComponentNgFactory = i1.ɵccf("app-settings", i2.SettingsComponent, View_SettingsComponent_Host_0, {}, {}, []);
export { SettingsComponentNgFactory as SettingsComponentNgFactory };
