import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filterMain } from '../_models/main.model';
@Injectable()
export class FilterControlService {
  currentFilterValues: any;
  constructor(
  ) {
    
   }
  toFormGroup(filters: filterMain[],urlTagList: string[]) {
    if(localStorage.getItem('currentFilter')){
      this.currentFilterValues = JSON.parse(localStorage.getItem('currentFilter'));
    }
    let group: any = {};
    filters[0].filter.forEach(filter => {
      let value: any;
      group[filter.name] = new FormControl();
      if(filter.type == "Tagfield" && urlTagList.length > 0){
          value = urlTagList;
      } else {
        if(this.currentFilterValues != undefined){
          value = this.currentFilterValues[filter.name];
        } else {
          value = null;
        }
          
      }
      if(localStorage.getItem('currentFilter')){group[filter.name].value = value;}
    });
    group["wishlist"] = new FormControl();
    if(localStorage.getItem('currentFilter')){group["wishlist"].value = this.currentFilterValues["wishlist"];}
    return new FormGroup(group);

  }
}