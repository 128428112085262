<div *ngIf="userExists">
	<div *ngIf="userExists == '0'">
		<h1>Unknown user</h1>
		<p>Hmm... user doesn't seem to exist!</p>
	</div>
	<div *ngIf="userExists != '0'">
		<h1 class="animated fadeIn hrdr-title" style="margin-bottom: 1em;">
			Your collections
        </h1>
		<div *ngIf="categoryList && !loading">
			<div *ngIf="categoryList.length > 0">
				<div class="row">
					<div class="col-6" style="text-align: left; margin-bottom: 1em; padding-top:30px;padding-left: 20px;">
						<mdb-checkbox style="margin-top:20px;" [(ngModel)]="showInactive">Show inactive</mdb-checkbox>
					</div>
					<div class="col-6" style="text-align: right; margin-bottom: 1em;">
						<button mdbBtn class="hrdr-btn hrdrr-btn-right" (click)="basicModal.show()">Add Collection</button>
					</div>
				</div>
				<div class="row animated fadeIn" *ngIf="!loading">
					<div class="col-lg-4 col-sm-6 col-12" *ngFor="let col of categoryList | collectionPipe: showInactive; let i = index;">
						<mdb-card (click)="goToCategory(col.identity.categoryid,col.identity.categoryname);" class="hrdr-col-card">
							<mdb-card-body>
								<div class="row">
									<div class="col">
										<mdb-card-title><h4>{{col.identity.categoryname}}</h4></mdb-card-title>
						  	  			<mdb-card-text>Items: <span style="font-weight: bold;">{{col.objectcount}}</span></mdb-card-text>
									</div>	
									<!--div *ngIf="col.icon != ''" class="col-4" style="text-align: right; font-size: 4em; color: #DDD;">
										<mdb-icon fas icon="{{col.icon}}" style="margin-right: 10px"></mdb-icon>
									</div-->	
								</div>
							  	<div class="row" style="min-height: 2.4em; font-size:1em; padding: 0.4em;">
									<div class="col-6"><span [ngStyle]="{color: col.isactive ? '#777':'#DDD'}"><mdb-icon fas icon="check" [ngStyle]="{color: col.isactive ? '#00354D':'#DDD'}"></mdb-icon>&nbsp;Active</span></div>
									<div class="col-6"><span [ngStyle]="{color: col.ispublic ? '#777':'#DDD'}"><mdb-icon fas icon="users" [ngStyle]="{color: col.ispublic ? '#00354D':'#DDD'}"></mdb-icon>&nbsp;Public</span></div>
							  	</div>
								<div class="row" style="min-height: 2.4em; font-size:1em; padding: 0.8em 0.4em 0 0.4em; color: #CCC;">
									<div *ngIf="col.objectcount > 0" class="col-12">Last item added on <span style="color: #AAA; font-weight:bold;">{{col.lastchanged.substr(0,10)}}</span></div>
									<div *ngIf="col.objectcount == 0" class="col-12">No items added yet</div>
							  	</div>
							</mdb-card-body>
						</mdb-card>
					</div>
				</div>
			</div>
			<div *ngIf="categoryList.length == 0">
				<p>Oops! Seems like you haven't started yet! Click <a (click)="basicModal.show()">here</a> to add your first item!</p>
			</div>
		</div>
	</div>			
</div>
<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
	  <div class="modal-content">
		<div class="modal-header hrdr-modal-header">
		  <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide(); clearCollection();">
			<span aria-hidden="true">×</span>
		  </button>
		  <h4 class="hrdr-title w-100" id="myModalLabel">Add new collection</h4>
		</div>
		<div class="modal-body">
			<form [formGroup]="categoryForm">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group md-form">
									<input mdbInput type="text" formControlName="categoryName" class="form-control">
									<label for="categoryName" style="left: 15px; color: #a4a4a4;">Name</label>
                                </div>
							</div>
						</div>
						<div class="row">
							<div class="col-12" style="height: 20px;">
								<div *ngIf="categoryMsg" class="alert alert-danger animated fadeIn" role="alert">
									{{ categoryMsg }}
								  </div>
							</div>
                        </div>
					</div>
				</div>
				<div class="row">
                    <div class="col-6">
                        <div class="form-group md-form">
                            <mdb-checkbox formControlName="categoryActive">Active</mdb-checkbox>
                        </div>
					</div>
					<div class="col-6">
                        <div class="form-group md-form">
                            <mdb-checkbox formControlName="categoryPublic">Public</mdb-checkbox>
                        </div>
                    </div>
				</div>
            </form>
		<div class="modal-footer">
		  <button type="button" mdbBtn color="light" class="waves-light" aria-label="Close" (click)="basicModal.hide(); clearCollection();" mdbWavesEffect>Close</button>
		  <button type="button" mdbBtn color="primary" class="relative waves-light" [disabled]="categoryMsg || categoryForm.value['categoryName'] == ''" mdbWavesEffect (click)="saveCategory(categoryForm.value)">Save</button>
		</div>
	  </div>
	</div>
  </div>