/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./login.service";
import * as i4 from "@angular/common/http";
import * as i5 from "ngx-cookie-service";
import * as i6 from "./login.component";
import * as i7 from "ng-uikit-pro-standard";
import * as i8 from "ngx-device-detector";
import * as i9 from "../_services/data.service";
import * as i10 from "../_services/user.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "container-fluid hrdr-login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "container-fluid hrdr-login-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "row hrdr-login-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "col-12"], ["style", "text-align: center; height:20em;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "hrdr-login-logo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hoarder"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "hrdr-login-logo-sub"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Collecting made "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["style", "color: #0081bd;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["easy"])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "row hrdr-login-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(14, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 14, 0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵprd(512, null, i3.LoginService, i3.LoginService, [i4.HttpClient, i5.CookieService]), i1.ɵdid(2, 114688, null, 0, i6.LoginComponent, [i3.LoginService, i2.Router, i7.ToastService, i8.DeviceDetectorService, i9.DataService, i10.UserService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i6.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
