<form [formGroup]="form">
        <div class="row" [ngSwitch]="filter.type">
            <div class="col-12">
                <div class="row fltrRow md-form hrdr-fltr-txt" *ngSwitchCase="'Text'">
                        <input autocomplete="off" style="color: #EEE;" mdbInput [id]="filter.name" [type]="filter.type" [formControlName]="filter.name" class="form-control">
                        <label class="hrdr-fltr-lbl" for="filter.name">{{filter.name | titlecase}}</label>
                </div>
                <div class="row" *ngSwitchCase="'Number'">
                        <input mdbInput [id]="filter.name" [type]="filter.type" [formControlName]="filter.name" class="form-control">
                        <label class="hrdr-fltr-lbl" for="filter.name">{{filter.name | titlecase}}</label>
                </div>
                <div class="row fltrRow" *ngSwitchCase="'Dropdown'">
                    <mdb-select class="hrdr-fltr-select" [formControlName]="filter.name" [options]="filter.values" [multiple]="true" label="{{filter.name}}"></mdb-select>
                </div>
                <div class="row fltrRow" *ngSwitchCase="'Checkbox'">
                    <mdb-select class="hrdr-fltr-select" [formControlName]="filter.name" [options]="checkboxList" label="{{filter.fullname}}"></mdb-select>
                </div>
                <div class="row fltrRow" *ngSwitchCase="'Image'">
                    <mdb-select class="hrdr-fltr-select" [formControlName]="filter.name" [options]="checkboxList" label="{{filter.fullname}}"></mdb-select>
                </div>
                <div class="row fltrRow" *ngSwitchCase="'Tagfield'">
                    <mdb-select class="hrdr-fltr-select" [formControlName]="filter.name" [options]="filter.values" [multiple]="true" label="{{filter.name}}"></mdb-select>
                </div>
            </div>
        </div>
    </form>