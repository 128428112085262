import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { identity } from '../_models/main.model';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(
    private http: HttpClient
  ) { }

  getValidity(ident: identity){
	  let url = `${API_URL}/Security?securityKey=` + ident.securitykey + `&userid=` + ident.userid;
	  return this.http.get(url);
  }

}
