      <div class="row">
        <div class="col-12">
          <div class="flex-center">
            <div class="col-lg-6 col-md-8 col-12 card animated fadeIn" style="padding: 2em;">
                <div *ngIf="!register">
              <form [formGroup]="registerForm">
                <div class="hrdr-title" style="margin-bottom: 1em;">Register</div>
                <div class="md-form" style="margin-bottom: -15px;">
                    <input autocomplete="off" [class.is-invalid]="userExistsMsg != '' || registerForm.get('username').invalid && registerForm.get('username').touched" mdbInput type="text" name="username" [formControlName]="'username'" id="username" class="form-control">
                    <label for="username" class="">Username *</label>
                    <div class="hrdr-form-err">
                        <span *ngIf="registerForm.get('username').invalid && registerForm.get('username').touched" class="hrdr-form-warning">Please provide a username</span>
                        <span *ngIf="userExistsMsg != ''" class="hrdr-form-warning">{{userExistsMsg}}</span>
                    </div>
                    
                  </div>
                <div class="md-form" style="margin-bottom: -15px;">
                    <input autocomplete="new-email" [class.is-invalid]="emailRegisteredMsg != '' || registerForm.get('email').invalid && registerForm.get('email').touched" mdbInput type="text" name="email" [formControlName]="'email'" id="email" class="form-control">
                    <label for="email" class="">E-mail *</label>
                    <div class="hrdr-form-err">
                        <span *ngIf="registerForm.get('email').invalid && registerForm.get('email').touched" class="hrdr-form-warning">Please provide a valid email</span>
                        <span *ngIf="emailRegisteredMsg != ''" class="hrdr-form-warning">{{emailRegisteredMsg}}</span>
                    </div>
                  </div>
                <div class="md-form">
                  <input autocomplete="new-password" mdbInput type="password" [name]="'pwd'" [formControlName]="'pwd'" [id]="'pwd'" class="form-control">
                  <label for="pwd" class="">Password *</label>
                </div>
                <div class="md-form">
                  <input autocomplete="new-password" [class.is-invalid]="registerForm.get('confirmPwd').touched && registerForm.get('confirmPwd').invalid" mdbInput type="password" name="confirmPwd" [formControlName]="'confirmPwd'" id="confirmPwd" class="form-control">
                  <label for="confirmPwd" class="">Confirm password *</label>
                  <div class="hrdr-form-err">
                    <span *ngIf="registerForm.get('confirmPwd').touched && registerForm.get('confirmPwd').errors != null && registerForm.get('confirmPwd').errors.mustMatch" class="hrdr-form-warning">Passwords are not the same</span>
                    <span *ngIf="registerForm.get('confirmPwd').touched && registerForm.get('confirmPwd').errors != null && !registerForm.get('confirmPwd').errors.mustMatch" class="hrdr-form-warning">Confirm password cannot be empty</span>
                </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <button 
                        [disabled]="
                            isLoading &&
                            !registerForm.get('pwd').touched || 
                            registerForm.invalid || 
                            userExistsMsg != '' ||
                            emailRegisteredMsg != ''
                        " type="submit" class="btn hrdr-btn" (click)="registerUser()">Register</button>
                  </div>
                  <div class="col-6" style="padding-top: 0.7em;">
                    <mdb-spinner *ngIf="isLoading" spinnerType="small" spinnerColor="blue" style="margin-left: 10px; position: absolute; top: 10px;"></mdb-spinner>
                  </div>
                </div>
              </form>
            </div>
            
            <div *ngIf="register">
                <h4>You have been registered!</h4>
                <p>An email has been sent to complete your registration. Follow the link in the email and enjoy Hoarder!</p>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 hrdr-login-register">
          <a [routerLink]="['/login']">Back to login page</a>
        </div>
      </div>